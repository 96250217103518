// child of BodyButtons.js
import React, { useState } from 'react'
import dateToDay from '../../../functions/dateToDay'

const PrintCard = ({ card }) => {
  const [usedCardType, setUsedCardType] = useState(card.cardType)
  let classNameForCont
  let cardAmountTitle
  if(usedCardType === 'Lahjakortti') {
    classNameForCont  = 'print-card print lahjakortti'
    if(card.productAmount > 0) {cardAmountTitle = card.productAmount + ' x ' + card.product}
    else {cardAmountTitle = card.amount + ' €'}
  } else {
    classNameForCont  = 'print-card print sarjakortti'
    if(card.productAmount > 0) {cardAmountTitle = card.productAmount + ' x ' + card.product}
    else {cardAmountTitle = card.amount  + ' €'}
  }


  return(
    <div>
      {/* Title */}
      <h4 className="body-header">Kortin tulostus</h4>
      <div className="giftcard-body-container">
        <div className="print-hidden">

          <select
            className="input-time"
            value={usedCardType}
            onChange={(event) => setUsedCardType(event.target.value)}
          > <option value={usedCardType}>{usedCardType}</option>
            <option value="Lahjakortti">Lahjakortti</option>
            <option value="Sarjakortti">Sarjakortti</option>
          </select>

          <button onClick={() => {window.print()}}>Print</button>

        </div>
        <p>Tämä on työnalla. Toistaiseksi kortista voi ottaa esim. kuvakaappauksen.</p>
        <div className={classNameForCont}>
          <h2 className="print-card-title">{usedCardType}</h2>
          <h3 className="print-card-value">{cardAmountTitle}</h3>
          {usedCardType === 'Sarjakortti' && <p className="print-card-name">{card.customerName}</p>}
          <p className="print-card-p">
         Kortin numero: <b>{card.num}</b> <br/>
         Viimeinen voimassaolopäivä <br/>
            {dateToDay(card.expiryDate)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrintCard