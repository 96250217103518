const msgScrollReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_SCROLL_TYPE':
    return action.data

  default: return state
  }
}

export const setScrollType = (type) => {
  return{
    type: 'SET_SCROLL_TYPE',
    data: type
  }
}

export default msgScrollReducer