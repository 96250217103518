import React from 'react'
import './EmailList.css'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import langs from '../../../../../langs'

const EmailList = ({ emails, removeEmail }) => {
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  let displayEmails = []

  if(emails){
    displayEmails = emails.map((e, i) => {
      return <div key={`${e}-${i}`} className="invite-email">
        <span>
          {e}
        </span>
        <button className="remove-email-button hover-pointer" id={e} onClick={removeEmail}>
          <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTimesCircle}/>
        </button>
      </div>
    })
  }

  return(
    <div id="email-list">
      <h3>{trans.emailsAdded[lN]}</h3>
      {displayEmails}
    </div>
  )
}

export default EmailList