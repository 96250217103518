import { getDeletedUsers } from '../services/users'

const userTrashcanReducer = (state = [], action) => {
  switch(action.type){
  case 'INIT_DELETED_USERS':
    return action.data

  default: return state
  }
}

export const initializeTrashcan = (token, company) => {
  return async dispatch => {
    const deletedUsers = await getDeletedUsers(token, company)
    dispatch({
      type: 'INIT_DELETED_USERS',
      data: deletedUsers
    })
  }
}


export default userTrashcanReducer