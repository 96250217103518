import React, { useState } from 'react'
import UiCard from '../../../components/UI/UiCard/UiCard'
import GiftCardUseHolder from './GiftCardUseHolder'
import GiftCardinfoHolder from './GiftCardinfoHolder'
import GiftCardHistoryHolder from './GiftCardHistoryHolder'
import BodyButtons from './BodyButtons'
import GiftCardDelete from './GiftCardDelete'
// import ButtonWithIcon from '../../../components/UI/ButtonWithIcon/ButtonWithIcon'
import './GiftCard.css'
import IsDeleted from './IsDeleted'
import GiftCardEditHolder from './GiftCardEditHolder'
import Reactivated from './Reactivated'
import PrintCard from './PrintCard'
import IconButton from '../../../components/UI/IconButton/IconButton'
import { useSelector } from 'react-redux'

const GiftCard = ({ card, showDeactivated, setShowDeactivated, setEmptyFilter,
  alertMessage, setAlertMessage, giftCardContainer,
  setGiftCardContainer, idSelected, setIdSelected,
  openModal, closeModal, setExpiredFilter
}) => {

  //const help = useSelector(state => state.help && state.help)

  //-------- S T A T E S --------------------------

  const [deleted,                    setDeleted] =                    useState(false)
  const [ButtonStyleEdit,            setButtonStyleEdit] =            useState('defaultButton')
  const [visits,                      setVisits] =                    useState(card.usage.length)
  const [bodyStyle, setBodyStyle]  = useState('ui-card-body-50px')
  const locations = useSelector(state => state.locations)
  // const helpTxts = [
  //   ['Ohje','Yllöolevista linkeistä pääset käyttämään ja muokkaamaan lahjakorttia. Painamalla nappia uudelleen, se pienentää valinnan. '],
  //   ['Veloitus','Voit valita pikavalinnasta haluamasi summan, tai syöttää sen kenttään. Valitse myös työntekijä.'],
  //   ['Tiedot','Täällä voit tarkistaa kortin tiedot'],
  //   ['Historia','Käyttöhistoriaan on talletettu kortin käyttö ja työntekijät'],
  //   ['Muokkaa','Esillä olevat kentät ovat mahdollisia päivittää myös myöhemmin. Ota huomioon, että nämä tiedot vaikuttavat myös raportteihin. Älä vaihda tietoja tarpeettomasti!'],
  //   ['Ohje','Kortti on tällä hetkellä roskakorissa. Saat palautettua sen "peru poisto" -napista. Vaihtoehtoisesti voit poistaa kortin tietokannasta kokonaan. Tämän jälkeen korttia ei voida enää palauttaa!'],
  //   ['Ohje6','Ohjeteksti6'],
  //   ['Ohje7','Ohjeteksti7'],
  //   [undefined,undefined],
  // ]


  // -------- N A V I G A T E --------------------------

  const giftNavigate = (giftNavId) => {
    setGiftCardContainer(giftCardContainers[giftNavId])

  }
  // -------- C O N T A I N E R S --------------------------

  const giftCardContainers = [
    null,  //0                                     // null
    <GiftCardUseHolder // 1
      key = '1'
      card = {card}
      setAlertMessage = {setAlertMessage}
      giftNavigate = {giftNavigate}
      setBodyStyle = {setBodyStyle}
      setButtonStyleEdit = {setButtonStyleEdit}
      setVisits = {setVisits}
      visits = {visits}
      openModal = {openModal}
      closeModal = {closeModal}
      setIdSelected = {setIdSelected}
    />,
    <GiftCardinfoHolder // 2
      key = '2'
      giftNavigate = {giftNavigate}
      setButtonStyleEdit = {setButtonStyleEdit}
      card = {card}

    />,
    <GiftCardHistoryHolder // 3                      // History
      key = '3'
      card={card}
      giftNavigate = {giftNavigate}
      setButtonStyleEdit = {setButtonStyleEdit}
      setAlertMessage = {setAlertMessage}
      openModal = {openModal}
      closeModal = {closeModal}
      setIdSelected = {setIdSelected}
      setExpiredFilter = {setExpiredFilter}
      setEmptyFilter = {setEmptyFilter}
    />,

    <GiftCardEditHolder  // 4
      key = '4'
      card={card}
      setIdSelected = {setIdSelected}
      setAlertMessage = {setAlertMessage}
      setButtonStyleEdit = {setButtonStyleEdit}
      giftNavigate = {giftNavigate}
      setDeleted = {setDeleted}
      setShowDeactivated = {setShowDeactivated}
      showDeactivated = {showDeactivated}
      openModal = {openModal}
      closeModal = {closeModal}
    />,

    <GiftCardDelete // 5
      key = '5'     // delete
      card = {card}
      setButtonStyleEdit = {setButtonStyleEdit}
      giftNavigate = {giftNavigate}
      setAlertMessage = {setAlertMessage}
      setDeleted = {setDeleted}
      setShowDeactivated = {setShowDeactivated}
      setIdSelected = {setIdSelected}
    />,

    <IsDeleted  // 6
      key = '6'                               // Card is deleted
      num = {card.num}

    />,
    <Reactivated  // 7                               // Reactivated
      key = '7'
      id = {card.id}
      giftNavigate = {giftNavigate}
      setButtonStyleEdit = {setButtonStyleEdit}
      num = {card.num}
      setAlertMessage = {setAlertMessage}
    />,
    <PrintCard // 8
      key = '8'
      card = {card}
      giftNavigate = {giftNavigate}
      setButtonStyleEdit = {setButtonStyleEdit}
    />
  ]


  // ------------ R E T U R N   M O D U L E -------------------------------------
  return (
    <>
      {/* Back button */}
      { idSelected !== '' &&
      <div className = 'UIcard-exit-button'>
        <IconButton
          type="button"
          onClick={() => {
            setIdSelected('')
            setGiftCardContainer(null)
          }}
          className="icon-button-secondary"
          size="20px"
          text = 'takaisin'
          char="x"
        />
      </div>

      }
      <UiCard
        headerTitle = {(card.cardType === 'Sarjakortti') ?
          <> {card.num} {card.customerName !== '' && ' • '}
            {card.customerName} </>: <>{card.num}
            <span className="grey">
              {card.customerName !== '' && ' • '}
              {card.customerName}
            </span>
          </>}

        headerAmount = {
          card.productAmount > 0
            ? card.productAmount + ' x ' + card.product + ' (' + (card.productAmount - card.usage.length) + ' jäljellä)'
            : Math.round((Number(card.amount) - Number(card.totalUsage))*100)/100 + ' € / ' + (Math.round(card.amount*100)/100 ) + '€'}

        footerInfoRight = {
          <>
            {locations && card.location && locations.filter(loc => loc.id === card.location)[0].name} <br/>
            {card.cardType}<br/>
            {card.payingMethod.methodName}<br/>
            {card.receipt && <> kuitti: {card.receipt} <br/></>}</>}

        cardType = {<><b>{card.cardType}</b></>}
        bodyStyle = {bodyStyle}
        idSelected = {idSelected}
        footerInfo={card.info ? card.info : undefined}
        helpTxt = 'Klikkaamalla yllä olevia symboleita, voit tarkastella ja muokata korttia'
      >

        <BodyButtons
          card = {card}
          alertMessage={alertMessage}
          giftNavigate={giftNavigate}
          ButtonStyleEdit={ButtonStyleEdit}
          setAlertMessage = {setAlertMessage}
          setButtonStyleEdit = {setButtonStyleEdit}
          deleted = {deleted}
          showDeactivated = {showDeactivated}
          setShowDeactivated = {setShowDeactivated}
          setBodyStyle = {setBodyStyle}
          setIdSelected = {setIdSelected}
        />

        {giftCardContainer}
      </UiCard>
    </>
  )}

export default GiftCard
