import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addData } from '../../../../reducers/rdc-oneCustomer'
import Button from '../../../../../../components/UI/Button/Button'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'





const AddData = ({ dataId, user, customer }) => {
  const [dataValue, setDataValue] = useState('')
  const dispatch = useDispatch()

  const sendDataHandler = () => {
    const body = {
      data: dataValue,
      dataPointId: dataId
    }
    dispatch(addData(user.token, body, customer.id))

    dispatch(setNotification({
      notification: `Arvo ${dataValue} talletettu onnistuneesti`,
      type: 'default' }))
    setDataValue('')
  }
  return(
    <div className='container'>
      <div className="flex-row">
        <input
          placeholder="syötä uusi arvo"
          value = {dataValue}
          onChange={(e) => {setDataValue(e.target.value)}}
        />
        <Button
          className = "buttonGreen"
          label = 'Lisää'
          type = 'buttonGreen'
          icon = 'update'
          onClick={sendDataHandler}
        />
      </div>
    </div>
  )}

export default AddData