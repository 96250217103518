import React, { useState } from 'react'
import '../RaportsHolder.css'
import UiCard from '../../../../../components/UI/UiCard/UiCard'
import DateDropDown from './DateDropDown'
import Button from '../../../../../components/UI/Button/Button'


const TableOfSells = ({ giftCardData }) => {
  const yearNow = new Date().getFullYear()
  let monthNow = (new Date().getMonth()+1).toString()
  if (monthNow < 10) {monthNow = '0' + monthNow}
  let dayNow = new Date().getDate().toString()
  if (dayNow < 10) {dayNow = '0' + dayNow}

  let monthToStart = monthNow
  if (monthNow > 1) monthToStart = monthNow-1
  if (monthToStart < 10) monthToStart = '0' + monthToStart.toString()

  const [filterRange, setFilterRange] = useState([
    '01',                       // start day
    monthToStart.toString(),    // start month
    yearNow.toString(),         // start year
    dayNow,                     // end day
    monthNow,                   // end month
    yearNow.toString()          // end year
  ])

  let filterStartDate = filterRange[2].toString() + '-' + filterRange[1].toString() + '-' + filterRange[0].toString()
  let filterEndDate = filterRange[5].toString() + '-' + filterRange[4].toString() + '-' + filterRange[3].toString()
  const[findFilter, setFindFilter] = useState('')
  const[showMoreUses, setShowMoreUses] = useState(['200px','Näytä lisää'])
  const timeRangeString = `${filterRange[0]}.${filterRange[1]}.${filterRange[2]} – ${filterRange[3]}.${filterRange[4]}.${filterRange[5]}`


  const handleFind = (event) => {
    setFindFilter(event.target.value)
  }


  // list of bought cards
  let listOfBoughtCards = giftCardData
    .filter(x => x.active === true) // only active cards
    .filter(x => x.amount)
    .filter(x =>
      x.createdDate.slice(0,10) >= filterStartDate
     && x.createdDate.slice(0,10) <= filterEndDate)
    .filter(x =>
      x.num.toString().toLowerCase().includes(findFilter.toLowerCase())
      || (x.info && x.info.toLowerCase().includes(findFilter.toLowerCase()))
      || (x.customerName && x.customerName.toLowerCase().includes(findFilter.toLowerCase()))
      || (x.receipt && x.receipt.toLowerCase().includes(findFilter.toLowerCase()))
    )


  // value of bought cards
  const valueOfboughtCards = listOfBoughtCards
    .map(x => x.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  // handle for CVS

  const handleCvs = (event) => {
    event.preventDefault()
    let info = ''

    let receipt = ''
    let find = ''
    if(findFilter !== '') {find = `_hakusanalla_${findFilter}`} else {find = ''}

    // set data format
    let csvContent = 'data:text/csv;charset=utf-8,'

    // headings
       + ',Nro,Pvm.,Arvo(€),Myyntitapa,Kuitti,Lisätiedot  \n,'


    // mapping data
    + listOfBoughtCards.map(function(x){

      //  Enters and commas are not allowed in csv - ,->. and ; -> :
      if(x.info )    { info = x.info.replace(',','.' ).replace(';',':').replace( /\r?\n|\r/g, ' ') }        else { info = '-' }
      //  if(x.seller)   { seller = x.seller.replace(',','.').replace(';',':') }    else { seller = '-' }
      if(x.receipt)  { receipt = x.receipt.replace(',','.').replace(';',':') }  else { receipt = '-' }

      return x.num + ',' + new Date(x.createdDate).getDate() +'.'+ (Number(new Date(x.createdDate).getMonth())+1).toString() + '.' + (new Date(x.createdDate).getFullYear()).toString() + ',' + x.amount  + ',' + 'seller'  + ',' + receipt + ',' + info + '\n'
    })
    + `\n,,Yhteensä(€): ,${valueOfboughtCards},,,\n   `
    // opening CSV
    var encodedUri = encodeURI(csvContent)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `Lahjakorttimyynnit_${timeRangeString}${find}.csv`)
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".
  }


  return(
    <>
      <UiCard
        headerTitle = "Ostotapahtumat "
        headerAmount = {timeRangeString}
        headerInfo = {'yht. ' + valueOfboughtCards + ' €'}
        footerInfo = "CVS tiedoston voit aukasta taulukkolaskentaohjelmassa"
      >
        <h4 className="body-header">Raportti ostotapahtumista</h4>

        <div className="giftcard-body-container">
          <input className="input-find    "
            value= {findFilter}
            onChange={handleFind}
            placeholder='hae'
          />
          <div className ="gap-20"/>
          <DateDropDown
            setFilterRange = {setFilterRange}
            filterRange = {filterRange}
            yearNow = {yearNow}
          />

          <div style={{ transition:' max-height 0.6s ease-out', height:'auto', maxHeight: showMoreUses[0], overflowY: 'scroll' }}>
            <table style={{ textAlign: 'left', borderLineWidth: '1px' }}>
              <thead>
                <tr className="tr-thead">
                  <th>Nro.</th>
                  <th>Pvm.</th>
                  <th>Kortin arvo (€)</th>
                  <th>Myyntiarvo (€)</th>
                  <th>Myyntitapa</th>
                  <th>Kuitti</th>
                  <th>Tyyppi</th>
                  <th>Lisätiedot</th>
                </tr>
              </thead>
              <tbody>
                { listOfBoughtCards.map(x =>
                  <tr key = {x.id}>
                    <td>{x.num}</td>
                    <td>{new Date(x.createdDate).getDate()}.{new Date(x.createdDate).getMonth()+1}.{new Date(x.createdDate).getFullYear()}</td>
                    <td>{x.amount}</td>
                    <td>{x.salesValue !== undefined ? x.salesValue : x.amount}</td>
                    <td>{'payingMethod' in x && x.payingMethod.methodName.substring(0, 25)}</td>
                    <td>{x.receipt}</td>
                    <td>{x.cardType}</td>
                    <td>{x.info && x.info.substring(0, 25)}</td>
                  </tr>
                )}

              </tbody>
            </table>
          </div>

          {listOfBoughtCards.length > 8 &&
        <p className = "cvs-link" onClick={() => {if (showMoreUses[0] === '2000px') {setShowMoreUses(['200px','Näytä lisää'])} else {setShowMoreUses(['2000px','Näytä vähemmän'])} }}>{showMoreUses[1]}</p>}
          {listOfBoughtCards.length > 0 ?
            <Button icon = 'download' type = "buttonGreen" onClick={handleCvs} label = 'Lataa CSV-tiedosto'/>
            : <p>Ei tuloksia annetulla haulla / aikajaksolla</p>
          }
        </div>
      </UiCard>

    </>
  )
}

export default TableOfSells