import React from 'react'
import RemoveAttachmentButton from '../../RemoveAttachmentButton/RemoveAttachmentButton'
import './ImageListItem.css'

const ImageListItem = ({ image, remove }) => {

  return(
    <div className="image-list-item">
      <div className="image-list-item-remove-button-container">
        <RemoveAttachmentButton item={image} remove={remove} />
      </div>
      <div className="image-list-item-image-container">
        <img src={image} alt="shared"  />
      </div>
    </div>
  )
}

export default ImageListItem