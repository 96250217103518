import axios from 'axios'
import { configurate } from '../../../../services/configurate'

// tag and tagCategory are combined  <- 2 baseUrls
// maybe should be separeted in future

const baseUrl = '/private/api/location'

// get all Locations filtered by company
export const getLocations = async (token, companyId) => {
  try{
    const config = configurate(token)
    const items = await axios.get(baseUrl + '/' + companyId, config)
    return items.data
  } catch(err){
    console.log('error at getLocations() : ', err)
  }
}

// post Location
export const postLocation = async (token, body) => {
  try{
    const config = configurate(token)
    const item = await axios.post(baseUrl, body, config)
    return item.data
  } catch(err){
    console.log('error at PostLocation() : ', err)
  }
}

// put Location
export const putLocation = async (token, body, LocationId) => {
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrl + '/' + LocationId, body, config)
    return item.data
  } catch(err){
    console.log('error at PutLocation() : ', err)
  }
}

// delete Location
export const deleteLocation = async (token, LocationId) => {
  try{
    const config = configurate(token)
    const item = await axios.delete(baseUrl + '/' + LocationId, config)
    // send the whole item for reducer here.
    return item
  } catch(err){
    console.log('error at DeleteLocation() : ', err)
  }
}