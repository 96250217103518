const modalReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_MODAL':
    return action.data

  default: return state
  }
}

export const setModal = (modal) => {

  return{
    type: 'SET_MODAL',
    data: modal
  }
}

export default modalReducer