import React from 'react'
import Text from '../../../../components/UI/Text/Text'
import dateToday from '../../../../functions/dateToDay'

const Line = ({ app }) => {

  const Item = ({ title, data }) => {

    return(
      <div className="card-list even-odd">
        <div className="flex-container-info customerRaport-info" style={{ width: '100px' }} >
          <b>{title}</b>
        </div>
        <div className="flex-item-right customerRaport-right">
          <Text data={data}/>
        </div>
      </div>
    )
  }

  return(
    <div >
      <div className = "customerRaport-date"> {dateToday( app.date )} </div>
      <Item title = "Tuote" data = {app.product}/>
      <Item title = "Työntekijä" data = {app.staff_ID}/>
      { app.treatment && <Item title = "Toimenpiteet" data = {app.treatment}/>}
      { app.instructions && <Item title = "Ohjeistukset" data = {app.instructions}/>}
      { app.notes && <Item title = "Muistiinpanot" data = {app.notes}/>}
      { app.check && <Item title = "Tutkimukset" data = {app.check}/>}
    </div>


  )
}

export default Line