import axios from 'axios'
import { configurate } from './configurate'
const baseUrl = '/private/api/users'

export const getAllUsers = async (token, company) => {
  try{
    const config = configurate(token)
    const users = await axios.get(baseUrl + '/' + company, config)
    return users.data
  } catch(err){
    console.log('error at getAllUsers() : ', err)
  }
}

export const updateUser = async (token, user, id) => {
  try{
    const config = configurate(token)
    const updatedUser = await axios.put(baseUrl + '/' + id, user, config)
    console.log('updated user: ', updatedUser.data)
    return updatedUser.data
  } catch(err){
    console.log('error at updateUser() : ', err)
  }
}

export const createNewUser = async (token, user) => {
  try{
    const config = configurate(token)
    const newUser = await axios.post(baseUrl, user, config)
    const successMessage = {
      message: `Uusi käyttäjä ${newUser.username} luotu`
    }
    return(successMessage)
  } catch(err){
    console.log('error at createNewUser() : ', err)
    return(false)
  }
}

export const inviteUsers = async (token, mails, role, isStudent, expiryDate) => {
  try{
    const config = configurate(token)
    const invites = { emails: mails, role: role, isStudent: isStudent, expiryDate: expiryDate }
    const response = await axios.post(baseUrl + '/invite', invites, config)
    // console.log('response: ', response.status)
    return response.status
  } catch(err){
    console.log('error at inviteUsers() : ', err.response.data.error)
    return err.response.data.error
  }
}

export const getInvitedUsers = async (token, company) => {
  try{
    const config = configurate(token)
    const invitedUsers = await axios.get(baseUrl + '/invited/' + company, config)
    // console.log('invs: ', invitedUsers.data)

    return invitedUsers.data
  } catch(err){
    console.log('error at getInvitedUsers() : ', err)
  }
}

export const deleteUser = async (token, userId) => {
  try{
    const config = configurate(token)
    const deletedUser = await axios.delete(baseUrl + '/' + userId, config)
    return deletedUser
  } catch(err){
    console.log('error at deleteUser() : ', err)
  }
}

export const unDeleteUser = async (token, userId) => {
  try{
    const config = configurate(token)
    const unDeletedUser = await axios.get(baseUrl + '/undelete/' + userId, config)
    return unDeletedUser.data
  } catch(err){
    console.log('error at unDeleteUser() : ', err.response.data)
    return err.response.data
  }
}

export const getDeletedUsers = async (token, company) => {
  try{
    const config = configurate(token)
    const deletedUsers = await axios.get(baseUrl + '/trash/' + company, config)
    return deletedUsers.data
  } catch(err){
    console.log('error at getDeletedUsers() : ', err)
  }
}

