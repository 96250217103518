import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AddDatapoint from './AddDatapoint'
import AddData from './AddData'
import DatapointLoopContent from './DatapointLoopContent'
import DataLoopContent from './DataLoopContent'
import EditDataPoint from './EditDataPoint'
import EditData from './EditData'
import HelpText from '../../../../../../components/UI/HelpText/HelpText'
import BodyInTags from '../../../../../../components/Private/Tags/components/BodyComponents/BodyInTags/BodyInTags'
import { useDispatch } from 'react-redux'
import { updateDatapointTags } from '../../../../reducers/rdc-oneCustomer'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'

const Body_DataPoints = ({ customer, setBodyContainer }) => {
  const[showData, setShowData] = useState(null)
  const[showAddPointForm, setShowAddPointForm] = useState(false)
  const [editDataPoint, setEditDataPoint] = useState('')
  const [editData, setEditData] = useState('')
  const user = useSelector(state => state.user)

  const editdataPointHandler = () => {
  }
  const dispatch = useDispatch()
  const saveTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateDatapointTags(token, newTags, customer.id, appointmentId, tags ))
  }

  return(
    <>
      <HelpText
        className = 'mp-mb-20'
        text="Täällä voit tallettaa tietoja asiakkaasta, jotka ovat mitattavissa. Mittaustuloksia on täältä helppo tarkastella. Luo ensin mitattava asia. Tälkeen voit syöttää mittaustuloksia"
      />
      <div className="flex-row mp-mb-20">
        <div>
          {showAddPointForm !== true && <IconButton char="j" className="icon-button-main" onClick={() => {setShowAddPointForm(true)}}/>}
        </div>
        <h4 className="body-header mp-0">Seuranta</h4>
      </div>
      {showAddPointForm === true && <AddDatapoint customer={customer} user={user} setShowAddPointForm = {setShowAddPointForm} />}
      <div>
        <div>
          <div className="customers-historylist-definitions card-list-onlyRows">
            <div className="flex-container-info hover-pointer"> Seurantapiste</div>
            <div className="flex-item-right">
              Yksikkö
            </div>
          </div>
          {customer && customer.dataPoints && customer.dataPoints.map( datapoint => {
            return(
              <div key={datapoint.id} >

                {  editDataPoint !== datapoint.id
                  ?
                  <DatapointLoopContent
                    datapoint = {datapoint}
                    showData = {showData}
                    setShowData = {setShowData}
                    setEditDataPoint = {setEditDataPoint}
                    editdataPointHandler = {editdataPointHandler}
                  />
                  :
                  <EditDataPoint
                    datapoint = {datapoint}
                    customer = {customer}
                    setEditDataPoint = {setEditDataPoint}
                    user = {user}
                  />

                }

                {showData === datapoint.id &&
                <div>
                  {datapoint.data.map( data => {
                    return(
                      <div key={data.id}>
                        { data.id !== editData ?
                          <DataLoopContent data={data} setEditData={setEditData}/>
                          :
                          <EditData
                            data={data}
                            setEditData={setEditData}
                            customer={customer}
                            datapointId={datapoint.id}
                          />
                        }

                      </div>
                    )
                  })}
                  <div className="flex-item-right align-items-end">

                  </div>

                  <AddData
                    customer={customer}
                    user={user}
                    dataId={datapoint.id}
                    setBodyContainer={setBodyContainer}
                  />
                  <div className="mp-mt-20">
                    <BodyInTags
                      itemId = {datapoint.id}
                      items = {datapoint.tags}
                      selectedID={datapoint.id}
                      saveTags = {saveTags}
                      sortBy = 'dataPoints'
                    />
                  </div>

                </div>}
              </div>
            )
          })
          }
        </div>
      </div>
    </>
  )
}
export default Body_DataPoints

