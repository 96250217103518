import React from 'react'
import Title from '../../UI/Title/Title'
import AccountForm from './AccountForm'


const EditAccount = () => {

  return(
    <div className='container'>
      <Title text= "Muokkaa tiliä" icon="L"/>
      <AccountForm
        formType="editAccount"
      />
    </div>
  )
}

export default EditAccount
