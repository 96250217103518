import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../reducers/userReducer'
import { useHistory, Link } from 'react-router-dom'
import './Login.css'
import PublicHeader from '../PublicHeader/PublicHeader'

const Login = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    // User to login
    const u = {
      username,
      password
    }
    dispatch(loginUser(u)).then((response) => {
      // If response is undefined it means there were no errors
      response ? setMessage(response) : history.push('/')
    })
  }

  const handleInput = (event) => {
    switch(event.target.id){
    case 'username':
      setUsername(event.target.value.toLowerCase())
      break
    case 'password':
      setPassword(event.target.value)
      break
    default: break
    }
  }

  return(
    <>
      <PublicHeader/>
      <div id="login-container">
        <h2>Kirjaudu sisään</h2>

        <form onSubmit={handleSubmit}>

          <div className="input-group">
            <input
              placeholder="käyttäjätunnus"
              type="text"
              id='username'
              data-testid='username'
              autoComplete="username"
              onChange={handleInput}
              value={username}
            />
          </div>

          <div className="input-group">
            <input
              placeholder="salasana"
              type="password"
              id='password'
              data-testid='password'
              autoComplete="current-password"
              onChange={handleInput} />
          </div>

          <p>{message}</p>

          <button
            className="buttonGreen"
            style={{ marginTop: 0 }}
            type="submit"
            id="login-button"
            data-testid="login-button"
          >
            Kirjaudu
          </button>
        </form>

        <Link to="/forgot">
          <p style={{ color: 'var(--gray-dark)' }}>salasana hukassa?</p>
        </Link>
      </div>
    </>
  )
}

export default Login