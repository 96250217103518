import { getInvitedUsers } from '../services/users'

const invitedUsersReducer = (state = null, action) => {
  switch(action.type){
  case 'INIT_INVITED_USERS':
    return action.data

  default: return state
  }
}

export const initializeInvitedUsers = (token) => {
  return async dispatch => {
    const invited = await getInvitedUsers(token)
    dispatch({
      type: 'INIT_INVITED_USERS',
      data: invited
    })
  }
}


export default invitedUsersReducer