import React, { useState } from 'react'
import FileUpload from './FileUpload/FileUpload'
import { useDispatch } from 'react-redux'
import { addImage } from '../../../MessageReducers/newPostReducer'

const ImageUploader = () => {

  // This component launches the Cloudinary image upload widget.

  const dispatch = useDispatch()
  const [widgetOpen, setWidgetOpen] = useState(false)

  const toggleFileUpload = () => {
    setWidgetOpen(!widgetOpen)
  }

  const addImageToPost = (img) => {
    dispatch(addImage(img))
  }

  const launchButton = <button className="icon msg-icon-addphoto icon-button" onClick={toggleFileUpload} />

  return(
    <>
      {!widgetOpen ? launchButton : <FileUpload setWidgetOpen={setWidgetOpen} addImage={addImageToPost}/>}
    </>
  )
}

export default ImageUploader