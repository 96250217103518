const initialState = {
  show: false
}

const helpReducer = (state = initialState, action) => {
  switch(action.type){
  case 'SHOWHELP':{
    const newState = {
      show: true
    }
    return newState
  }

  case 'HIDEHELP':{
    return initialState
  }

  default: return state
  }
}
export const showHelp = () => {
  return{
    type: 'SHOWHELP'
  }
}

export const hideHelp = () => {
  return{
    type: 'HIDEHELP'
  }
}



export default helpReducer