import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCards, newCard } from '../reducers/cardsReducer'
import UiCard from '../../../components/UI/UiCard/UiCard'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import moment from 'moment'
import '../Cards.css'
import IconButton from '../../../components/UI/IconButton/IconButton'
import HelpPop from '../../../components/UI/HelpPop/HelpPop'


const AddNewCard = ({
  cardsId, token
}) => {
  const [newName, setNewName] = useState('')
  const [newAmount, setNewAmount] = useState('')
  const [newPayingMethod, setNewPayingMethod] = useState({ methodName: '', isPersonal: false, needReceipt: false })
  const [newInfoTxt, setNewInfoTxt] = useState('')
  const [newReceipt, setNewReceipt] = useState('')
  const [newCardContainer, setNewCardContainer] = useState(false)
  const [newProductAmount, setNewProductAmount] = useState('')
  const [newProduct, setNewProduct] = useState('Ei tuotetta')
  const [bodyStyle, setBodyStyle] = useState('add-card-section-collapsed')
  const [newCardType, setNewCardType] = useState('Valitse korttityyppi')
  const onlyNumbers = (value) => { return /^\d*\.?\d*$/.test(value) }   // to check is number
  const [notificationName, setNotificationName] = useState('')
  const [notificationReceipt, setNotificationReceipt] = useState('')
  const [notificationAmount, setNotificationAmount] = useState('arvo vaaditaan, jos tuotetta ei valittu')
  const [newSalesValue, setNewSalesValue] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [location, setLocation] = useState('')

  let formPass = false // is form valid to send
  const productsData = useSelector(state => state.products)

  // -------------------------------- states from store ------------------------------->

  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)
  const currentUserId = useSelector(state => state.user.id)
  const locations = useSelector(state => state.locations)
  const payingMethods = cards.cards_payingMethods
  const help = useSelector(state => state.help && state.help.show)
  const user = useSelector(state => state.user)

  // refreshing cards db, so we have for sure latest data for nextCardNumber
  useEffect(() => {
    user && dispatch(getCards( user.token, user.company))
  }, [user, dispatch])

  const nextCardNumber = useSelector( state => state.cards.nextCardNumber)

  // scroll up when new card is being created
  useEffect(() => {window.scrollTo({ top: 0,  behavior: 'smooth' })},[newCardContainer])

  // new card form toggler
  const handleNewCard = (event) => {
    event.preventDefault()
    newCardContainer === true ? setNewCardContainer(false) : setNewCardContainer(true)
  }

  // to show submit button or not
  if( newAmount >= 0
    && notificationReceipt === ''
    && notificationName === ''
    && notificationAmount !== 'arvo vaaditaan, jos tuotetta ei valittu'
    && newPayingMethod.methodName !== 'Valitse myyntitapa'
    && newCardType !== 'Valitse korttityyppi'
  ) {
    formPass = true } else formPass = false

  // adding new card to mongoDB
  const handleAddNewCard = (event) => {
    event.preventDefault()

    if(formPass === true) {
      let expiryDate = moment()
      expiryDate.add(cards.expiryTime, 'months')
      expiryDate = expiryDate.toISOString()

      // creating object
      const cardObject = {
        customerName: newName,
        // seller: newPayingMethod.methodName,
        payingMethod: newPayingMethod,
        amount: newAmount,
        receipt: newReceipt,
        info: newInfoTxt,
        active: true,
        product: newProduct,
        productAmount: newProductAmount,
        cardType: newCardType,
        salesValue: newSalesValue,
        email: newEmail,
        createdBy: currentUserId,
        isSent: false,
        location: location
      }

      // update cards state

      dispatch(newCard(token, cardsId, cardObject ))  // update cards to DB
      dispatch(setNotification({
        notification: 'Uusi kortti on luotu',
        type: 'default' }))

      // reset states
      setNewName('')
      setLocation('')
      setNewAmount('')
      setNewReceipt('')
      setNewPayingMethod('')
      setNewInfoTxt('')
      setNewEmail('')
      setNewProduct('Ei tuotetta')
      setBodyStyle('add-card-section-collapsed')
      setNewCardContainer(false)
      setNewCardType('Valitse korttityyppi')
      setNewSalesValue('')

      // timeout for modals
      setTimeout(() => {
      // setAlertMessage(null)
      // setErrorMessage(null)
      }, 20000)
    }
  }

  // selection of products

  const ListOfProducts = ({ newProduct }) => {
    return(
      <>
        <option value={newProduct}>{newProductAmount && newProductAmount + ' x ' }{newProduct}</option>
        {productsData.filter(product => product.locationId === location).map(x => <option key={x.id} value={x.productName}>{x.productName} ({x.productValue} €)</option>)}
        <option value="Ei tuotetta">Ei tuotetta</option>
      </>
    )
  }

  // ----------------------------- Handlers -----------------------------------//

  const setNewInfoHandler = (event) => { setNewInfoTxt(event.target.value) }
  // SET AMOUNT HANDLER ---------------------------------------------------------->

  const emailHandler = (event) => { setNewEmail(event.target.value) }

  const setNewAmountHandler = (event) => {

    // replace comma to dot
    if(event.target.value !== '') {setNotificationAmount('')}
    else if(event.target.value === '') setNotificationAmount('arvo vaaditaan, jos tuotetta ei valittu')
    const eventEdited = event.target.value.replace(/,/g, '.')

    // amount imput is possible to fill, if product / product amount has no value
    if(newProduct === 'Ei tuotetta' && newProductAmount === '' ){
      onlyNumbers(eventEdited) && setNewAmount(eventEdited)
    } else {
      // if product is choosen amount is autofilled
      const productValue = productsData.filter(x => x.productName === newProduct)[0].productValue
      setNewAmount(newProductAmount * productValue)
    }
  }

  // handler for product ---------------------------------------------------------->

  const setNewProductHandler = (event) => {
    setNewProduct(event.target.value)
    if(event.target.value === 'Ei tuotetta') {setNewProductAmount('')}
    if(event.target.value !== '' && event.target.value !== 'Ei tuotetta'){
      setNotificationAmount('Arvo on laskettu automaattisesti')
    }

    if(event.target.value === 'Ei tuotetta' || event.target.value === ''){
      setNotificationAmount('arvo vaaditaan, jos tuotetta ei valittu')
      setNewAmount(0)
    }

    // product prize is overriding amount-input if product and amount of products is choosen
    const productValue = productsData.filter(x => x.productName === event.target.value)[0].productValue
    if(event.target.value !== 'Ei tuotetta' && newProductAmount !== ''){
      setNewAmount(newProductAmount * productValue)}
    if(event.target.value){ if(newProductAmount < 1){setNewAmount(productValue)} }

  }

  // handler for amount of products ---------------------------------------------------------->

  const setNewProductAmountHandler = (event) => {
    if(event.target.value === ''){setNewProduct('Ei tuotetta')}
    setNewProductAmount(event.target.value)
    // product prize is overriding amount-input if product and amount of products is choosen
    if(newProduct !== 'Ei tuotetta' && event.target.value !== ''){
      const productValue = productsData.filter(x => x.productName === newProduct)[0].productValue
      setNewAmount(event.target.value * productValue)}
  }

  // if new card form is triggered ...

  if (newCardContainer === true){
    setTimeout(() => {
      setBodyStyle('add-card-section')
    }, 2)

    // name hander ---------------------------------------------------------------------->

    const nameHandler = (event) => {
      setNewName(event.target.value)
      event.target.value !== '' ? setNotificationName('') : setNotificationName('Virike-etu vaatii nimen käytön')
    }

    // Receipt handler ------------------------------------------------------------------->
    const receiptHandler = (event)  => {
      setNewReceipt(event.target.value)
      if (event.target.value !== '') { setNotificationReceipt('') }
      else if ( newPayingMethod.needReceipt === true) setNotificationReceipt('Kuitin numero vaaditaan')
    }

    const setNewSalesValueHandler = (event) => {
      event.preventDefault()
      setNewSalesValue(event.target.value)
    }

    // paying method handler ---------------------------------------------------------->
    // method.id, method.methodName, method.needReceipt, method.isPersonal
    const payingMethodHandler = (event) => {
      if(event.target.value !== 'null' && newPayingMethod !== ''){

        // we know now mothods id, so with filter we'll find out full object
        let method = payingMethods.filter(m => m.id === event.target.value)[0]
        // method = ({ ...method, id: event.target.value })
        setNewPayingMethod(method)

        // if is personal card, card needs to have a owner name
        if(newName) { setNotificationName('') }
        else if( method.isPersonal === true) {setNotificationName('Virike-etu vaatii nimen käytön')}
        else  { setNotificationName('') }

        // if paying method needs receipt
        if( method.needReceipt === true) {setNotificationReceipt('kuitin numero vaaditaan')}
        else {setNotificationReceipt('')}
      }
    }


    return (
      <div className={bodyStyle}>
        <UiCard
          headerTitle = {'Lisää uusi kortti (' + nextCardNumber + ')'}
        >
          {/* Back button */}
          <div style={{ position: 'absolute', zIndex: '10 ', top: '15px', right: '20px' }}>
            <IconButton
              type="button"
              onClick={() => {setNewCardContainer(false)
                setBodyStyle('add-card-section-collapsed')}}
              className="icon-button-gray"
              size="20px"
              text = {help}
              char="x"
            />
          </div>
          <form onSubmit={handleAddNewCard} className= 'flex-column-container' style={{ marginTop: 0, paddingTop: 0 }}>
            <div className="flex-row-container">
              <IconButton className="icon-button-secondary" char="S"/>
              <div>
                <p>Harmaat syötöt vaatii huomiota. <br/> Vihreäreunaiset on tyytyväisiä. Tarkista silti jokainen arvo huolellisesti.</p>
                <p>Tarkista kortin luonnin jälkeen, että kortin numero täsmää varmasti!
                  <HelpPop
                    text = {'Kortin numero (' + nextCardNumber + ') pitää tarkistaa luonnin jälkeen, koska numero perustuu sivun lataushetken tilanteeseen. Jos joku toinen tekee kortin samaan aikaan, voi numero olla väärä'}
                    className = "help-pop-p"
                  />
                </p>
              </div>
            </div>

            {/* Toimipiste */}
            <div className='input-group'>
              <label>Toimipiste
                <HelpPop
                  text = "Toimipiste pitää olla valittuna, mikäli valitaan tuote"
                  className = "help-pop-p"
                />
              </label>
              <select onChange={(e) => setLocation(e.target.value)}
                className={location !== '' ? 'passed' : 'not-passed' }
              >
                <option value="">Valitse toimipiste</option>
                {locations.map( loc => {
                  return(
                    <option key={loc.id} value = {loc.id}>{loc.name}</option>
                  )
                })}
              </select>
            </div>
            {/* Nimi */}
            <div className='input-group'>
              <label>Nimi</label>
              <input
                className={notificationName === '' ? 'passed' : 'not-passed' }
                placeholder={notificationName === '' ? 'Nimi' : notificationName}
                value={newName}
                onChange={nameHandler}
              />
            </div>

            {/* email */}
            <div className='input-group'>
              <label>e-mail</label>
              <input
                className= 'passed'
                placeholder = "e-mail"
                value={newEmail}
                onChange={emailHandler}
              />
            </div>

            {/* Paying methods */}
            <div className='input-group'>
              <label>Maksutapa</label>
              <select
                className = {(newPayingMethod.methodName !== '')  ? 'passed' : 'not-passed'}
                defaultValue = {newPayingMethod}
                onChange={payingMethodHandler}>
                <option value='null'>Valitse maksutapa</option>
                { payingMethods.map(method =>
                  <option key = {method.id} value={method.id}>{method.methodName}</option>
                )}
              </select>
            </div>

            {/* Kuitin nro */}
            <div className="input-group">
              <label>Kuitin numero</label>
              <input
                className = {notificationReceipt === '' ? 'passed' : 'not-passed' }
                placeholder = {notificationReceipt !== '' ? notificationReceipt : 'Kuitin numero'}
                value={newReceipt} onChange= {receiptHandler}/>
            </div>

            {/* Tuote */}

            <div className = "input-group">
              <label>Valitse tuote</label>
              {location !== '' ?
                <div className= 'flex-container-add' >
                  { newProduct !== 'Ei tuotetta' &&
               <div className="flex-item-add-amount">
                 {newProductAmount > 0 ? <> <input  placeholder='Kpl' className = 'passed' value={newProductAmount} onChange={setNewProductAmountHandler}/></> :
                   <> <input  placeholder='Kpl' className = 'not-passed'  value={newProductAmount} onChange={setNewProductAmountHandler}/></>}
               </div>
                  }
                  <div className= 'flex-item-add-product'>
                    <select
                      onChange={setNewProductHandler}
                      className="passed"
                    >
                      <ListOfProducts
                        newProduct = {newProduct}
                      />
                    </select>
                  </div>
                </div>
                :
                <div className= 'flex-container-add' >
                  Valitse toimipiste, jos haluat lisätä tuotteen.
                </div>
              }

            </div>

            {/* Arvo */}

            <div className="input-group">
              <label>Arvoa kortilla</label>
              <input
                placeholder='Arvoa kortilla'
                className = { newAmount > 0 ? 'passed' : 'not-passed'}
                value={newAmount}
                onChange={setNewAmountHandler}/>
            </div>

            {/* Myyntiarvo */}

            <div className = "input-group">
              <label>Myyntiarvo
                <HelpPop
                  text = 'Jos kortti on myyty alennuksella, merkitse myyntiarvo tähän. Muuten jätä kohta tyhjäksi'
                />
              </label>
              <div className="flex-container-add">
                <div className="flex-item-add-product">
                  <input placeholder='Myyntiarvo / tarjoushinta' className = 'passed' value={newSalesValue} onChange={setNewSalesValueHandler}/>
                </div>
                <div className = "flex-item-add-prosent" >
                  <button
                    className = 'passed'
                    value = {Math.round(Number(newAmount * 0.95)*100)/100}
                    onClick={setNewSalesValueHandler}>
                    -5%
                  </button>

                </div>
                <div className = "flex-item-add-prosent" >
                  <button
                    className = 'passed'
                    value = {Math.round(Number(newAmount * 0.9)*100)/100}
                    onClick={setNewSalesValueHandler}>
                      -10%
                  </button>
                </div>
              </div>
            </div>
            {/* TYYPPI (sarja / lahjakortti) */}

            <div className = "input-group">
              <label>Valitse korttityyppi</label>
              <select
                className={ newCardType === 'Valitse korttityyppi' ? 'not-passed' : 'passed' }
                defaultValue={newCardType}
                onChange={(event) => {setNewCardType(event.target.value)}}>
                <option value="Valitse korttityyppi">Valitse korttityyppi</option>
                <option value="Lahjakortti">Lahjakortti</option>
                <option value="Sarjakortti">Sarjakortti</option>
              </select>
            </div>

            {/* Lisätiedot */}
            <div className = "input-group">
              <label>Lisätiedot</label>
              <textarea className = "passed" placeholder='Mahdolliset lisätiedot' value={newInfoTxt} onChange={setNewInfoHandler}></textarea>
            </div>

            {/* BUTTONS */}
            <div className="flex-item-right-button mp-mb-40 mp-mt-20">{
              formPass === true
                ? <button className="buttonGreen" type="submit"> Lisää kortti summalla {newAmount} €</button>
                : <button type="button" className="buttonRed"> Lisää kortti - Tarkista lomakken arvot </button> }

            </div>
          </form>
        </UiCard>
      </div>
    )} else return (
    <>

      <IconButton
        className="icon-button-main"
        onClick={handleNewCard}
        char="j"
        size="35px"
      />

    </>
  )

}

export default AddNewCard
