import React, { useEffect } from 'react'
import UserList from './UserList/UserList'
import UserFilter from './UserList/UserFilter/UserFilter'
import { useDispatch, useSelector } from 'react-redux'
import { initializeUsers } from '../../../reducers/usersReducer'

const UserAdmin = () => {

  const token = useSelector(state => state.user && state.user.token)
  const company = useSelector(state => state.user && state.user.company)
  const filteredUsers = useSelector(state => state.users && state.users.filteredUsers)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeUsers(token, company))
  }, [dispatch, token])

  return(
    <div className="container">
      <UserFilter />
      <UserList users={filteredUsers && filteredUsers}/>
    </div>
  )
}

export default UserAdmin