import React from 'react'
import './Title.css'

const Title = ({ text, headinLevel, className, icon }) => {

  if(!headinLevel){ headinLevel = 'h1'}

  return(
    text &&
      <>
        <div className={'title-heading-container'}/>
        {headinLevel === 'h1'&&
          <h1 style = {{ textAlign: 'center' }} className={ className }><span className = "title-symbol" >{icon} </span> {' '} {text}</h1>}
      </>
  )
}

export default Title