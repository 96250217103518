import React from 'react'
import IconButton from '../../../../components/UI/IconButton/IconButton'
// import ButtonWithIcon from '../../../../components/UI/ButtonWithIcon/ButtonWithIcon'

const BodyButtons = ({ setBodyContainer, customer, setSelectedID }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0,  behavior: 'smooth' })
  }
  return(
    <div className='body-buttons-container'>
      <IconButton  className = 'icon-button-secondary' char="U" text="Kirjaukset"     onClick={ () => { setBodyContainer('1'); scrollTop(); setSelectedID(customer.id)}}/>
      <IconButton  className = 'icon-button-secondary' char="L" text="Tiedot"         onClick={ () => { setBodyContainer('2'); scrollTop(); setSelectedID(customer.id)}}/>
      <IconButton  className = 'icon-button-secondary' char="W" text="Tutkimukset"    onClick={ () => { setBodyContainer('3'); scrollTop(); setSelectedID(customer.id)}}/>
      <IconButton  className = 'icon-button-secondary' char="P" text="Ohjeistukset"   onClick={ () => { setBodyContainer('4'); scrollTop(); setSelectedID(customer.id)}}/>
      <IconButton  className = 'icon-button-secondary' char="T" text="Seuranta"       onClick={ () => { setBodyContainer('6'); scrollTop(); setSelectedID(customer.id)}}/>
    </div>
  )
}

export default BodyButtons