import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

// use this to get date in dd.mm.Year form
// if props are empty it is returning current date

const DayAndTime = (props) => {
  let day
  let time
  let date
  // UTC fixed time:
  const today = new Date( Number(new Date().getTime()) + 2 * 60 * 60 * 1000)
  if(props.date){ date = props.date} else date = today.toJSON()

  day =  date.slice(8,10)+
    '.' + date.slice(5,7) +
    '.' + date.slice(0,4)
  time = date.slice(11,13) + ':' + date.slice(14,16)

  return(
    <div style = {{ fontSize: '13px', display: 'flex' }} >
      {day} <div style={{ marginRight: '5px', marginLeft: '5px' }}><FontAwesomeIcon icon={faClock}/> </div>{time}
    </div>
  )
}

export default DayAndTime