import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/UI/Button/Button'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import { updateCard, deleteCard } from '../reducers/cardsReducer'

const GiftCardDelete = ({ setButtonStyleEdit, giftNavigate, num, setDeleted, id, setIdSelected } ) =>
{
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const cards = useSelector(state => state.cards)
  // --------- H A N D L D E R S ----------------------------- //

  // REACTIVATE CARD HANDLER ----------------------------------->
  const handleActivateCard = (event) => {
    event.preventDefault()
    const cardObject = { active: true }
    dispatch(updateCard(token, cardObject, id, cards ))  // update cards
    giftNavigate(0)
    setButtonStyleEdit('defaultButton')
    setDeleted(false)

    // pop notification about update
    dispatch(setNotification({
      notification: `Kortti numero ${num} on palautettu`,
      type: 'default' }))
    setButtonStyleEdit('defaultButton')
    setIdSelected('')
  }

  // DELETE PERMANANTLY CARD HANDLER ----------------------------------->

  const handleDeleteCard = (event) => {
    event.preventDefault()
    if(window.confirm(`Poista kortti nro ${num}? TOIMINTOA EI VOIDA PERUA!`)){

      dispatch(deleteCard(token, id, cards ))  // delete card, and response with updated cards

      // pop notification about update
      dispatch(setNotification({
        notification: `Kortti nro ${num} on poistettu tietokannasta lopullisesti`,
        type: 'default' }))

      setDeleted(true)
      giftNavigate(0)
      setIdSelected('')
    }
  }

  return (
    <div className="giftcard-body-container">
      <h3> Kortti nro {num} on siirretty roskikseen!</h3>
      <p>Käytettyä korttia ei voi poistaa tietokannasta kokonaan.
        Testimielssä tehdyn kortin voi poistaa kokonaan,
        jos sen nimeksi antaa ensin &quot;testi&quot;</p>
      <Button
        label = 'Peru poisto!'
        className="buttonGreen"
        onClick={handleActivateCard}
      />
      <br/>
      <Button
        label = 'Poista kortti tietokannasta lopullisesti!'
        className="buttonRed"
        onClick={handleDeleteCard}
      />
    </div>
  )
}

export default GiftCardDelete