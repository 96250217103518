const initialState = ''

const themeReducer = (state = initialState, action) => {
  switch(action.type){
  case 'SET_THEME':
    return action.data

  default: return state
  }
}

export const setTheme = (data) => {


  return async dispatch => {
    dispatch({
      type: 'SET_THEME',
      data: data
    })
  }
}

export default themeReducer