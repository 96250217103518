import  {
  cardsGet,
  cardUpdate,
  cardAdd,
  cardNewAccount,
  cardUpdateAccount,
  carUsageDelete,
  cardDdelete,
  cardPayingMethodAdd,
  cardPayingMethodDelete
} from '../services/cards_services'

const cardsReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_CARDS':
    return action.data
  default: return state
  }
}

// get cards dorm db
export const getCards = (token, company) => {
  return async dispatch => {
    let cards = await cardsGet(token, company)
    if(cards.data === null){cards = 'noAccountYet'
    } else {
      cards = cards.data
      cards.cards_cards = cards.cards_cards.reverse()
    }
    dispatch({
      type: 'SET_CARDS',
      data: cards
    })
  }
}

// Update cards
// router.put('/updatecard/:accountId/:cardId', async (req, res) => {
export const updateCard = (token, body, cardId, cards) => {
  return async dispatch => {
    const updatedCard = await cardUpdate(token, body, cardId)
    const cardsArray = cards.cards_cards
    const updatedCardsArray = await cardsArray.map( card => card.id === cardId ? updatedCard.data : card)
    let updatedCards = cards
    updatedCards.cards_cards = updatedCardsArray
    dispatch({
      type: 'SET_CARDS',
      data: updatedCards
    })

  }
}

// add new card
export const newCard = (token, companyId, body) => {
  return async dispatch => {
    let cards = await cardAdd(token, companyId, body)
    cards = cards.data
    cards.cards_cards = cards.cards_cards.reverse()
    await
    dispatch({
      type: 'SET_CARDS',
      data: cards
    })
  }
}

// Delete Card
// let response = await axios.post(baseUrl + '/deletecard/'  + cardId, config)
export const deleteCard = (token, cardId, cards) => {
  return async dispatch => {

    // response is not needed
    await cardDdelete(token, cardId)

    // filter deleted card by id form cards array
    const cardsArray = cards.cards_cards
    const updatedCardsArray = await cardsArray.map( card => card.id !== cardId)

    // update array to cards object
    let updatedCards = cards
    updatedCards.cards_cards = updatedCardsArray

    await
    dispatch({
      type: 'SET_CARDS',
      data: updatedCards
    })
  }
}

// new Account
export const newAccount = (token, companyId, body) => {
  return async dispatch => {
    const cards = await cardNewAccount(token, companyId, body)
    await
    dispatch({
      type: 'SET_CARDS',
      data: cards.data
    })

  }
}

// update account
export const updateAccount = (token, companyId, body) => {
  return async dispatch => {
    const cards = await cardUpdateAccount(token, companyId, body)
    await
    dispatch({
      type: 'SET_CARDS',
      data: cards.data
    })

  }
}

// usage delete (return to card) carUsageDelete

export const deleteCardUsage = (token, usageId, cards, cardId) => {
  return async dispatch => {
    const updatedCard = await carUsageDelete(token, cardId, usageId)
    const updatedCardsArray = cards.cards_cards.map( card => card.id === cardId ? updatedCard.data : card)

    let updatedCards = cards
    updatedCards.cards_cards =  await updatedCardsArray
    await
    dispatch({
      type: 'SET_CARDS',
      data: updatedCards
    })

  }
}

// add new payingMethod reducer
export const addNewCardPayingMethod = (token, accountId, body) => {
  return async dispatch => {
    const cards = await cardPayingMethodAdd(token, accountId, body)
    await
    dispatch({
      type: 'SET_CARDS',
      data: cards.data
    })

  }
}

// delete payingMethod reducer
export const deleteCardPayingMethod = (token, accountId, methodId) => {
  return async dispatch => {
    const cards = await cardPayingMethodDelete(token, accountId, methodId)
    await
    dispatch({
      type: 'SET_CARDS',
      data: cards.data
    })

  }
}

export default cardsReducer