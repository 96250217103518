import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomers } from './reducers/rdc-customers'
import Customer from './components/AppBody/Customer'
import AppHeader from './components/AppHeader/AppHeader'
import './Customers.css'
import Loader from '../../components/UI/Loader/Loader'

const Customers = () => {
  const dispatch = useDispatch()
  const customersDb =                  useSelector(state => state.users && state.customers)

  const user =             useSelector(state => state.user)
  const customer =             useSelector(state => state.oneCustomer)

  // states
  const [selectedID,          setSelectedID]            = useState(null)
  const [filteredCustomers,   setFilterdCustomers]      = useState(customersDb)
  const [bodyContainer,       setBodyContainer]         = useState(null)
  const [customers,           setCustomers]             = useState(customersDb)

  const [searchWord, setSearchWord] = useState('')

  useEffect(() => {
    // get customers
    user && dispatch(getCustomers( user.token, user.company))
    // reset filteredCustomers (needed when adding new customer to include it in array)
    customersDb && setFilterdCustomers(customersDb)
  }, [user, dispatch, bodyContainer, customer])

  // sorting customers
  let sortedCustomers
  if(Array.isArray(filteredCustomers)) {
    sortedCustomers = filteredCustomers.slice(0,50)
    // sortedCustomers = filteredCustomers.sort((a,b) => { return b.appointmentsAmount - a.appointmentsAmount })
  }
  // scroll to up, when arriving to site
  useEffect( () => {window.scrollTo(0, 0)},[bodyContainer])


  // somehow customers are not shown when page is loaded first time.

  setTimeout(() => {
    if(!customers) {setCustomers(customersDb)
      customersDb && searchWord === ''  && setFilterdCustomers(customersDb)}
  },500)

  return(
    customers ?
      <div>
        <AppHeader
          selectedID={selectedID}
          setFilterdCustomers = {setFilterdCustomers}
          filteredCustomers = {filteredCustomers}
          customers = {customersDb}
          bodyContainer = {bodyContainer}
          setBodyContainer = {setBodyContainer}
          searchWord = {searchWord}
          setSearchWord = {setSearchWord}
        />

        <div className = "flex-column-reverse mp-mt-20">
          {(Array.isArray(filteredCustomers) && bodyContainer !== '0' && Array.isArray(filteredCustomers) === true ) &&
      sortedCustomers.map(x =>
        <div key={x.id}>
          <Customer
            customer={x}
            selectedID = {selectedID}
            setSelectedID = {setSelectedID}
            bodyContainer = {bodyContainer}
            setBodyContainer = {setBodyContainer}
            customers = {customers}
            setCustomers = {setCustomers}
            searchWord = {searchWord}
            setSearchWord = {setSearchWord}
            setFilterdCustomers = {setFilterdCustomers}
          />
        </div>
      )}
        </div>
      </div>
      :
      <Loader/>
  )
}

export default Customers