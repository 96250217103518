import React from 'react'
import AuthorCircle from '../../../../../components/UI/AuthorCircle/AuthorCircle'
import { useHistory } from 'react-router-dom'
import { setPostThreadId, resetPost } from '../../../MessageReducers/newPostReducer'
import { useDispatch } from 'react-redux'
import './ThreadListItem.css'
import { useSelector } from 'react-redux'
import DayAndTime from '../../../../../functions/DayAndTime'

const ThreadListItem = ({ thread }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)

  const onClickThread = (id) => {
    dispatch(resetPost())
    dispatch(setPostThreadId(id))
    history.push('/msg/' + id)
  }

  return(
    <div className = {'hover-pointer thread-list-item' + theme} key = {thread.id} onClick={() => onClickThread(thread.id)} >
      <div className="msg-auth-circle flex-center"> <AuthorCircle author={thread.posts[0].author}/></div>
      <div className="thread-info">
        <div className="thread-topic">
          <h5>{thread.topic}</h5>
        </div>
      </div>
      <div className="thread-created-info">
        {thread.posts[0].firstname} {thread.posts[0].lastname}
        <br/>
        <span className="timestamp">
          <DayAndTime date={thread.date}/>
        </span>
      </div>
    </div>
  )
}

export default ThreadListItem