import React from 'react'
import './UserList.css'
import UserListItem from './UserListItem/UserListItem'
import Title from '../../../UI/Title/Title'
import langs from '../../../../langs'
import { useSelector } from 'react-redux'

const UserList = ({ users }) => {
  const trans = langs.lang
  const lN = useSelector(state => state.user.language)


  function compare( a, b ) {
    if ( a.firstname < b.firstname ){
      return -1
    }
    if ( a.firstname > b.firstname ){
      return 1
    }
    return 0
  }
  const usersOrdered = users.sort( compare )

  let usersToDisplay
  if(users){

    usersToDisplay = usersOrdered.map(u => {

      return <UserListItem
        firstname = {u.firstname}
        company = {u.company}
        lastname = {u.lastname}
        username = {u.username}
        role = {u.role}
        id = {u.id}
        key = {u.id}
        active = {u.active}
        createdDate = {u.userCreated}
        expiryDate = {u.expiryDate}
        isStudent = {u.isStudent}
      /> })
  }

  return(
    <div>
      <Title text = {trans.searchResults[lN]} icon="i"/>

      { usersToDisplay && usersToDisplay}

    </div>
  )
}

export default UserList