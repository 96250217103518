import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterUsers } from '../../../../../reducers/usersReducer'
import './UserFilter.css'
import Title from '../../../../UI/Title/Title'
import langs from '../../../../../langs'
import UiCard from '../../../../UI/UiCard/UiCard'
import Button from '../../../../UI/Button/Button'


const UserFilter = () => {

  const filter = useSelector(state => state.users && state.users.filter)
  const dispatch = useDispatch()
  const trans = langs.lang
  const lN = useSelector(state => state.user.language)

  const setNewFilter = (event) => {

    const val = event.target.value
    const id = event.target.id
    let newUserRoleFilter = [...filter.roles]
    let newFilter = { ...filter }

    switch(id){
    case 'firstname-filter':
      newFilter.firstname = val
      break

    case 'lastname-filter':
      newFilter.lastname = val
      break

    case 'username-filter':
      newFilter.username = val
      break

    case 'active-only-filter':
      newFilter.activeOnly = !filter.activeOnly
      break

    case 'role-user-filter':
      newFilter.roles.includes(val) ? newUserRoleFilter = filter.roles.filter(r => r !== 'user') : newUserRoleFilter.push(val)
      newFilter.roles = newUserRoleFilter
      break

    case 'role-staff-filter':
      newFilter.roles.includes(val) ? newUserRoleFilter = filter.roles.filter(r => r !== 'staff') : newUserRoleFilter.push(val)
      newFilter.roles = newUserRoleFilter
      break

    case 'role-admin-filter':
      newFilter.roles.includes(val) ? newUserRoleFilter = filter.roles.filter(r => r !== 'admin') : newUserRoleFilter.push(val)
      newFilter.roles = newUserRoleFilter
      break

    case 'role-customer-filter':
      newFilter.roles.includes(val) ? newUserRoleFilter = filter.roles.filter(r => r !== 'customer') : newUserRoleFilter.push(val)
      newFilter.roles = newUserRoleFilter
      break

    case 'is-student-filter':
      newFilter.isStudent = !filter.isStudent
      break

    default: break
    }

    dispatch(filterUsers(newFilter))
  }

  const clearTextInputs = () => {
    let newFilter = { ...filter }
    newFilter.firstname = ''
    newFilter.lastname = ''
    newFilter.username = ''
    dispatch(filterUsers(newFilter))
  }

  return(

    <>
      <Title text = 'Käyttäjähallinta' icon="w"/>
      <UiCard headerTitle = {trans.searchUser[lN]}>
        <div className="giftcard-body-container">
          <div className="filter-options">
            <div className="input-group input-1-3">
              <input id="firstname-filter" placeholder={trans.firstName[lN]} type="text" value={filter.firstname}  onChange={setNewFilter} />
            </div>

            <div className="input-group input-2-3">
              <input id="lastname-filter" type="text" placeholder= {trans.lastName[lN]} value={filter.lastname}  onChange={setNewFilter} />
            </div>

            <div className="input-group input-3-3">
              <input id="username-filter" type="text" placeholder={trans.userName[lN]} value={filter.username}  onChange={setNewFilter} />
            </div>
            <div className="input-group input-1-4">
              <Button onClick={clearTextInputs} type = 'buttonGreen' icon = "undo" label={trans.clear[lN]}/>
            </div>
          </div>

          <div className="filter-options">
            <div className="input-group input-active-only checkbox-input">
              <input type="checkbox" name="active-only" id="active-only-filter" checked={filter.activeOnly ? true : false} onChange={setNewFilter} />
              <label htmlFor="active-only">{trans.hideInActive[lN]}</label>
            </div>

            <div className="input-group input-is-studen checkbox-input">
              <input type="checkbox" checked={filter.isStudent} name="is-student" id="is-student-filter" onChange={setNewFilter} />
              <label htmlFor="is-student">{trans.showOnlyStudents[lN]}</label>
            </div>
          </div>

          <h4 style={{ color: 'var(--dark-gray)', margin: '6px 0 6px 0' }}>{trans.showUserLevels[lN]}</h4>

          <div className="filter-options">
            <div className="input-group checkbox-input">
              <input type="checkbox" id="role-user-filter" name="user" value="user" onChange={setNewFilter} checked={filter.roles.includes('user')}/>
              <label htmlFor="user">{trans.subscriber[lN]}</label>
            </div>

            <div className="input-group checkbox-input">
              <input type="checkbox" id="role-staff-filter" name="staff" value="staff" onChange={setNewFilter} checked={filter.roles.includes('staff')}/>
              <label htmlFor="staff">{trans.staff[lN]}</label>
            </div>

            <div className="input-group checkbox-input">
              <input type="checkbox" id="role-admin-filter" name="admin" value="admin" onChange={setNewFilter} checked={filter.roles.includes('admin')}/>
              <label htmlFor="admin">{trans.admin[lN]}</label>
            </div>
            <div className="input-group checkbox-input">
              <input type="checkbox" id="role-customer-filter" name="customer" value="customer" onChange={setNewFilter} checked={filter.roles.includes('customer')}/>
              <label htmlFor="admin">Asiakas</label>
            </div>
          </div>
        </div>
      </UiCard>
    </>
  )
}

export default UserFilter