import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { editDataPointDb, deleteDataPoint } from '../../../../reducers/rdc-oneCustomer'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'


const EditDataPoint = ({ datapoint, customer, setEditDataPoint, user }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState(datapoint.name)
  const [unit, setUnit] = useState(datapoint.unit)

  const sendNewDataPointName_Handler = () => {
    const body = {
      name: name,
      unit: unit,
      dataPointId: datapoint.id
    }
    dispatch(editDataPointDb(user.token, body, customer.id))
    setEditDataPoint(null)
    dispatch(setNotification({
      notification: `Seurantapisteen nimi: ${name} ja yksikkö: ${unit} pävitetty onnistuneesti`,
      type: 'default' }))
  }

  return(
    <>
      <div className="card-list even-odd">
        <div className="flex-container-info">
          <div>
            <input
              placeholder={name}
              value = {name}
              onChange={(e) => {setName(e.target.value)}}
            />
          </div>
        </div>
        <div className="flex-item-right mp-mr-0">
          <input
            placeholder={unit}
            value = {unit}
            onChange={(e) => {setUnit(e.target.value)}}
          />
        </div>
      </div>
      <div className="buttons-row">
        <IconButton
          className="icon-button-main"
          size="20px"
          char="u"
          onClick={sendNewDataPointName_Handler}
          text="Päivitä"
        />
        <IconButton
          className="icon-button-gray"
          char="M"
          size="20px"
          text="Poista"
          onClick={
            () => {
              dispatch(setNotification({
                notification: 'Ookkonää ny iha varma?',
                type: 'modal',
                onClickConfirm: () => dispatch(deleteDataPoint(user.token, customer.id, datapoint.id)),
                onClickBack: () => setEditDataPoint(null)
              },
              100000
              ))
            }
          }
        />
        <IconButton
          className="icon-button-gray"
          size="20px"
          char="X"
          onClick={() => setEditDataPoint(null)}
          text="Peruuta"
        />
      </div>
    </>
  )
}

export default EditDataPoint