import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LinkListItem from './LinkListItem/LinkListItem'
import { removeLink } from '../../../../MessageReducers/newPostReducer'

const Links = () => {

  const postLinks = useSelector(state => state.newPost && state.newPost.links)
  const dispatch = useDispatch()

  const [linkItems, setLinkItems] = useState(postLinks)

  useEffect(() => {
    if(postLinks){
      const removeLinkFromList = (item) => {
        dispatch(removeLink(item))
      }
      const newLinks = postLinks.map(l => <LinkListItem item={l} remove={removeLinkFromList} key={`${l}-${Math.random() * 99999999}`} />)
      setLinkItems(newLinks)
    }
  }, [postLinks, dispatch])

  return(
    <div style={{ maxWidth: '80vw', overflowX: 'hidden' }}>
      {linkItems.length > 0 && <h5 style={{ marginBottom: '15px' }}>Lähetettävät linkit: </h5>}
      {linkItems && linkItems}
    </div>
  )
}

export default Links