// -----------------------------------------------------------------------|
//             VIEW OR EDIT ITEMS WHEN LOOPING CONTENT                    |
//                depndend of bodyComponents                              |
// -----------------------------------------------------------------------|

import React , { useState }from 'react'
import { updateTag } from '../../../reducers/tagsReducer'
import { useDispatch, useSelector } from 'react-redux'
import modifyKey from '../../../../../../functions/modifyKey'
import IconButton from '../../../../../UI/IconButton/IconButton'


// ------------- input with update handler -------------------------------
const DataInput = ({ title, data, name, tag }) => {

  const dispatch = useDispatch ()

  // selectors

  const help = useSelector (state => state.help.show)
  const user = useSelector(state => state.user)
  const categories = useSelector(state => state.tagCategories)

  // local states

  const [openForm, setOpenform] = useState(false)
  const [newData, setNewData]= useState(data)
  const [newCategory, setNewCategory]= useState(data + ',' + tag.categoryId)
  // ------------------------------------------|
  //               UPDATE HANDLER              |
  // ------------------------------------------|

  const handlerUpdateClient = () => {

    // mofify tag object containing name (variable) -key and value for key is newData
    let modifiedTag = tag

    // category is setting 2 keys at ones. Thats why exception
    if(name === 'category'){
      modifiedTag.categoryId = newCategory.split(',')[1]
      modifiedTag.category = newCategory.split(',')[0]

    // if not category use modifyKey function
    } else modifiedTag = modifyKey(tag, name, newData)

    // updating tag to db
    modifiedTag && dispatch(updateTag( user.token, modifiedTag, tag.id))
    setOpenform(false)
  }

  const currentDataToShow = () => {
    if (data === 'instructions') return 'Ohjeistukset'
    if (data ===  'check') return 'Tutkimukset'
    if (data ===  'notes') return 'Asiakastapaaminen'
    if (data ===  'dataPoints') return 'Seuranta'
    if (data ===  'other') return 'Muu'
    if (data ===  false) return 'Ei'
    if (data ===  true) return 'Kyllä'
    else return data
  }

  // ------------------------------------------|
  //       component to VIEW item              |
  // ------------------------------------------|

  const ViewItem = () => {
    return(
      <>
        <div className="flex-item-right" onClick={() => { setOpenform(true)}}>{currentDataToShow()}</div>
        {name && help === true &&
          <div className="flex-item-right align-items-end">
            <IconButton
              onClick={() => { setOpenform(true)}}
              className="icon-button-secondary"
              size="20px"
              char="L"
              text="Muokkaa"
            />
          </div>}
      </>
    )
  }

  // ----------------------------|
  //       THE RETURN            |
  // ----------------------------|

  return(
    <div className="card-list even-odd hover-pointer">
      <div className="flex-container-info">{title}</div>

      { openForm === false
        ? <ViewItem/>
        :  <>
          <div className="flex-item-right">
            { (name !== 'category' && name !== 'mainCategory' && name !== 'published')  ?
              <input
                placeholder={data}
                value = {newData}
                onChange={(e) => {setNewData(e.target.value)}}
              />
              : name === 'category' ?
                <select onChange={(e) => { setNewCategory(e.target.value )}}>
                  <option value={tag.category + ',' + tag.categoryId}>{tag.category} </option>
                  {categories.map( cat =>
                    <option key={cat.id} value={ [cat.name, cat.id ]} >
                      {cat.name}
                    </option>
                  )}
                </select>
                : name === 'mainCategory' ?
                  <select onChange={(e) => {setNewData(e.target.value)}}>
                    <option value = {data}>{currentDataToShow()}</option>
                    <option value ='instructions'>Ohjeistukset</option>
                    <option value ='check'>Tutkimukset</option>
                    <option value ='notes'>Asiakastapaaminen</option>
                    <option value ='dataPoints'>Seuranta</option>
                    <option value ='other'>Muu</option>
                  </select>
                  :
                  <select onChange={(e) => {setNewData(e.target.value)}}>
                    <option value = {data}>{currentDataToShow()}</option>
                    <option value ='false'>Ei</option>
                    <option value ='true'>Kyllä</option>
                  </select>
            }
          </div>
          {name &&
          <div className="buttons-row-editbox">

            <IconButton
              onClick = { handlerUpdateClient }
              className="icon-button-main"
              size="20px"
              char="u"
              text="OK"
            />


            <IconButton
              type="button"
              onClick={() => { setOpenform(false)} }
              className="icon-button-gray"
              size="20px"
              char="X"
              text="Peru"
            />

          </div>
          }
        </>
      }
    </div>
  )
}
export default DataInput