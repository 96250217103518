import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../UI/Button/Button'
// import { setNotification } from '../../../UI/Notification/notificationReducer'
import { newTagCategory } from '../../Tags/reducers/tagCategoryReducer'

const BodyNewItem = ({ setBodyContainer }) => {

  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const tagCategories = useSelector(state => state.tagCategories)
  const companyId = useSelector(state => state.user.company)
  const [name, setName] = useState('')
  const [type, setType] = useState('')


  // ---------------- new item handler -----------------
  const handleAddNewItem = (event) => {
    event.preventDefault()
    const body = {
      name: name,
      sort: type,
      company: companyId
    }
    dispatch(newTagCategory(token, body, tagCategories))
    setBodyContainer('')
  }

  return(
    <>
      <form onSubmit={handleAddNewItem} className="flex-column-container" style={{ marginTop: 0, paddingTop: 0 }}>

        {/* Name */}
        <input
          className="passed"
          placeholder="Kategorian nimi"
          value = {name}
          onChange={(e) => {setName(e.target.value)}}
        />
        {/* category */}
        <input
          className="passed"
          placeholder="Kategorian tyyppi"
          value = {type}
          onChange={(e) => {setType(e.target.value)}}
        />

        {/* confirmation, and cancel Buttons */}
        <div className="flex-row-container">
          <Button
            className = "buttonGreen"
            label = 'Tallenna'
            type = 'buttonGreen'
            icon = 'update'
          />

          <div onClick={ () => { setBodyContainer('') }}>
            <Button
              type = "buttonNoEnter"
              label='Peruuta'
              icon='undo'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default BodyNewItem