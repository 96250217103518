import React from 'react'
import './Quotes.css'
import LinkInPost from '../LinkInPost/LinkInPost'
//import Toggler from '../../../../../../components/UI/Toggler/Toggler'

const Quotes = ({ quotes }) => {

  const createLinks = (links) => {
    return links.map(url =>
      <LinkInPost
        url={url}
        key={Math.random() * 9999999}
      />
    )
  }

  const quotesReducer = (html, quote) => {
    return(
      <>

        <div className="quote">
          <div className="vertical-line"></div>
          <div className="quote-content">
            {/* INSERT ANOTHER QUOTE HERE */}
            {html}
            <div className="quote-header">
              <h6 className = "quote-header" >{quote.name} kirjoitti:</h6>
            </div>
            {quote.links.length > 0 && createLinks(quote.links)}
            {quote.body}
          </div>
        </div>

        <div style={{ marginBottom: '1em' }}/>
      </>
    )
  }

  return(
    quotes.reduce(quotesReducer, '')
  )
}

export default Quotes