import React from 'react'
import { useSelector } from 'react-redux'
import './UserListItem.css'
import dayjs from 'dayjs'
import Button from '../../../../UI/Button/Button'
import { useHistory } from 'react-router-dom'
import langs from '../../../../../langs'
import UiCard from '../../../../UI/UiCard/UiCard'

const UserListItem = ({ username,
  firstname,      lastname,     role,      id,       active,       createdDate,
  expiryDate,     isStudent,    company
}) => {

  const trans = langs.lang
  const lN = useSelector(state => state.user.language)
  let compaName = useSelector(state => state.companies).filter(c => c.id === company)[0]
  if (compaName) {compaName = compaName.companyName} else {compaName = 'error reading DB' }
  const history = useHistory()

  const onClickEdit = (event) => {
    event.preventDefault()
    history.push(`/admin/user/${id}`)
  }

  return(
    <UiCard>
      <div className = "giftcard-body-container">
        <div className="user-list-top-row">
          <div className="user-list-item-name">
            <h4>
              { firstname + ' ' +  lastname} | { username } | <span>{ role }</span> | {compaName}
            </h4>
          </div>
        </div>

        <div className="user-list-bottom-row">
          <span>{trans.created[lN]}{' ' + dayjs(createdDate).format('DD.MM.YY')}</span><br/>
          <span>{ expiryDate ? trans.expires[lN] + ': ' +  dayjs(expiryDate).format('DD.MM.YY') : trans.doesNotExpire[lN]}</span><br/>
          <span style ={{ color: active ? 'green' : 'red' }}> {active ? trans.active[lN] : trans.inactive[lN]}</span><br/>
          <span style ={{ color: isStudent ? 'green' : 'red' }}>{isStudent ? trans.student[lN] : trans.notStudent[lN]}</span>
          <div>
            <Button
              id={`edit-user-button-${username}`}
              type="buttonGreen"
              onClick={onClickEdit}
              label={trans.edit[lN]}
              icon ="edit"
            />
          </div>
        </div>
      </div>
    </UiCard>
  )
}

export default UserListItem