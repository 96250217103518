import React from 'react'
import './MiniLoader.css'

const Loader = () => {
  return(
    <div className="mini-loader">
      <div className="mini-lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader