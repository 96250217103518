import { getAllToDos, createNewToDo, updateToDoState } from '../services/toDos'

const toDosReducer = (state = [], action) => {
  switch(action.type){
  case 'SET_TODOS':
    return action.data

  default: return state
  }
}

export const setToDos = () => {
  return async dispatch => {
    const toDos = await getAllToDos()
    dispatch({
      type: 'SET_TODOS',
      data: toDos
    })
  }
}

export const newToDo = (newToDo) => {
  return async dispatch => {
    await createNewToDo(newToDo)
    const toDos = await getAllToDos()
    dispatch({
      type: 'SET_TODOS',
      data: toDos
    })
  }
}

export const changeToDoState = (id) => {
  return async dispatch => {
    await updateToDoState(id)
    const toDos = await getAllToDos()
    dispatch({
      type: 'SET_TODOS',
      data: toDos
    })
  }
}

export default toDosReducer