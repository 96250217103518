import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import IconButton from '../../../../../UI/IconButton/IconButton'
import { setNotification } from '../../../../../UI/Notification/notificationReducer'
import Text from '../../../../../UI/Text/Text'
import TextArea from '../../../../../UI/TextArea/TextArea'
import { removeTagContent, updateTag, updateTagContent } from '../../../reducers/tagsReducer'

// --------------- Line -----------------------------------------------//
const Line = ({ line, button, setNewItem, index, editItem, setEditItem, tag }) => {
  const dispatch = useDispatch()

  // selectors
  const user = useSelector(state => state.user)
  const tags = useSelector(state => state.tags)

  // states
  const [newLineData, setNewLineData] = useState(line.data)

  // ---------- HANDLE UPDATE ----------------------------
  const handleUpdate = () => {

    function videSrc(src){
      // split original embed data to array
      const array = src.split('"')

      // filter theArray (if includes https -word) and pick one and only item from array
      let theSrc = array.filter( item =>  item.includes('https') === true)[0]

      // if it is YOUTUBE LINK it needs to be modify to me embedded link:
      if(theSrc.includes('youtu.be')) {theSrc = theSrc.replace('youtu.be','www.youtube.com/embed')}

      // if it is VIMEO LINK we are doing following tricks
      if(theSrc.includes('https://vimeo.com/')) {theSrc = theSrc.replace('https://vimeo.com/','https://player.vimeo.com/video/')}

      return theSrc
    }

    let dataToUpdate = newLineData

    // Modify data if is Video
    if(line.sort === 'video'){dataToUpdate = videSrc(dataToUpdate)}

    const body = {
      sort: line.sort,
      data: dataToUpdate
    }
    // dispatch new data to DB
    dispatch(updateTagContent(user.token, body, tags, tag.id, line.id)) // token, body, tags, tagId, contentId)

    // Reset states
    setNewLineData('')
    setEditItem({ id:0 })
    setNewItem(false)

  }

  // ----- movin item in content array, and updating it to server --------
  const moveContent = (modContent, index, moveAmount) => {


    // let set boundaries for moving item
    let newIndex = index + moveAmount

    // moving item to new place
    while (index < 0) {
      index += modContent.length
    }
    while (newIndex < 0) {
      newIndex += modContent.length
    }
    if (newIndex >= modContent.length) {
      var k = newIndex - modContent.length + 1
      while (k--) {
        modContent.push(undefined)
      }
    }
    modContent.splice(newIndex, 0, modContent.splice(index, 1)[0])

    // updating tag to db
    let updatedTag = tag
    updatedTag.content = modContent
    dispatch(updateTag(user.token, updatedTag, tag.id))

  }
  // --------- DELETE HANDLE ---------------------------
  const handleDelete = () => {

    dispatch(setNotification({
      notification: 'Valittu sisältö poistetaan! Toimintoa ei voi peruuttaa!', // title
      type: 'modal',
      onClickConfirm: () => {
        dispatch(removeTagContent(user.token, tags, tag.id, line.id))
        setTimeout(() => {dispatch(setNotification({ notification: 'Sisältö on nyt poistettu!' }))},100)}
    }))
  }
  return(
    <div className="container">
      { editItem !== line.id ?
        <div className="tag-item-content row hover-pointer">
          <div className = "tag-left-content" onClick={button}>
            { line.sort === 'text' && <Text data={line.data}/>}
            { line.sort === 'link' && <div onClick = {() => {window.open(line.data.split(',')[1], 'Tag-popup', 'width=0,height=0') }}>{<><span className="symbol">g </span>{line.data.split(',')[0]}</>}</div> }
            { line.sort === 'header' && <h5 className='tag-header'>{line.data}</h5>}
            { line.sort === 'video' && line.data !== ''
              ? <div className = "video-container">
                <iframe src={line.data} title="Video" allowFullScreen className="tag-video">{line.data}</iframe>
                <section className = "edit-video-button symbol::first-letter">L Muokkaa videon lähdeosoitetta</section>
              </div>
              : line.sort === 'video' &&
                <p>Lisää videon embed koodi</p>}
          </div>
          <div className="tag-arrow-buttons">
            {  index > 0   &&
                <IconButton
                  onClick={() => moveContent(tag.content, index, -1 )}
                  className="icon-button-gray"
                  char="p"
                  size="30px"
                  text="ylös"
                />}

            {  index + 1 !== tag.content.length &&
                <IconButton
                  onClick={() => moveContent(tag.content, index, 1 )}
                  className="icon-button-gray"
                  size="30px"
                  char="o"
                  text="Alas"
                />}
          </div>
        </div>
        :
        <div className="tag-edit-facus">
          <div className = "tag-edit-left-content">
            { line.sort === 'text' &&
                <TextArea
                  placeholder='Lisää tekstiä'
                  className = 'tag-textarea'
                  trickers ={ [newLineData, editItem] }
                  value = {newLineData}
                  onChange={(e) => setNewLineData(e.target.value)}/>}
            { line.sort === 'link' &&
                <TextArea
                  placeholder='Linkin otsikko, https://...'
                  className = 'tag-textarea'
                  trickers ={ [newLineData, editItem] }
                  value = {newLineData}
                  onChange={(e) => setNewLineData(e.target.value)}/>}
            { line.sort === 'header' &&
                  <TextArea
                    autoFocus
                    placeholder='Lisää uusi otsikko'
                    value = {newLineData}
                    className = 'tag-textarea-header'
                    onChange={(e) => setNewLineData(e.target.value)}/>}
            { line.sort === 'video' &&
                  <input
                    autoFocus
                    placeholder='Lisää tähän Youtube tai Vimeo embed osoite tai linkki'
                    value = {newLineData}
                    onChange={(e) => setNewLineData(e.target.value)}/>}
          </div>
          <div className="tag-edit-right-buttons">

            <IconButton
              onClick={handleUpdate}
              className="icon-button-white"
              char="u"
              size="25px"
            />
            <IconButton
              onClick={handleDelete}
              className="icon-button-white"
              char="M"
              size="25px"
            />
            <IconButton
              onClick={() => setEditItem({ id: 0 })}
              className="icon-button-white"
              size="25px"
              char="X"
            />
          </div>
        </div>
      }
    </div>
  )}

export default Line