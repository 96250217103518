import React from 'react'

const DateOption =
   ({ amount, offset }) => {
     return [...Array(Number(amount))
       .keys()]
       .map( x => (x+Number(offset))>9
         ? <option
           key = {x+Number(offset)}
           value={x+Number(offset)}>
           {x+Number(offset)}
         </option>
         : <option
           key = {(0)
             .toString()
              +((x+Number(offset))
                .toString())}
           value={(0)
             .toString()
              +((x+Number(offset))
                .toString())}>
           {(0).toString()+((x+Number(offset))
             .toString())}
         </option>)}

export default DateOption