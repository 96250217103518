
import React from 'react'
import { useDispatch } from 'react-redux'
import BodyInTags from '../../../../../components/Private/Tags/components/BodyComponents/BodyInTags/BodyInTags'
import BodyHelpHeader from '../../../../../components/UI/HelpText/HelpText'
import DayAndTime from '../../../../../functions/DayAndTime'
import { updateCustomerCheckTags } from '../../../reducers/rdc-oneCustomer'

const Body_Researches = ({ customer }) => {
  const dispatch = useDispatch()
  const saveCheckTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateCustomerCheckTags(token, newTags, customer.id, appointmentId, tags ))
  }

  const Researches = () => {

    return(
      <>
        {customer && customer.appointments !== undefined &&
        customer.appointments.map(app =>

          <div className="even-odd" key={app.id}>
            <div  className="card-list" key={app.id}>
              <div className="flex-container-info"  ><b><DayAndTime date = {app.date}/></b></div>
              <div className="flex-item-right">       {app.check}</div>
            </div>
            <BodyInTags
              items = {app.checkTags}
              itemId = {app.id}
              selectedID={app.id}
              saveTags = {saveCheckTags}
              linkText = 'tagit'
              editTitle = '-'
              sortBy = 'check'
            />
          </div>
        )}
      </>
    )

  }

  return(
    <>
      <h4 className="body-header">Tutkimukset</h4>
      <BodyHelpHeader
        text="Täällä voit tarkastella kirjauksia harjoitteista"
      />
      <div className="customers-body-container">
        <Researches/>
        {Researches().props.children && Researches().props.children.length === 0 &&
      <div className="mp-p-20">
        <h6>Asiakkaalla ei ole vielä kirjattuja tutkimuksia</h6>
        <p className="mp-center">Tutkimukset tehdään kirjauksissa. Tämä mesta on vain oikopolku tsekata pelkät tutkimukset.</p>
      </div>}
      </div>
    </>
  )
}
export default Body_Researches
