// -----------------------------------------------------------------------|
//                       SHOW AND EDIT BASIC DATA OF TAG                  |
//                          depndend of bodyComponents                    |
// -----------------------------------------------------------------------|

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// import Button from '../../../../../components/UI/Button/Button'
import { setNotification } from '../../../../../UI/Notification/notificationReducer'
import { removeTag } from '../../../reducers/tagsReducer'
import HelpText from '../../../../../UI/HelpText/HelpText'
import ButtonTxtIcon from '../../../../../UI/ButtonTxtIcon/ButtonTxtIcon'
import DataInput from './DataInput'

const BodyBasicData = ({ tag, setBodyContainer, setSelectedID }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const tags = useSelector(state => state.tags)
  const token = user.token


  const handleDeleteCustomer = () => {
    dispatch(setNotification({
      notification: 'Tagi poistetaan! Toimintoa ei voi peruuttaa!', // title
      type: 'modal',
      onClickConfirm: () => {
        setBodyContainer(null)
        setSelectedID(null)
        dispatch(removeTag(token, tag.id, tags))
        setTimeout(() => {dispatch(setNotification({ notification: 'Tagi on nyt poistettu!' }))},500)
        // setFilterdCustomers(tags.filter(cust => cust.id !== tag.id))
      }
    }
    ))

  }

  return(
    <>
      <h4 className="body-header">Muokkaa Tagin tietoja</h4>
      <HelpText
        text='Voit muokasta tagin nimeä, kategoriaa ja avainsanoja. Erota avainsanat pilkuilla.'
      />
      { tag &&
        <div>
          <div className="customers-body-container" style={{ marginBottom: '30px' }}>

            <DataInput title = "Nimi"            sort="input"   tag={tag}     data = {tag.name}            name = "name"/>
            <DataInput title = "Pääkategoria"    sort="select"  tag={tag}     data = {tag.mainCategory}    name = "mainCategory"/>
            <DataInput title = "kategoria"       sort="select"  tag={tag}     data = {tag.category}        name = "category"/>
            <DataInput title = "avainsanat"      sort="input"   tag={tag}     data = {tag.keywords}        name = "keywords"/>
            <DataInput title = "Onko julkaistu"  sort="select"  tag={tag}     data = {tag.published}       name = "published"/>
            <div className='buttons-row'>
              <ButtonTxtIcon
                label = 'Poista tagi tietokannasta'
                icon = 'M'
                className="buttonRed"
                onClick={handleDeleteCustomer}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default BodyBasicData
