import axios from 'axios'
import { configurate } from '../../../../services/configurate'

// tag and tagCategory are combined  <- 2 baseUrls
// maybe should be separeted in future

const baseUrl = '/private/api/product'

// get all products filtered by company
export const getProducts = async (token, companyId) => {
  try{
    const config = configurate(token)
    const items = await axios.get(baseUrl + '/' + companyId, config)
    return items.data
  } catch(err){
    console.log('error at getProducts() : ', err)
  }
}

// post product
export const postProduct = async (token, body) => {
  try{
    const config = configurate(token)
    const item = await axios.post(baseUrl, body, config)
    return item.data
  } catch(err){
    console.log('error at PostProduct() : ', err)
  }
}

// put product
export const putProduct = async (token, body, productId) => {
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrl + '/' + productId, body, config)
    return item.data
  } catch(err){
    console.log('error at PutProduct() : ', err)
  }
}

// delete product
export const deleteProduct = async (token, productId) => {
  try{
    const config = configurate(token)
    const item = await axios.delete(baseUrl + '/' + productId, config)
    // send the whole item for reducer here.
    return item
  } catch(err){
    console.log('error at DeleteProduct() : ', err)
  }
}