import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneCustomer } from '../../../reducers/rdc-oneCustomer'
import Body_History from './Body_History/Body_History'
import Body_BasicData from './Body_BasicData'
import Body_Exercises from './Body_Exercises'
import Body_NewAppointment from './Body_NewAppointment'
import Body_Researches from './Body_Researches'
import Body_DataPoints from './Body_Datapoints/Body_DataPoints'
// import filters from '../../AppHeader/filters'

const BodyComponents = ({ customers, setCustomers, setFilterdCustomers, bodyContainer, selectedID, setSelectedID, setBodyContainer }) => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const customer =  useSelector(state => state.users && state.oneCustomer)

  // getting customers full data only, if staff is clicking to some customercomponent
  useEffect(() => {
    selectedID && dispatch(getOneCustomer( user.token, selectedID))
  }, [selectedID, bodyContainer])

  return(
    <>
      <div className="customer-body-container">
        {bodyContainer === '1' &&
        <Body_History customer = {customer} token = {user.token} selectedID = {selectedID} setCustomers = {setCustomers} customers = {customers} setBodyContainer={setBodyContainer}/>}
        {bodyContainer === '2' &&
        <Body_BasicData setBodyContainer = {setBodyContainer} selectedID = {selectedID} customer = {customer} setFilterdCustomers = {setFilterdCustomers} customers = {customers} token = {user.token} setSelectedID={setSelectedID}/>}
        {bodyContainer === '3' && <Body_Researches customer = {customer}/>}
        {bodyContainer === '4' && <Body_Exercises customer = {customer}/>}
        {bodyContainer === '5' && <Body_NewAppointment customer = {customer} setBodyContainer={setBodyContainer}/>}
        {bodyContainer === '6' && <Body_DataPoints customer = {customer} setBodyContainer={setBodyContainer}/>}
      </div>
    </>
  )
}
export default BodyComponents
