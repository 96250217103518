/* eslint-disable no-unexpected-multiline */
const filters = (tags, searchWord) => {
  let showCards
  if( tags && Array.isArray(tags) === true ) {
  // ------------ FILTERS --------------------------------------
    showCards = tags.filter
    (
      x => (x.name && x.name.toLowerCase().includes(searchWord.toLowerCase()))  // name
        || (x.category && x.category.toLowerCase().includes(searchWord.toLowerCase()))  // by category
        || (x.keywords && x.keywords.includes(searchWord.toLowerCase())) // by keywords
    )
  }
  return showCards
}

export default filters