import { useDispatch } from 'react-redux'

const getUsers = ({ user, initializeUsers, dispatch, useEffect }) => {
  // get users
  useDispatch
  useEffect( () => {
    user && dispatch(initializeUsers(user.token, user.company))
  },[dispatch, user])
}

export default getUsers
