import React from 'react'
import './InvitedList.css'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import langs from '../../../../../langs'
import UiCard from '../../../../UI/UiCard/UiCard'


const InvitedList = ({ invitedUsers }) => {
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  let displayInvitedUsers = invitedUsers && invitedUsers.map(u => {

    return(
      <div key = { Math.floor(Math.random() * 100000) } className="list-bg">
        <div className="list-item">
          <div className="invited-users-list-1">
            <span className="invited-users-list-email">{u.email}</span><br/>
            <span>kutsuttu: {dayjs(u.invitedDate).format('DD.MM.YYYY')}</span>
          </div>
          <div className="invited-users-list-role">
            <span>{u.role}</span>
          </div>
          <div className="invited-users-list-student">
            <span style={{ color: u.isStudent ? 'green': 'red' }}>{u.isStudent ? trans.student[lN] : trans.notStudent[lN]}</span>
          </div>
        </div>
      </div>
    )
  })
  if(invitedUsers && invitedUsers.length < 1) displayInvitedUsers = <p>Ei avoimia kutsuja</p>
  return(
    <UiCard
      headerTitle = {trans.invitationsPending[lN]}>
      <div className="giftcard-body-container">
        { displayInvitedUsers && displayInvitedUsers }
      </div>
    </UiCard>
  )
}

export default InvitedList