import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonTxtIcon from '../../../UI/ButtonTxtIcon/ButtonTxtIcon'
import HelpText from '../../../UI/HelpText/HelpText'
import IconButton from '../../../UI/IconButton/IconButton'
import { setNotification } from '../../../UI/Notification/notificationReducer'
import { delete_location, put_location } from '../reducers/locationsReducer'




const Item = ({ item }) => {
  const dispatch =  useDispatch()
  const help = useSelector(state => state.help.show)
  const user = useSelector(state => state.user)
  const items = useSelector(state => state.locations)

  const handleDelete =  () => {
    dispatch(setNotification({
      notification: 'Ookkonää ny iha varma?',
      type: 'modal',
      onClickConfirm: () => dispatch(delete_location(user.token, items, item.id )),
      onClickBack: () => console.log('delete cancelled')
    }))
  }

  const DataInput = ({ title, data, name }) => {

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    const handlerUpdateClient = () => {

      // close input
      setOpenform(false)
      //  bracket notation, so only modified key is updated
      const object = {
        [name]: newData }
      // token, body, locations, locationId
      dispatch(put_location(user.token, object, items, item.id  ))
    }
    // -------- delete handle -------------------





    return(
      <>
        <div className="card-list even-odd">
          <div className="flex-container-info">{title}</div>
          { openForm === false ?
            <>
              <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{data}</div>
              {name && help === true &&
          <div className="flex-item-right align-items-end">
            <IconButton
              onClick={() => { setOpenform(true)}}
              className="icon-button-secondary"
              char="L"
              text="Muokkaa"
              size="30px"
            />
          </div>}
            </>
            :
            <>
              <div className="flex-item-right">
                <input
                  className="passed"
                  placeholder={data}
                  value = {newData}
                  onChange={(e) => {setNewData(e.target.value)}}
                />
              </div>
              {name &&
          <>
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={handlerUpdateClient}
                className="icon-button-main"
                char="u"
                size="30px"
                text="OK"
              />
            </div>
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(false)}}
                className="icon-button-gray"
                char="X"
                text="Peru"
                size="30px"
              />
            </div>
          </>
              }
            </>
          }
        </div>
      </>
    )
  }

  return(
    <div>
      <HelpText
        text='
          Lisää uusi toimipaikka. Täytä alla olevat tiedot. Paikan nimi on pakollinen tieto.
        '
      />
      <DataInput title = "Paikan nimi"     data = {item.name}            name = "name"/>
      <DataInput title = "Info"            data = {item.info}            name = "info"/>
      <DataInput title = "Osoite"          data = {item.address}         name = "address"/>
      <DataInput title = "puh."            data = {item.phone}           name = "phone"/>
      <DataInput title = "email"           data = {item.email}           name = "email"/>
      <DataInput title = "Web-sivu"        data = {item.webAddress}      name = "webAddress"/>
      <ButtonTxtIcon
        label = "Poista paikka"
        className = "buttonRed mp-ml-20"
        onClick = {handleDelete}
        icon = "M"
      />
    </div>
  )
}

export default Item
