import React from 'react'
import ThreadListItem from './ThreadListItem/ThreadListItem'
import { useSelector } from 'react-redux'


const ThreadList = () => {
  const threads = useSelector(state => state.threads.filteredThreads)
  const threadsToDisplay = threads && threads.map(t => <ThreadListItem thread={t} key={t.id} />)

  return(
    <>
      <div style={{ marginBottom: '100px', marginTop: '20px' }}>
        { threadsToDisplay && threadsToDisplay}
      </div>
    </>
  )
}

export default ThreadList