import React, { useState } from 'react'
import'./styles.css'
import Button from '../../components/UI/Button/Button'
import Title from '../../components/UI/Title/Title'

function StaffRaports() {

  // time month ago
  var d = new Date()


  // Set it to one month ago
  d.setMonth(d.getMonth() - 1)
  const monthAgo = d.getFullYear() + '-' + (Number(d.getMonth()) + 1)
  const[jsonValue, setJsonValue] = useState([])
  const[file, setFile] = useState()
  const[title, setTitle] = useState(monthAgo)
  const[showInfo, setShowInfo] = useState(false)
  const[provisio, setProvisio] = useState(25)


  var reg = /^\d*\.?\d+$/

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals)
  }

  const findUniqueNames = (arr) => {
    let uniqueNames = []
    arr.forEach( (item) => {
      if(item.Palveluntarjoaja || item.Työntekijä){
        let name = item.Palveluntarjoaja ? item.Palveluntarjoaja : item.Työntekijä
        if(uniqueNames.length > 0 && uniqueNames.filter(uName => uName === name ).length > 0){
          console.log('')}
        else {
          if(uniqueNames.length === 0)
            uniqueNames = [name]
          else{
            uniqueNames.push(name)
          }
        }
      }

      // uniqueNames = uniqueNames + item.Palveluntarjoaja
    } )
    return uniqueNames
  }


  function csvToArr(stringVal, splitter) {
    //change long key value to simpler
    const [keys, ...rest] = stringVal.split('"').join('')
      .split('Onko sinulla lahjakortti (nro), muut lisätiedot').join('Tiedot')
      .split('Appointment date').join('Date')
      .split('Lisätietoja hierojalle').join('Tiedot')
      .split(' (Kuka tahansa)').join('')
      .trim()
      .split('\n')
      .map((item) => item.split(splitter))

    const formedArr = rest.map((item) => {
      const object = {}
      keys.forEach((key, index) => (object[key] = item.at(index)))
      return object
    })
    return formedArr
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const reader = new FileReader()

    reader.onload = function (e) {
      const csvArray = csvToArr(e.target.result, ';')

      // setJsonValue(JSON.stringify(csvArray, null, 4))
      setJsonValue(csvArray)
    }
    reader.readAsText(file)
  }
  const cvsHeader = { ID: 'ID',  Date: 'Aika', Palveluntarjoaja: 'Palveluntarjoaja',  palvelu: 'Palvelu', Tiedot: 'Tiedot', Hinta: 'Hinta', Provisio: 'Provisio', Virike: 'Virike', Yht: 'Yht.' }


  function filteredJson(provisionOfWorker, fixedPrice, worker) {
    let totalPrice = 0
    console.log('fixedPrice-r: ', fixedPrice)
    // filter worker if it is defined
    const filterByWorker = (item) => {
      if(!worker){return true}
      else if(item.Palveluntarjoaja === worker) return true
      else return false
    }
    const value = jsonValue.filter(item => filterByWorker(item)).map(function(x){
      let provision = Number(provisionOfWorker)/100
      let hinta = 0
      let virike = 1



      if (x.Palvelu)
      {
        if( x.Palvelu === 'Hieronta 30 min'){ hinta = 35}
        if( x.Palvelu === 'Hieronta 45 min'){ hinta = 42}
        if( x.Palvelu === 'Hieronta 60 min'){ hinta = 52}
        if( x.Palvelu === 'Hieronta 75 min'){ hinta = 62}
        if( x.Palvelu === 'Hieronta 90 min'){ hinta = 72}
        if( x.Palvelu === 'Hieronta 120 min'){ hinta = 92}
        if( x.Palvelu === 'Hieronta 50 min ( KEMPPI )'){ hinta = 38}
        if( x.Palvelu === 'Osteopatia 60min (opiskelija)'){ hinta = 65}
        if( x.Palvelu === 'Fysioterapia - 60 min'){ hinta = 70}
        if( x.Palvelu === 'Fysioterapia - 50 min.'){ hinta = 60}
        if( x.Palvelu === 'Fysioterapia - 30 min.'){ hinta = 50}
        if( x.Palvelu === 'Kemppi hieronta'){ hinta = 38}
        if( x.Palvelu === 'Hieronta 60 min (kesäkampanja)'){ hinta = 45}

        let tiedot = x.Tiedot ? x.Tiedot.toLowerCase() : ''

        if(tiedot.includes('epassi') || tiedot.includes('smart') || tiedot.includes('edenr') || tiedot.includes('fysioup') || tiedot.includes('fupin') || tiedot.includes('fysio')) {
          virike = 0.95
          provision = round(-(1 - provision), 2)
        }
        else if(tiedot.includes('sarjakort') || tiedot.includes('000') || reg.test(tiedot) || tiedot.includes('202' || tiedot.includes('sk\t')) || tiedot === 'sk' || tiedot === 'lk' || tiedot.includes('lahja') || tiedot.includes('levisan') ){
          provision = round(-(1 - provision),2)
        }
        else if(tiedot.includes('laukkanen')){
          provision = round(provision - 0.5, 2)}

        if(tiedot.includes('reipas')){ hinta = hinta - 8}
        if(tiedot.includes('test')){ hinta = 0}
        if(tiedot.includes('ei saapunut')){ hinta = 0}
        x.Hinta = hinta
        totalPrice = totalPrice + round((Number(hinta) * Number(provision) * Number(virike)),2)
        x.Yht = round(Number(hinta) * Number(provision) * Number(virike),2).toString().split('.').join(',')
        if(provision){provision = provision.toString().split('.').join(',')}
        virike = virike.toString().split('.').join(',')

        if(tiedot.includes(',')){x.Tiedot = x.Tiedot.split(',').join('.')}
        return {
          ID: x.ID,
          Date: x.Date,
          Palveluntarjoaja: x.Palveluntarjoaja ? x.Palveluntarjoaja : x.Työntekijä,
          palvelu: x.Palvelu,
          Tiedot: tiedot,
          Hinta: hinta,
          Provisio: provision,
          Virike: virike,
          Yht: x.Yht
        }
      }

      else return cvsHeader


    })
    totalPrice += (Number(fixedPrice ? fixedPrice : 0))
    return [... value, {
      ID: 'Kiinteä hinta:',
      Date: '',
      Palveluntarjoaja: '',
      palvelu: '',
      Tiedot: '',
      Hinta: '',
      Provisio: '',
      Virike: '',
      Yht: fixedPrice ? fixedPrice : 0 },
    {
      ID: 'Yhteensä:',
      Date: '',
      Palveluntarjoaja: '',
      palvelu: '',
      Tiedot: '',
      Hinta: '',
      Provisio: '',
      Virike: '',
      Yht: round(totalPrice, 2) }
    ]

  }

  // convert json to CSV
  function convertToCSV(objArray) {
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    var str = ''
    for (var i = 0; i < array.length; i++) {
      let line = '', comma = '', j = 0

      for (var index in array[i]) {
        // if (line !== '') line += ','
        if(j < index.length) comma = ';'
        line += array[i][index] + comma
        j ++
      }
      str += line + '\r\n'
    }
    return str
  }

  // Download file
  // https://gist.github.com/danallison/3ec9d5314788b337b682
  function downloadString(text, fileType, fileName) {
    var blob = new Blob([text], { type: fileType })

    var a = document.createElement('a')
    a.download = fileName
    a.href = URL.createObjectURL(blob)
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setTimeout(function() { URL.revokeObjectURL(a.href) }, 1500)
  }

  const uniqueWorkers = findUniqueNames(jsonValue)



  const WorkerButtons = () => {

    // for filttering row by worker or if it is the last item with Fixed Price
    let totalAmount = 0
    const buttons = uniqueWorkers.map(function(worker,i){

      const[provisioWorker, setProvisoWorker] = useState(provisio)
      const [fixedPrice, setFixedPrice] = useState(0)

      const provisionHandler = (e) => {
        setProvisoWorker(isNaN(e.target.value) ? provisioWorker : e.target.value)
      }
      let jsonWorker = [cvsHeader].concat(filteredJson(provisioWorker, fixedPrice, worker))
      let workerTotal = round(jsonWorker[jsonWorker.length -1].Yht,2)
      totalAmount = totalAmount + workerTotal

      return(
        <div key = {worker} >
          <div className = "buttons-row">
            <button className="txt-download"
              style={{ width: 300 }}

              onClick=
                {
                  () => {
                    downloadString(
                      convertToCSV(jsonWorker), 'csv', `rapsa-${worker}-${title}.csv`
                    )
                  }
                }>
              {worker}
            </button>
            <input value={provisioWorker} onChange={provisionHandler}></input>
            <div>% + </div>
            <input value={fixedPrice} onChange={ (e) => {setFixedPrice(!isNaN(e.target.value) ? e.target.value : fixedPrice)}}></input>
            <div>€</div>
            <div style={{ width:200, textAlign: 'right' }}><b>{workerTotal}€</b></div>
          </div>
          {uniqueWorkers.length - 1 === i &&
          <div>
            <div style={{ textAlign: 'right' }}><b>Yhteensä:{round(totalAmount,2)}€</b></div>
          </div>
          }
        </div>

      )
    }

    )
    return buttons
  }

  return (
    <div className="container">
      <Title text="Fysio Up – Rapsatin" icon="V" />
      { showInfo && <div>
        <video
          style={{ width: '90%' }}
          controls = { true }
          src='img/ohjeet/rapsat-ohje-v1.mp4'/>
        <ol className="rapsat-ol">
          <li>Lataa koneelle CSV-tiedosto Booklystä. Se löytyy Appointments välilehden alta.</li>
          <li>Valitse Created any time, </li>
          <li>Valitse haluamasi ajankohta. Klikkaa Export to CSV...</li>
          <li>Anna olla kaikki täpät valinnassa</li>
          <li>Vaihda Delimiter puolipisteeksi / Semicolon</li>
          <li>Klikkaa Export to CSV</li>
          <li>Täytä alle vuosi ja kuukausi, jolle haluat nimetä tiedostot</li>
          <li>Lataa teidosto ja klikkaa Valitse tiedosto ja Lähetä</li>
          <li>Ohjelma muodostaa jokaisesta työntekijästä oman raportin CVS muodossa</li>
        </ol>
      </div>}
      <h2 style={{ textAlign: 'center', fontSize: '20px' }}>Huom! Ladattavaan <i>Rapsaan</i> ei ole asetettu laskukaavoja.</h2>
      <p className="mp-mt-0 mp-mb-20">Jos sitä pitää editoida, on laskukaavat asetettava itse. Tarkista aina raportti, että siinä on kaikki oikein. Jos esim. osa maksusta on tehty käteisellä ja osa ePassilla, ei scripti osaa ottaa sitä huomioon.</p>
      <form>
        <label>Mille kuukaudelle raportti tehdään. ( vuosi-kk ) </label>
        <input value={title} onChange = {(e) => {setTitle(e.target.value)}}placeholder="Vuosi-kk"></input>
        <label>Provisio %</label>
        <input value={provisio} onChange={(e) => {setProvisio(isNaN(e.target.value) ? provisio : e.target.value)}}/>
        <input type="file" onChange={(e) => {setFile(e.target.files[0])}} id="csvInput" accept=".csv" />
        <div className="buttons-row">
          <Button className="buttonGreen" type="submit" label="Lähetä" onClick={submitHandler}/>
          <Button
            className ="buttonGreen"
            label={showInfo ? 'Piilota ohjeet' : 'Näytä ohjeet'}
            onClick={(e) => {
              e.preventDefault()
              setShowInfo(showInfo ? false : true)
            }}/>
        </div>


      </form>
      <div className="flex-row flex-wrap">
        <div className="buttons-row mp-mt-30">
          <div style={{ width: 300 }}>Työntekijä</div>
          <div style={{ width: '100%' }}>Provisio (%)</div>
          <div style={{ width: '100%' }}>Kiinteä vuokra</div>
          <div style={{ width: 200 }}>Yhteensä</div>
        </div>
        {/* WORKERS BUTTONS */}
        {
          jsonValue.length > 0 && uniqueWorkers && <WorkerButtons/>
        }
      </div>

    </div>
  )
}

export default StaffRaports
