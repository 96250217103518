import React from 'react'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'


const DatapointLoopContent = ({ datapoint, showData, setShowData, setEditDataPoint, editdataPointHandler }) => {
  return(
    <div className="customers-historylist-headerrow card-list-onlyRows even-odd">

      <div className="flex-container-info hover-pointer"
        onClick={(() => {showData === null ? setShowData(datapoint.id) : setShowData(null)})}>
        <p className="UI-card-link-title">{datapoint.name}</p>
      </div>

      <div className="flex-item-right">
        {datapoint.unit}
      </div>

      <div className="flex-item-right align-items-end">
        <IconButton
          className="icon-button-white"
          char="L"
          size="20px"
          text="muokkaa"
          onClick={() => {
            setEditDataPoint(datapoint.id)
            editdataPointHandler()
          } }/>

      </div>
    </div>
  )

}

export default DatapointLoopContent