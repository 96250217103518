import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../UI/Button/Button'
import { newTag } from '../../../reducers/tagsReducer'
import SelectCategory from './SelectCategory'

const BodyNewTag = ({ setBodyContainer }) => {

  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const tags = useSelector(state => state.tags)
  const categories = useSelector(state => state.tagCategories)
  const companyId = useSelector(state => state.user.company)
  const [name, setName] = useState('')
  const [category, setCategory] = useState(categories.length > 0 ? [categories[0].name, categories[0].id] : [])
  const [mainCategory, setMainCategory] = useState('')
  const [keywords, setKeywords] = useState('')

  const handleAddNewTag = (event) => {
    event.preventDefault()
    const body = {
      name: name,
      category: category.split(',')[0], // in selectCategory is set 2 values: name, and id
      categoryId: category.split(',')[1],
      keywords: keywords,
      company: companyId,
      mainCategory: mainCategory,
      published: false
    }
    dispatch(newTag(token, body, tags))
    setBodyContainer('')
  }



  return(
    categories.length > 0 ?
      <>
        <form onSubmit={handleAddNewTag} className="flex-column-container" style={{ marginTop: 0, paddingTop: 0 }}>

          {/* firstName */}
          <input
            className="passed"
            placeholder="Nimi *"
            value = {name}
            onChange={(e) => {setName(e.target.value)}}
          />
          {/* Main Category */}
          <select onChange={(e) => {setMainCategory(e.target.value)}}>
            <option value ='other'> Valitse Yläkategoria</option>
            <option value ='instructions'>Ohjeistukset</option>
            <option value ='check'>Tutkimukset</option>
            <option value ='notes'>Asiakastapaaminen</option>
            <option value ='dataPoints'>Seuranta</option>
            <option value ='other'>Muu</option>
          </select>

          {/* category */}
          <SelectCategory setCategory={setCategory} categories={categories}/>

          {/* Keywords */}
          <input
            className="passed"
            placeholder="Avainsanat, erota sanat pilkulla"
            value = {keywords}
            onChange={(e) => {setKeywords(e.target.value)}}
          />
          {/* confirmation, and cancel Buttons */}
          <div className="flex-row-container">
            <Button
              className = "buttonGreen"
              label = 'Tallenna'
              type = 'buttonGreen'
              icon = 'update'
            />

            <div onClick={ () => { setBodyContainer('') }}>
              <Button
                type = "buttonNoEnter"
                label='Peruuta'
                icon='undo'
              />
            </div>
          </div>
        </form>
      </>
      :
      <h5>Ei yhtään ketegoriaa vielä luotu - Luo ensin kategoriat</h5>
  )
}

export default BodyNewTag