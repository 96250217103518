import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AppHeader from './AppHeader'
import Item from './BodyComponents/Item'



const TagCategories = () => {

  const items = useSelector(state => state.users && state.tagCategories)
  const [selectedID,     setSelectedID]            = useState(null)
  const [bodyContainer,  setBodyContainer]         = useState(null)
  const [filteredItems,   setFilterdItems]           = useState(items)

  // restore filters if new tag is created or changed
  useEffect(() => {
    setFilterdItems(items)
  },[items])

  const [searchWord, setSearchWord] = useState('')
  return(
    <div className="container">
      <AppHeader
        selectedID={selectedID}
        filteredItems = {filteredItems}
        setFilterdItems = {setFilterdItems}
        bodyContainer = {bodyContainer}
        setBodyContainer = {setBodyContainer}
        searchWord = {searchWord}
        setSearchWord = {setSearchWord}
      />
      <div className = "flex-column-reverse mp-mt-20">
        {filteredItems && filteredItems.slice(0,50).map(item => {
          return(
            <div key={item.id}>
              <Item
                key={item.key}
                item={item}
                selectedID = {selectedID}
                setSelectedID = {setSelectedID}
                bodyContainer = {bodyContainer}
                setBodyContainer = {setBodyContainer}
                searchWord = {searchWord}
                setSearchWord = {setSearchWord}

              />
            </div>
          )
        })}
      </div>

    </div>

  )
}

export default TagCategories