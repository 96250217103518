import React from 'react'
import Loader from '../../UI/Loader/Loader'

const AccountInactive = () => {

  return(
    <>
      <div className="container">
        <Loader title = 'Oops!' text = 'Tilisi on edeleen olemasa, mutta se ei ole aktiivinen. Jos haluat aktivoida tilin, ota yhteyttä tukeen.' />
      </div>
    </>
  )
}

export default AccountInactive