import React from  'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { logout } from '../../../../reducers/userReducer'
import './NewCustomer.css'

const Logout = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = () => {
    history.push('/login')
    dispatch(logout())
  }

  return(
    <Link to="/">
      <div
        onClick={logOut}
        className="hover-pointer menu-link"
        id="customer-sign-out-button"
        data-testid="customer-sign-out-button"
      > <div>
        Kirjaudu ulos
        </div>
      </div>
    </Link>
  )
}

export default Logout