import React from 'react'
import filters from './filters'
import UiCard from '../../UI/UiCard/UiCard'
import { useSelector } from 'react-redux'
import BodyNewItem from './BodyComponents/BodyNewItem'
import Title from '../../UI/Title/Title'
import IconButton from '../../UI/IconButton/IconButton'

const AppHeader = ({ searchWord, setSearchWord, selectedID, setFilterdItems, setBodyContainer, bodyContainer }) => {


  // -------- handler to apply filters from search form ----------------

  const handleFilter = (event) => {
    setSearchWord(event.target.value.toString())
    setFilterdItems(filters(items, event.target.value.toString()))
  }
  const help = useSelector(state => state.help && state.help.show)
  const items = useSelector(state => state.tagCategories)
  const theme = useSelector(state => state.theme)

  // ----------------------- RETURN PAGE ------------------------------
  return(
    <div>
      {
        selectedID === null && bodyContainer !== '0'
          &&
          <>
            <div className="add-item-search-top-bar">
              {/* ------------- BUTTON - add new Category  ----------------- */}


              <IconButton
                char="j"
                className="icon-button-main"
                onClick={() => {setBodyContainer('0')}}
              />

              {/* -------------------- SEARCH INPUT  ------------------ */}

              <input className={'input-cardholder-find theme' + theme } value= {searchWord} onChange={handleFilter} placeholder='etsi tagin kategoriaa'/>

              { searchWord !== '' &&
                <IconButton
                  char="x"
                  className="icon-button-gray"
                  size="30px"
                  onClick={() => {
                    setSearchWord('')
                    setFilterdItems(items, '')
                  }}
                />}




            </div>
            <Title text="Kategoriat" icon="C"/>
            {help===true && <p>Kategoriat ovat tarkoitettu jäsentämään tageja.
              Jokainen tagi on asetettu tietyn kategorian alle. Esim. <b>harjoite</b> voisi olla kategoria.
              Harjoite kategorian alle voidaan sijoittaa esim. vatsalihasliikkeet, punnerrukset jne.
            </p> }
          </>
      }

      {/* -------------FORM to add new customer -------------------*/}
      {
        bodyContainer === '0' &&
          <div>
            <div/>
            <UiCard
              headerTitle = "Lisää uusi Kategoria"
              headerInfo = ""
            >
              <BodyNewItem setFilterdItems = {setFilterdItems} setBodyContainer = {setBodyContainer} items = {items} setSearchWord={setSearchWord}/>
            </UiCard>
          </div>
      }
    </div>

  )
}
export default AppHeader