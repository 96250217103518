import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from './components/Card'
import './Cards.css'
import AddNewCard from './components/AddNewCard'
import filters from './components/Filters'
import timeStamp from './components/TimeStamp'
import Loader from '../../components/UI/Loader/Loader'
import UiCard from '../../components/UI/UiCard/UiCard'
import { getCards } from  './reducers/cardsReducer'
import NewCardAccount from './components/NewCardAccount'
import ToggleButton from '../../components/UI/ToggleButton/ToggleButton'
import { setFilters } from '../../reducers/filtersReducer'
// import ButtonWithIcon from '../../components/UI/ButtonWithIcon/ButtonWithIcon'
import Title from '../../components/UI/Title/Title'
import IconButton from '../../components/UI/IconButton/IconButton'
let sliceStart
let sliceStop
const now = timeStamp(new Date())

const Cards = () => {
  const dispatch = useDispatch()

  // selectors
  const cards =                   useSelector(state => state.users && state.cards)
  const user =                    useSelector(state => state.user)
  const giftCardData =            useSelector(state => state.cards && state.cards.cards_cards)
  const cardsFilter =             useSelector(state => state.filters)

  // states
  const [showCardNro,             setShowCardNro]               = useState('')
  const [howManyToShow,           setHowManyToShow]             = useState(50)
  const [downButtonContent,       setDownButtonContent]         = useState('Näytä lisää (51-100) >>')
  const [giftCardContainer,       setGiftCardContainer]         = useState(null)
  const [idSelected,              setIdSelected]                = useState('')

  useEffect(() => {
    user && dispatch(getCards( user.token, user.company))
  }, [user, dispatch])

  // -------------------- handles ------------------------------

  const handleSelectedCardNro = (event) => {
    setShowCardNro(event.target.value.toString())
  }
  const setExpiredFilterHandler =  () => {
    let state = false
    if(cardsFilter.cardsOld === false){state = true}
    dispatch(setFilters({ ...cardsFilter, cardsOld: state }))
  }

  const setEmptyFilterHandler = () => {
    let state = false
    if(cardsFilter.cardsUsed === false){state = true}
    dispatch(setFilters({ ...cardsFilter, cardsUsed: state }))
  }

  const setDeactivatedFilterHandler = () => {
    let state = false
    if(cardsFilter.cardsTrach === false){state = true}
    dispatch(setFilters({ ...cardsFilter, cardsTrach: state }))
  }
  let showCardsOrig
  if(giftCardData) showCardsOrig = filters(giftCardData, cardsFilter.cardsOld, cardsFilter.cardsUsed, showCardNro, cardsFilter.cardsTrach, now)
  let showCards = showCardsOrig

  if(idSelected !== '' && giftCardData) {
    showCards = giftCardData.filter( x => x.id === idSelected)
    sliceStart = 0
    sliceStop = showCards.length
  } else {
    sliceStart = howManyToShow - 50
    sliceStop = howManyToShow
  }
  // ---------- PAGE RETURN -------------------------------

  return (


    (cards !== 'noAccountYet' && giftCardData && Array.isArray(giftCardData) === true ) ?

      <div>
        {(idSelected === '' && cards) &&
      <>
        <div className="add-item-search-top-bar">
          {/* ADD NEW CARD */}

          { (giftCardContainer === null && giftCardData) &&

         <AddNewCard
           giftCardData ={giftCardData}
           token = {user && user.token}
           cardsId = {cards && cards.id}
         />
          }

          {/* search input */}

          <input className= 'input-cardholder-find' value= {showCardNro} onChange={handleSelectedCardNro} placeholder='etsi korttia'/>
          {showCardNro !== '' &&
          <IconButton
            className="icon-button-gray"
            onClick={() => setShowCardNro('')}
            size="30px"
            char="x"
          />}
        </div>

        {/* TOGGLE BUTTONS */}
        <div className="card-filter-togglers">
          <ToggleButton checked = {cardsFilter.cardsOld} onClick = {setExpiredFilterHandler} label = 'Vanhat'/>
          <ToggleButton checked = {cardsFilter.cardsUsed} onClick = {setEmptyFilterHandler} label = 'Käytetyt' />
          <ToggleButton checked = {cardsFilter.cardsTrach} onClick = {setDeactivatedFilterHandler} label = 'Roskis'/>
        </div>
        <Title text="Lahja- ja sarjakortit" icon="f" />
      </>
        }

        <div className = "giftcards-container">
          {idSelected === '' &&
            <div className = 'search-results-container'>
              <div className="search-results-txt">
                Hakutulokset {howManyToShow - 49} - {howManyToShow} / {showCards.length}
              </div>
            </div>}
          {
            (howManyToShow > 50 && idSelected === '' ) &&

      <button
        className = "buttonGreen"
        style = {{ marginBottom: '20px' }}
        onClick = { () => {
          howManyToShow === 100 && setDownButtonContent('Näytä lisää (50-100) >>')
          setHowManyToShow( howManyToShow - 50 )
          window.scrollTo({ top: 200,  behavior: 'auto' })
        }
        }>
        {`<< Näytä aiemmat ( ${howManyToShow - 99} - ${howManyToShow - 50} )`}
      </button>
          }
          <div className = "flex-column">
            {
              (giftCardData && Array.isArray(giftCardData) === true ) &&
    showCards.slice(sliceStart, sliceStop).map(card =>
    {
      if(!card.salesValue || card.salesValue === undefined) {card.salesValue = card.amount}
      if(card.payingMethod === undefined){
        card.payingMethod = {
          methodName: 'ei määritetty',
          isPersonal:  'ei määritetty',
          needReceipt:  'ei määritetty'
        }
      }
      {
        return(
          <div key={card.id}>
            <Card
              card = {card}
              giftCardContainer = {giftCardContainer}
              setGiftCardContainer = {setGiftCardContainer}
              idSelected = {idSelected}
              setIdSelected = {setIdSelected}

            />
          </div>)}}
    )
            }
          </div>
        </div>
        {(showCards.length > howManyToShow && idSelected === '') &&
     <div className="flex-row-container" style = {{ marginBottom: '60px ' }}>


       <button className = "buttonGreen"  onClick = { () => {
         showCards.length < howManyToShow && setDownButtonContent('')
         showCards.length > howManyToShow && setDownButtonContent( `Näytä lisää ( ${howManyToShow + 51 } - ${howManyToShow + 100} ) >>`)
         window.scrollTo({ top: 0 })
         {/* setHideCards('hide-cards') */}
         setTimeout(() => {
           setHowManyToShow( howManyToShow + 50 )
           {/* setHideCards('show-cards') */}
         }, 200)}
       }>
         {downButtonContent}
       </button>


       <button
         className="buttonGreen"
         onClick={() => {window.scrollTo({ top: 0,  behavior: 'smooth' })}}
       >
          Ylös!
       </button>
     </div>
        }
        {
          (idSelected === '' && showCards.length <= howManyToShow && showCards.slice(sliceStart, sliceStop).length > 8  ) &&
    <button
      className="buttonGreen"
      onClick={() => {window.scrollTo({ top: 0,  behavior: 'smooth' })}}
    >
          Ylös!
    </button>

        }
        {giftCardData[0] === 'empty'  &&
     <div>
       <UiCard headerTitle="Ladataan"><Loader/></UiCard>
       <UiCard headerTitle="Ladataan"><Loader/></UiCard>
       <UiCard headerTitle="Ladataan"><Loader/></UiCard>
       <UiCard headerTitle="Ladataan"><Loader/></UiCard>
     </div>}
        {(giftCardData[0] !== 'empty' && showCards.length === 0) &&
       <div className = 'mp-center'>
         <div className = 'helpText-container '>
           <div className = "helpText-">
             <IconButton className="icon-button-secondary" char="S"/>
           </div>
           <p className = 'helpText'>
              Annetuilla hakuehdoilla <br/>ei ole tuloksia
           </p>
         </div>
       </div>}
      </div>
      :
      // if no account yet, form to make one:
      <NewCardAccount/>
  )
}
export default Cards