
import React from 'react'
import { useDispatch } from 'react-redux'
import BodyInTags from '../../../../../components/Private/Tags/components/BodyComponents/BodyInTags/BodyInTags'
import HelpText from '../../../../../components/UI/HelpText/HelpText'
import DayAndTime from '../../../../../functions/DayAndTime'
import { updateCustomerInstructionTags } from '../../../reducers/rdc-oneCustomer'

const Body_Exercises = ({ customer }) => {
  const dispatch = useDispatch()
  const instructionTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateCustomerInstructionTags(token, newTags, customer.id, appointmentId, tags ))
  }

  const Exercises = () => {


    return(
      <>
        {customer && customer.appointments !== undefined &&
        customer.appointments.map(app =>

          <div className="even-odd" key={app.id}>
            <div className = "card-list ">
              <div className="flex-container-info"  ><b><DayAndTime date = {app.date}/></b></div>
              <div className="flex-item-right">       {app.instructions}</div>
            </div>
            <BodyInTags
              items = {app.instructionTags}
              itemId = {app.id}
              selectedID={app.id}
              saveTags = {instructionTags}
              linkText = 'tagit'
              editTitle = '-'
              sortBy = 'instructions'
            />
          </div>


        )}
      </>
    )
  }


  return(
    <>
      <h4 className="body-header">Ohjeistukset</h4>
      <HelpText
        text=' Täällä voit tarkastella kirjattuja harjoitteita. Tietoja ei voi täällä muokata, ne on tehtävä kirjaukset -välilehden alla.'
      />
      <div className="customers-body-container">
        <Exercises/>

        {/* if props childern length array is empty, there is no data to show */}
        {/* so show information, that there is not yet exrcises */}
        {Exercises().props.children && Exercises().props.children.length === 0 &&
        <div className="mp-p-20">
          <h6>Asiakkaalla ei ole vielä kirjattuja ohjeistuksia</h6>
          <p className="mp-center">Ohjeistukset tehdään kirjauksissa. Tämä mesta on vain oikopolku tsekata pelkät ohjeistukset.</p>
        </div>
        }

      </div>
    </>
  )
}
export default Body_Exercises
