import React from 'react'
import './AuthorCircle.css'
import authorColors from './authorColors'

let auth2, authCol, color

const AuthorCircle = (props) => {
  if(props.color){color = props.color} else {color = 'yes'}
  if(props.firstName && props.lastName){
    auth2 = props.firstName.slice(0,1) + props.lastName.slice(0,1)
    authCol = props.firstName.slice(0,2) + props.lastName.slice(0,2)

  } else if(props.author) {
    auth2 = props.author.slice(0,2); authCol = props.author.slice(0,3)
  } else {
    auth2 = 'na'; authCol='aaaa'}

  return(
    <>
      <div className="author-circle"
        style={{ backgroundColor: 'rgb(' + authorColors([authCol,color]) + ')' }}>
        <p>{auth2}</p>
      </div>
    </>
  )
}
export default AuthorCircle