import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDatapoint } from '../../../../reducers/rdc-oneCustomer'
import Button from '../../../../../../components/UI/Button/Button'

const AddDatapoint = ({ customer, user, setShowAddPointForm }) => {
  const dispatch = useDispatch()
  const [dataPointName, seDataPointName] = useState('')
  const [dataPointUnit, setDataPointUnit] = useState('')

  const sendDataPointHandler = () => {
    const body =
    {
      name: dataPointName,
      unit: dataPointUnit
    }
    dispatch(addDatapoint(user.token, body, customer.id))
    setShowAddPointForm(false)
  }

  return(
    <div className = "container mp-mb-40">
      <div className="customers-historylist-headerrow">
        <h6>Tee uusi Seurantapiste</h6>
      </div>
      <div>
        <div className="input-group">
          <label>Syötä seurantapisteen nimi</label>
          <input
            className="passed"
            placeholder="Seurantapisteen nimi"
            value = {dataPointName}
            onChange={(e) => {seDataPointName(e.target.value)}}
          />
          <div className="input-group">
            <label>Syötä yksikkö</label>
            <input
              className="passed"
              placeholder="yksikkö"
              value = {dataPointUnit}
              onChange={(e) => {setDataPointUnit(e.target.value)}}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="buttons-row" >
            <Button
              className = "buttonGreen"
              label = 'Lisää'
              type = 'buttonGreen'
              icon = 'update'
              onClick={sendDataPointHandler}
            />
            <div onClick={ () => { setShowAddPointForm(false) }}>
              <Button
                type = "buttonNoEnter"
                label='Peruuta'
                icon='undo'
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AddDatapoint