import  {
  customerGetOne,
  customerNewAppointment,
  customerNew,
  customerDelete,
  customerUpdate,
  dataPointAdd,
  dataAdd,
  dataPointEdit,
  dataEdit,
  dataDelete,
  dataPointDelete,
  customerUpdateAppointmenTags,
  customerUpdateDatapointTags,
  customerUpdateChechTags,
  customerUpdateInstructionTag
} from '../services/srv-customers'

const oneCustomerReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_ONE_CUSTOMER':
    return action.data
  default: return state
  }
}

// ------------------- customer ------------------------------//
// get one customer
export const getOneCustomer = (token, customerId) => {
  return async dispatch => {
    let customer = await customerGetOne(token, customerId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// update customer -- used also for update appointment
export const updateCustomer = (token, body, customerId) => {
  return async dispatch => {
    let customer = await customerUpdate(token, body, customerId)

    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// new customer

export const addNewCustomer = (token, body) => {
  return async dispatch => {
    let customer = await customerNew(token, body)

    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}
//-----------------------
// update appointment tags

// updateCustomerAppointmentsTags(token, newTags, appointmentId )


export const updateCustomerAppointmentsTags = (token, body, customerId, appointmentId) => {
  // body = new updat4ed tags in appointment
  return async dispatch => {
    let customer = await customerUpdateAppointmenTags(token, body, customerId, appointmentId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}
export const updateCustomerCheckTags = (token, body, customerId, appointmentId) => {

  return async dispatch => {
    let customer = await customerUpdateChechTags(token, body, customerId, appointmentId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

export const updateCustomerInstructionTags = (token, body, customerId, appointmentId) => {

  return async dispatch => {
    let customer = await customerUpdateInstructionTag(token, body, customerId, appointmentId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// update Datapoint tags

export const  updateDatapointTags  = (token, body, customerId, datapointId) => {
  // body = new updat4ed tags in appointment
  return async dispatch => {
    let customer = await customerUpdateDatapointTags(token, body, customerId, datapointId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// empty oneCustomer state when leaving

export const emptyCustomerState = () => {
  return async dispatch => {

    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: []
    })
  }
}

// delete customer

export const deleteCustomer = (token, customerId) => {
  return async dispatch => {
    let customer = await customerDelete(token, customerId)

    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// add new appointment
export const addNewAppointment = (token, body, customerId) => {
  return async dispatch => {
    let customer = await customerNewAppointment(token, body, customerId)

    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: customer.data
    })
  }
}

// ------------- dataPoint services -----------------------------

export const addDatapoint = (token, body, customerId) => {
  return async dispatch => {
    let dataPoint = await dataPointAdd(token, body, customerId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: dataPoint.data
    })
  }
}

export const editDataPointDb = (token, body, customerId) => {
  return async dispatch => {
    let dataPoint = await dataPointEdit(token, body, customerId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: dataPoint.data
    })
  }
}

export const deleteDataPoint = (token, customerId, dataPointId) => {
  return async dispatch => {
    let dataPoint = await dataPointDelete(token, customerId, dataPointId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: dataPoint.data
    })
  }
}

// --------------- data services ----------------------

export const addData = (token, body, customerId) => {
  return async dispatch => {
    let data = await dataAdd(token, body, customerId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: data.data
    })
  }
}

export const editDatadb = (token, body, customerId) => {
  return async dispatch => {
    let data = await dataEdit(token, body, customerId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: data.data
    })
  }
}

export const deleteData = (token, customerId, dataId) => {
  return async dispatch => {
    let data = await dataDelete(token, customerId, dataId)
    dispatch({
      type: 'SET_ONE_CUSTOMER',
      data: data.data
    })
  }
}


export default oneCustomerReducer