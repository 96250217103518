import React, { useState } from 'react'
import PublicHeader from '../PublicHeader/PublicHeader'
import { Link } from 'react-router-dom'
import { forgotPassword } from '../../../services/auth'

const ForgotPassword = () => {

  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const emailSentVerification = <div>
    <h3>Sähköposti lähetetty osoitteeseen: {email}</h3>
    <p>Ohjeiden saapumisessa voi kestää muutama minuutti. Jos ohjeita ei näy, tarkista roskapostisi.</p>
  </div>

  const handleSubmit = async (event) => {
    event.preventDefault()
    await forgotPassword(email)
    setEmailSent(true)
  }

  return(
    <>
      <PublicHeader/>
      <div className="container">
        {!emailSent ?
          <>
            <h1>Salasana hukassa?</h1>
            <p>
          Syötä sähköpostisi jolla olet rekisteröitynyt käyttäjäksi palveluun. Lähetämme sinulle ohjeet salasanan vaihtoon.
            </p>
            <form>
              <div className="input-group">
                <label htmlFor="email">Sähköposti</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <button onClick={handleSubmit}>Lähetä</button>
            </form>
          </>
          : emailSentVerification
        }
        <Link to='/'>
          <p>Kirjaudu sisään</p>
        </Link>
      </div>
    </>
  )
}

export default ForgotPassword