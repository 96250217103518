import { getAllCompanies, createNewCompany } from '../services/company'


const companyReducer = (state = [], action) => {
  switch(action.type){
  case 'SET_COMPANIES':
    return action.data

  default: return state
  }
}

export const setCompanies = (token, companyId) => {
  return async dispatch => {
    const companies = await getAllCompanies(token, companyId)
    dispatch({
      type: 'SET_COMPANIES',
      data: companies
    })
  }
}

export const newCompany = (token, body) => {
  return async dispatch => {
    const companies = await createNewCompany(token, body)
    dispatch({
      type: 'SET_COMPANIES',
      data: companies
    })
  }
}

// export const changeCompany = (companiesOld, body) => {
//   const updatedCompanies =  { ... companiesOld, body }
//   return async dispatch => {
//     // const companies = await updateCompany(token, body, id)
//     dispatch({
//       type: 'SET_COMPANIES',
//       data: updatedCompanies
//     })
//   }
// }

export default companyReducer