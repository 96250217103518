import React from 'react'
const Tietosuojaseloste = () => {

  return(
    <div>
      <h3>Fysio Up - Tietosuojaseloste</h3>
      <h5>Rekisterinpitäjä</h5>
      <p>Fysio Up Oy, Rautatienkatu 11, 15110 Lahti</p>
      <h5>2 Rekisteriasioiden yhteyshenkilö</h5>
      <p>Roni Koivunen, info(at)fysioup.fi</p>

      <h5>3 Rekisterin nimi</h5>
      <p>Fysio Up Oy:n asiakasrekisteri</p>

      <h5>4 Mitkä ovat henkilötietojen käsittelyn oikeusperuste ja tarkoitus?</h5>
      <p>Henkilötietojen käsittelyn perusteena on laki, asiakkaan (rekisteröity) nimenomainen suostumus, yrityksen oikeutettu etu asiakassuhteen ja/tai muun asiallisen yhteyden perusteella ja sopimuksen täytäntöönpaneminen.Henkilötietojen käsittelyn tarkoituksena on:</p>
      <ul>
        <li>asiakkaan turvallinen hoitaminen ja hoidon laadun varmistaminen</li>
        <li>sopimus- ja muiden lupaustemme ja velvollisuuksiemme täyttäminen</li>
        <li>asiakassuhteemme hoitaminen ja kehittäminen</li>
        <li>sähköinen suoramarkkinointi</li>
      </ul>

      <h5>5 Mitä tietoja käsittelemme?</h5>

      <p>Käsittelemme asiakasrekisterin yhteydessä seuraavia asiakkaan henkilötietoja:</p>

      <ul>
        <li>rekisteröidyn perustiedot kuten etunimi*, sukunimi*, syntymäaika</li>
        <li>rekisteröidyn yhteystiedot kuten sähköpostiosoite*, puhelinnumero*, katuosoite*, postinumero* ja postitoimipaikka</li>
        <li>rekisteröityä koskevat suoramarkkinointikiellot ja -suostumukset</li>
        <li>rekisteröidyn antamat esitietolomakkeessa kysytyt taustatiedot koskien rekisteröidyn terveydentilaa: fyysinen kiputila asteikolla 0 – 10, diagnosoidut sairaudet ja diagnoosien toteamisaika</li>
        <li>hierojan esitietolomakkeeseen täyttämät rekisteröidyn terveyttä koskevat taustatiedot</li>
        <li>asiakkuutta ja sopimusta koskevat tiedot kuten tiedot menneistä ja voimassaolevista sopimuksista sekä toteutetuista ja tilatuista palveluista</li>
        <li>rekisteröidyn asiointiin ja hoitoon liittyvät tiedot kuten palvelun (hoidon) toteuttanut hieroja, hoitokäyntiä koskevat tiedot ja hoidon toteuttamiseen liittyvät hierojan tekemät relevantit kirjaukset rekisteröityyn ja hänen kulloiseenkin terveydentilaan liittyen;Tähdellä merkittyjen henkilötietojen antaminen on edellytys sopimussuhteemme ja/tai asiakassuhteemme syntymiselle. Ilman tarvittavia henkilötietoja emme voi toimittaa tuotetta ja/tai palvelua</li>
      </ul>

      <h5>6 Mistä saamme tietoja?</h5>
      <p>Saamme tietoja rekisteröidyltä itseltään.</p>

      <h5>7 Kenelle luovutamme ja siirrämme tietoja sekä siirrämmekö tietoja EU:n tai ETA:n ulkopuolelle?</h5>
      <p>Emme luovuta tietoja kolmansille osapuolille.</p>

      <h5>8 Miten suojaamme tiedot ja miten pitkään niitä säilytämme?</h5>
      <p>Henkilötietoja sisältävän järjestelmän käyttöön ovat oikeutettuja vain ne työntekijämme, joilla on sopimus työskennellä tiloissamme.  Kullakin käyttäjällä on oma käyttäjätunnus ja salasana järjestelmään. Tiedot kerätään tietokantoihin, jotka ovat palomuurein, salasanoin ja muilla teknisillä keinoilla suojattuja. Tietokannat ja niiden varmuuskopiot sijaitsevat lukituissa tiloissa ja tietoihin pääsevät käsiksi vain tietyt ennalta nimetyt henkilöt.Säilytämme henkilötietoja niin kauan kuin henkilötiedon käyttötarkoituksen vuoksi on tarpeellista. Lähtökohtaisesti säilytämme tietoja asiakassuhteen voimassaolon ajan ja sen jälkeen kaksi (2) vuotta. Paperisia esitietolomakkeita säilytämme kahden (2) lukuvuoden ajan, jonka jälkeen ne hävitetään asianmukaisesti.Arvioimme tietojen säilyttämisen tarpeellisuutta säännöllisesti sovellettava lainsäädäntö huomioon ottaen. Tämän lisäksi huolehdimme sellaisista kohtuullisista toimenpiteistä, joilla varmistetaan, ettei rekisteröidyistä säilytetä rekisterissä käsittelyn tarkoituksiin nähden yhteensopimattomia, vanhentuneita tai virheellisiä henkilötietoja. Oikaisemme tai hävitämme tällaiset tiedot viipymättä.</p>

      <h5>9 Mitkä ovat sinun oikeutesi rekisteröitynä?</h5>
      <p>Rekisteröitynä sinulla on oikeus tarkastaa itseäsi koskevat henkilörekisteriin tallennettuja vaatia virheellisen, vanhentuneen, tarpeettoman tai lain vastaisen tiedon oikaisua tai poistamista. Mikäli sinulla on pääsy itselläsi tietoihisi, voit muokata tietojasi itse. Mikäli käsittely perustuu suostumukseen, sinulla on myös oikeus peruuttaa suostumuksesi tai muuttaa sitä. Suostumuksen peruuttaminen ei vaikuta käsittelyn lainmukaisuuteen ennen suostumuksen peruuttamisen ajankohtaa.</p>

      <h5>10 Kehen voit olla yhteydessä?</h5>
      <p>Kaikki tätä selostetta koskevat yhteydenotot ja pyynnöt tulee esittää kirjallisesti tai henkilökohtaisesti kohdassa kaksi (2) nimetylle yhteyshenkilölle.</p>

      <h5>11 Tietosuojaselosteen muutokset</h5>
      <p>Mikäli muutamme tätä selostetta, laitamme muutokset näkyville selosteeseen päivättyinä. Mikäli muutokset ovat merkittäviä, voimme informoida näistä myös muulla tavoin, kuten sähköpostitse tai laittamalla sivuillemme asiasta ilmoituksen. Suosittelemme, että käytte säännöllisesti sivuillamme ja huomioitte mahdolliset muutoksemme selosteessa.</p>
    </div>
  )
}

export default Tietosuojaseloste