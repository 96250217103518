import { getAllUsers } from '../services/users'

const filter = {
  firstname: '',
  lastname: '',
  username: '',
  activeOnly: false,
  roles: ['user', 'staff', 'admin', 'superadmin'],
  isStudent: false,
  validated: true
}

const initialState = {
  allUsers: [],
  filteredUsers: [],
  filter: filter
}
let filteredUserList, newUsers, newFilteredUsers

const usersReducer = (state = initialState, action) => {
  switch(action.type){
  case 'INIT_USERS':

    filteredUserList = filterUserList(action.data, state.filter)

    newUsers = {
      ...state,
      allUsers: action.data,
      filteredUsers: filteredUserList
    }
    return newUsers

  case 'SET_FILTER':
    newFilteredUsers = {
      ...state,
      filteredUsers: filterUserList(state.allUsers, action.data),
      filter: action.data
    }
    return newFilteredUsers

  default: return state
  }
}

export const initializeUsers = (token, company) => {
  return async dispatch => {
    const users = await getAllUsers(token, company)
    dispatch({
      type: 'INIT_USERS',
      data: users,
    })
  }
}

export const filterUsers = (filter) => {
  return{
    type: 'SET_FILTER',
    data: filter
  }
}


const filterUserList = (userList, filter) => {
  const results = userList && userList.filter(u => {
    let filteredUser = u
    let userFound = false

    if(u.role){
      for (let i = 0; i < filter.roles.length; i++) {
        if(filter.roles[i] === u.role){
          userFound = true
        }
      }
      if(!userFound){
        filteredUser = null
      }
    }

    if(filter.firstname.length > 0){
      userFound = u.firstname && u.firstname.toLowerCase().includes(filter.firstname.toLowerCase())
      if(!userFound){
        filteredUser = null
      }
    }

    if(filter.lastname.length > 0){
      userFound = u.lastname && u.lastname.toLowerCase().includes(filter.lastname.toLowerCase())
      if(!userFound){
        filteredUser = null
      }
    }

    if(filter.username.length > 0){
      userFound = u.username && u.username.toLowerCase().includes(filter.username.toLowerCase())
      if(!userFound){
        filteredUser = null
      }
    }

    if(filter.activeOnly){
      userFound = u.active
      if(!userFound){
        filteredUser = null
      }
    }

    if(filter.isStudent){
      if(u.isStudent !== null){

        userFound = u.isStudent
        if(!userFound){
          filteredUser = null
        }
      }
    }

    if(!u.validated){
      filteredUser = null
    }

    return filteredUser && filteredUser

  })
  return results
}


export default usersReducer