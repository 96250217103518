import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import IconButton from '../../UI/IconButton/IconButton'
import Loader from '../../UI/Loader/Loader'
import { setNotification } from '../../UI/Notification/notificationReducer'
import Title from '../../UI/Title/Title'
import UiCard from '../../UI/UiCard/UiCard'
import Item from './components/Item'
import { post_location } from './reducers/locationsReducer'

const Locations = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const locations = useSelector(state => state.locations).filter( loc => loc.state !== false)

  const newLocationHandler = () => {
    const body = {
      name: 'Aseta paikalle nimi',
      company: user.company
    }
    dispatch(post_location(user.token, body, locations))
    dispatch(setNotification({ notification: 'Uusi paikka luotu, täytä paikan tiedot alla' }))
  }


  return(
    <div>
      <div className="customer-add-new-customer">
        <IconButton
          char="j"
          className="icon-button-main"
          onClick={newLocationHandler}
        />
      </div>
      <Title text="Toimipisteet" icon="h"/>
      <div className="container flex-column-reverse">
        {locations ? locations.map( item => {
          let style = {}
          if(item.name === 'Aseta paikalle nimi') {style = { border: 'solid 5px var(--passed-color)', borderRadius: '5px' }}
          return(
            <div key={item.id}>
              <UiCard
                style = {style}
                headerTitle = {item.name}
              >
                <Item item={item}/>
              </UiCard>
            </div>
          )
        }):
          <Loader/>
        }

      </div>
    </div>

  )
}

export default Locations