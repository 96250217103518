// textarea with controlled height.
// use trickers variable for tricker new height value
// value for value, what textarea renders
// Antti Haikonen 5.1.2022

import React, { useState, useRef, useEffect } from 'react'

const TextArea = ({ onChange, className, value, trickers, placeholder }) => {

  const elementRef = useRef(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    // setHeight
    elementRef !== null && elementRef.current && setHeight(elementRef.current.scrollHeight)
  }, [trickers])

  return(

    <textarea
      placeholder={placeholder}
      ref={elementRef}
      style={{ height: height }}
      className = {className}
      value = {value}
      onChange={onChange}
    />
  )}

export default TextArea