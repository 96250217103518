import React, { useState } from 'react'
import '../RaportsHolder.css'
import UiCard from '../../../../../components/UI/UiCard/UiCard'
import DateDropDown from './DateDropDown'
import Button from '../../../../../components/UI/Button/Button'
// import { useSelector } from 'react-redux'

const TableOfUse = ({ giftCardData }) => {
  const yearNow = new Date().getFullYear()
  let monthNow = (new Date().getMonth()+1).toString()
  if (monthNow < 10) {monthNow = '0' + monthNow}
  let dayNow = new Date().getDate().toString()
  if (dayNow < 10) {dayNow = '0' + dayNow}

  let monthToStart = monthNow
  if (monthNow > 1) monthToStart = monthNow-1
  if (monthToStart < 10) monthToStart = '0' + monthToStart.toString()

  const [filterRange, setFilterRange] = useState([
    '01',                       // start day
    monthToStart.toString(),    // start month
    yearNow.toString(),         // start year
    dayNow,                     // end day
    monthNow,                   // end month
    yearNow.toString()          // end year
  ])

  let filterStartDate = filterRange[2].toString() + '-' + filterRange[1].toString() + '-' + filterRange[0].toString()
  let filterEndDate = filterRange[5].toString() + '-' + filterRange[4].toString() + '-' + filterRange[3].toString()
  const[findFilter, setFindFilter] = useState('')
  const[showMoreUses, setShowMoreUses] = useState(['200px','Näytä lisää'])
  const timeRangeString = `${filterRange[0]}.${filterRange[1]}.${filterRange[2]} – ${filterRange[3]}.${filterRange[4]}.${filterRange[5]}`
  const handleFind = (event) => {
    setFindFilter(event.target.value)
  }

  // usageData of Cards
  const giftCardUseHistory =
  giftCardData.filter(x => x.active === true) // only active cards
    .map(x => x.usage)
    .filter(x => x.length > 0)

    // concatenation of usage data
    .reduce((pre, cur) => pre.concat(cur))
    .filter(x => x.worker) // this can be removed after DB is cleaned !!!!!!

    // sorting data by amount
    .sort((a, b) => a.usageDate.localeCompare(b.usageDate))

  // filter by date
    .filter(x =>
      x.usageDate.slice(0,10) >= filterStartDate
        && x.usageDate.slice(0,10) <= filterEndDate)

    // filter by find input
    .filter(x =>
      x.worker.toLowerCase().includes(findFilter.toLowerCase()
      ))

  // total usage of cards:
  const giftCardsTotalUsage = giftCardUseHistory
    .map(x => x.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  // handle for CVS
  const handleCvs = (event) => {
    event.preventDefault()
    let find = ''
    if(findFilter !== '') {find = `_hakusanalla_${findFilter}`} else {find = ''}
    let worker = ''

    let csvContent = 'data:text/csv;charset=utf-8,'
          + ',Pvm,määrä (€),Työntekijä \n,'


          + giftCardUseHistory.map(function(x) {
            if(x.worker){worker =x.worker.replace(',','.').replace(';',':')}
            return x.usageDate.slice(0,10).toString() + ',' + x.amount.toString() + ',' + worker.slice(0,25).toString() + '\n'})
           + `\n,Yhtensä (€),${giftCardsTotalUsage},`

    var encodedUri = encodeURI(csvContent)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `Lahjakorttien_kayttoraportti_${timeRangeString}${find}.csv`)
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".


  }

  return(
    <>
      <UiCard
        headerTitle = "Käyttöraportti "
        headerAmount = {timeRangeString}
        headerInfo = {'yht. ' + giftCardsTotalUsage + ' €'}
        footerInfo = "CVS-tiedoston voit avata taulukkolaskentaohjelmassa. Hakukenttällä voit etsiä esim. tietyn työntekijän korttien käytön"
      >

        <h4 className="body-header">Käyttöraportti</h4>
        <div className="giftcard-body-container">
          <input className="input-find    "
            value= {findFilter}
            onChange={handleFind}
            placeholder='hae'
          />
          <div className ="gap-20"/>

          <DateDropDown
            setFilterRange = {setFilterRange}
            filterRange = {filterRange}
            yearNow = {yearNow}
          />


          <div style={{ transition:' max-height 0.6s ease-out', height:'auto', maxHeight: showMoreUses[0], overflowY: 'scroll' }}>
            {giftCardUseHistory.length > 0 &&
    <table style={{ textAlign: 'left', borderLineWidth: '1px' }}>
      <thead>
        <tr className="tr-thead">
          <th>Pvm.</th>
          <th>määrä</th>
          <th>Työntekijä</th>
        </tr>
      </thead>
      <tbody>
        { giftCardUseHistory.map(x =>
          <tr key = {x.id}>
            <td>{new Date(x.usageDate).getDate()}.{new Date(x.usageDate).getMonth()+1}.{new Date(x.usageDate).getFullYear()}</td>
            <td>{x.amount}</td>
            <td>{x.worker && x.worker.substring(0, 25)}</td>
          </tr>
        )}
      </tbody>
    </table>
            }
          </div>

          {giftCardUseHistory.length > 8 &&
      <p className = "cvs-link" onClick={() => {if (showMoreUses[0] === '2000px') {setShowMoreUses(['200px','Näytä lisää'])} else {setShowMoreUses(['2000px','Näytä vähemmän'])} }}>{showMoreUses[1]}</p>}
          {giftCardUseHistory.length > 0 ?
            <Button icon = 'download' type = "buttonGreen" label = 'Lataa CSV-tiedosto' onClick={handleCvs}/>
            : <p>Ei tuloksia annetulla haulla / aikajaksolla</p>
          }
        </div>
      </UiCard>
    </>
  )
}

export default TableOfUse