import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/customers'

// ----------------------GET CUSTOMERS --------------------------->

export const customersGet = async (token, companyId) => {
  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl + '/' + companyId, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customersGet: ', err)
  }
}

export const customerGetOne = async (token, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl + '/getCustomer/' + customerId, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerGetOne: ', err)
  }
}
export const customerNewAppointment = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/newAppointment/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerNewAppointment: ', err)
  }
}

// edit appointment tags
export const customerUpdateAppointmenTags = async (token, body, customerId, appointmentId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/appointmentTag/' + customerId + '/' + appointmentId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerUpdateAppointmenTags: ', err)
  }
}
// edit appointment tags
export const customerUpdateChechTags = async (token, body, customerId, appointmentId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/chackTag/' + customerId + '/' + appointmentId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerUpdateChechTags: ', err)
  }
}
// edit appointment tags
export const customerUpdateInstructionTag = async (token, body, customerId, appointmentId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/instructionTag/' + customerId + '/' + appointmentId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerUpdateInstructionTag: ', err)
  }
}

export const customerUpdateDatapointTags = async (token, body, customerId, dataPointId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/dataPointTag/' + customerId + '/' + dataPointId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerUpdateDatapointTags: ', err)
  }
}

export const customerNew = async (token, body) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/newCustomer/', body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerNew: ', err)
  }
}

export const customerUpdate = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/updateCustomer/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerNew: ', err)
  }
}


export const customerDelete = async (token, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/' + customerId, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/customerNew: ', err)
  }
}

export const dataPointAdd = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/dataPoint/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/dataPoint: ', err)
  }
}
export const dataAdd = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/data/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/dataPoint: ', err)
  }
}

export const dataPointEdit = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/dataPoint/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/dataPoint (put): ', err)
  }
}

export const dataEdit = async (token, body, customerId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/data/' + customerId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/data (put): ', err)
  }
}

// Delete data
export const dataDelete = async (token, customerId, dataId) => {

  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/data/' + customerId + '/' +  dataId, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/data (delete): ', err)
  }
}

// Delete dataPoint
export const dataPointDelete = async (token, customerId, dataPointId) => {
  console.log('customerId-srv: ', customerId)
  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/dataPoint/' + customerId + '/' + dataPointId, config)
    return response

  } catch(err){
    console.log('Umhh... error at customers/service/dataPoint (delete): ', err)
  }
}