import React from 'react'
import './QuotedPost.css'

const QuotedPost = ({ name }) => {

  return(
    <div className="quoted-post">
      <h5>Vastaa käyttäjän {name} viestiin:</h5>
    </div>
  )
}

export default QuotedPost
