import React, { useState, useEffect } from 'react'
import EmailList from './EmailList/EmailList'
import { inviteUsers } from '../../../../services/users'
import { useSelector, useDispatch } from 'react-redux'
import { initializeInvitedUsers } from '../../../../reducers/invitedUsersReducer'
import dayjs from 'dayjs'
import Title from '../../../UI/Title/Title'
import InvitedList from './InvitedList/InvitedList'
import Button from '../../../UI/Button/Button'
import './InviteUser.css'
import langs from '../../../../langs'
import UiCard from '../../../UI/UiCard/UiCard'
import { setNotification } from '../../../UI/Notification/notificationReducer'

const InviteUser = () => {

  const [emailList, setEmailList] = useState([])
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('user')
  const [expiryDate, setExpiryDate] = useState(dayjs().add(1, 'year').format('YYYY-MM-DD'))
  const [infiniteLicence, setInfiniteLicence] = useState(false)
  const [isStudent, setIsStudent] = useState(true)
  const [errorMessages, setErrorMessages] = useState(null)
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  const dispatch = useDispatch()
  const token = useSelector(state => state.user && state.user.token)
  const invitedUsers = useSelector(state => state.invitedUsers && state.invitedUsers)

  useEffect(() => {
    dispatch(initializeInvitedUsers(token))
  }, [dispatch, token])

  const clickAddEmail = (event) => {
    event.preventDefault()
    if(validate(email)){
      const newList = [...emailList, email]
      setEmailList(newList)
      setEmail('')
      setErrorMessages(null)
    }
  }

  const removeEmail = (event) => {
    const newEmailList = emailList.filter(e => e !== event.target.id)
    setEmailList(newEmailList)
  }

  const clickSendInvitations = async (event) => {
    event.preventDefault()
    const licenseExpiresAt = infiniteLicence ? null : expiryDate
    // If everything goes well response is 200 else it's an error msg from server.
    // Sent invitations will be displayed in InvitationList
    const response = await inviteUsers(token, emailList, role, isStudent, licenseExpiresAt)
    if(response !== 200){
      dispatch(setNotification({ notification: response, type: 'alert' }))
    }
    else
    {
      // If everything was ok
      dispatch(setNotification({ notification: trans.invitationsSent[lN], type: 'default' }))
      setEmailList([])
      dispatch(initializeInvitedUsers(token))
    }
  }

  const validate = (email) => {
    let err = []
    !email && err.push(trans.fillEmail[lN])
    if(email){
      if(!email.toString().includes('@') || !email.toString().includes('.')){
        err.push(`${email}: ` + trans.badEmail[lN])
      }
    }
    if(err.length > 0){
      setErrorMessages(err)
      return false
    }
    else
    {
      return true
    }
  }

  const handleInput = (e) => {
    const inputId = e.target.id
    switch(inputId){
    case 'expiry-date':{
      const expDate = new Date(e.target.value)
      setExpiryDate(expDate)
      break
    }
    case 'infite-license':
      setInfiniteLicence(!infiniteLicence)
      break
    default: break
    }
  }

  return(
    <div className="container">
      <Title text = 'Käyttäjäkutsut' icon="l"/>
      <UiCard
        headerTitle = {trans.sendInvitationsTitle[lN]}
        footerInfo = "Voit lisätä yhden tai usemman kutsun kerralla. Kirjoita kutsuttavan email-osoite ja paina lisää sähköpostiosoite."
      >
        <div className="giftcard-body-container">
          <div className="input-group mp-mb-40">
            <form id="add-emails-form">
              <div className="gap-10"/>
              <label>Lisättävän henkilön sähköpostiosoite</label>
              <input
                type="email"
                value={email}
                placeholder = {trans.email[lN]}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                onClick={clickAddEmail}
                type='buttonGreen'
                label = {trans.addEmail[lN]}
                icon = "add"
              />
            </form>
            <ul>
              { errorMessages && errorMessages.map(e => <li key={e + Math.random() * 999999}>{e}</li>) }
            </ul>
          </div>
          <div className="gap-20"/>
          <hr/>
          {emailList.length > 0 && < EmailList emails={emailList} removeEmail={removeEmail}/>}

          { emailList.length > 0 &&
        <>
          <div className="input-group">
            <label>{trans.role[lN]}</label>
            <select value={role} onChange={ (event) => setRole(event.target.value)}>
              <option value="user">{trans.basicUser[lN]}</option>
              <option value="staff">{trans.staff[lN]}</option>
              <option value="admin">{trans.admin[lN]}</option>
            </select>
          </div>

          <div className="input-group">
            <input type="checkbox" checked={isStudent} value="is-student" onChange={() => setIsStudent(!isStudent)} />
            <label>{trans.student[lN]}</label>
          </div>

          <div className="input-group">
            <input
              id="infite-license"
              name="infite-license"
              data-testid="infite-license"
              type="checkbox"
              onChange={handleInput}
              value={infiniteLicence}
              defaultChecked={infiniteLicence}
            />
            <label htmlFor="infinite-license">{trans.notExpiring[lN]}</label><br/>
          </div>
          { !infiniteLicence &&
          <>
            <label>{trans.orChooseExpDay[lN]}</label><br/>
            <input
              id="expiry-date"
              name="expiry-date"
              data-testid="expiry-date"
              type="date"
              value={expiryDate}
              onChange={handleInput}
            />
          </>
          }
          <div className = "gap-20"/>
          <Button
            label = {trans.sendInvitations[lN]}
            type = "buttonGreen"
            onClick={clickSendInvitations}
            icon = "send"
          />
        </>
          }
        </div>
      </UiCard>
      <InvitedList invitedUsers={invitedUsers && invitedUsers} />

    </div>
  )
}

export default InviteUser