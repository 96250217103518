import React from 'react'
import './LinkInPost.css'
import Button from '../../../../../../components/UI/Button/Button'

const LinkInPost = ({ url }) => {

  const link = () => {
    window.location.href = url
  }
  return(
    <Button
      type = 'buttonGreen'
      icon="link"
      onClick={link}
      label = {url.length > 25 ? url.slice(0,25) + '...' : url}

    />
  )
}

export default LinkInPost