import React, { useEffect } from 'react'
import MiniLoader from '../../../../../../components/UI/MiniLoader/MiniLoader'

const FileUpload = ({ setWidgetOpen, addImage }) => {

  useEffect(() => {
    const checkUploadResult = (resultEvent) => {
      let imgUrl = null
      if(resultEvent.event === 'success'){
        imgUrl = resultEvent.info.secure_url
        imgUrl && addImage(imgUrl)
      }
      if(resultEvent.event === 'close' || resultEvent.event === 'abort'){
        setWidgetOpen(false)
      }
    }
    const widget = window.cloudinary.createUploadWidget({
      cloudName: 'ahjomedia',
      uploadPreset: 'nmxkzrzz',
    }, (err, result) => {
      !err ? checkUploadResult(result) : console.log('Error creating Cloudinary widget')
    })
    widget.open()
  }, [addImage, setWidgetOpen])

  return(
    // This is shown until widget is loaded
    <MiniLoader/>
  )
}

export default FileUpload