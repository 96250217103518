import React from 'react'
import './ActionButton.css'

const ActionButton = (props) => {
  return(
    <button className="action-button" onClick={props.onClick} id={props.id}>
      X
    </button>
  )
}

export default ActionButton