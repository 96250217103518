import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../components/Private/Home/Home'
import UserAdmin from '../components/Private/UserAdmin/UserAdmin'
import UpdateUser from '../components/Private/UserAdmin/UpdateUser/UpdateUser'
import NewUser from '../components/Private/UserAdmin/NewUser/NewUser'
import InviteUser from '../components/Private/UserAdmin/InviteUser/InviteUser'
import UserProfile from '../components/Private/UserProfile/UserProfile'
import UserTrashcan from '../components/Private/UserAdmin/UserTrashcan/UserTrashcan'
import EditAccount from '../components/Private/Accounts/EditAccount'
import Footer from '../components/UI/Footer/Footer'
import Todo from '../Apps/ToDo/ToDo/ToDo'

// Account management
import Accounts from '../components/Private/Accounts/Accounts'
import NewAccount from '../components/Private/Accounts/NewAccount'
import Tags from '../components/Private/Tags/Tags'
import AppRoutes from '../Apps/AppRoutes'
import TagCategories from '../components/Private/TagCategories/TagCategories'
import Products from '../components/Private/Products/Products'
import { useSelector } from 'react-redux'
import NavBar from '../components/UI/NavBar/NavBar'
import Locations from '../components/Private/Locations/Locations'

const SuperAdminRoutes = ({ userToDisplay, activeModules }) => {
  const theme = useSelector(state => state.theme)

  return(
    <div className={ 'routes-container' + theme } >
      <Todo/>
      <Switch>
        <Route exact path="/home">       <NavBar activeModules = {activeModules}/>                                            <Home />                            <Footer/> </Route>
        <Route exact path="/">            <NavBar activeModules = {activeModules}/>                                           <Home />                            <Footer/> </Route>
        <Route exact path="/profile">         <NavBar activeModules = {activeModules}/>                                       <UserProfile />                     <Footer/> </Route>
        <Route  path="/accounts/accounts" >       <NavBar activeModules = {activeModules}/>      <div className="container">  <Accounts/>       </div>            <Footer/> </Route>
        <Route path="/accounts/newaccount">         <NavBar activeModules = {activeModules}/>    <div className="container">  <NewAccount/>     </div>            <Footer/> </Route>
        <Route exact path="/accounts/editaccount/:id"> <NavBar activeModules = {activeModules}/> <EditAccount/>                                                   <Footer/> </Route>
        <Route exact path="/admin/user/:id">     <NavBar activeModules = {activeModules}/>       <UpdateUser user={userToDisplay} />                              <Footer/> </Route>
        <Route exact path="/admin/trash">        <NavBar activeModules = {activeModules}/>       <UserTrashcan/>                                                  <Footer/> </Route>
        <Route exact path="/admin/users">         <NavBar activeModules = {activeModules}/>      <UserAdmin/>                                                     <Footer/> </Route>
        <Route exact path="/admin/newuser">       <NavBar activeModules = {activeModules}/>      <NewUser/>                                                       <Footer/> </Route>
        <Route exact path="/admin/invite">      <NavBar activeModules = {activeModules}/>        <InviteUser/>                                                    <Footer/> </Route>
        <Route exact path="/tags">             <NavBar activeModules = {activeModules}/>          <Tags />                               <Footer/> </Route>
        <Route exact path="/tagcategories">      <NavBar activeModules = {activeModules}/>        <TagCategories />                      <Footer/> </Route>
        <Route exact path="/products">      <NavBar activeModules = {activeModules}/>             <Products />                           <Footer/> </Route>
        <Route exact path="/locations">      <NavBar activeModules = {activeModules}/>             <Locations />                           <Footer/> </Route>

        { activeModules && <AppRoutes activeModules={activeModules}/>}
      </Switch>
    </div>
  )
}


export default SuperAdminRoutes