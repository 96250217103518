import React from 'react'
import './SliderMenu.css'

const SliderMenu = (props) => {
  return(
    <div className={`slider-menu ${props.menuState}`} >
      {props.children}
    </div>
  )
}

export default SliderMenu