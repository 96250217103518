//------------------------------------------------------------------//
//       BodyEdit is taking care of body content of UIcard          //
//------------------------------------------------------------------//

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonTxtIcon from '../../../../../UI/ButtonTxtIcon/ButtonTxtIcon'
import HelpText from '../../../../../UI/HelpText/HelpText'
import { newTagContent } from '../../../reducers/tagsReducer'
import './bodyedit.css'
import Line from './Line'


const BodyEdit = ({ tagId }) => {

  const dispatch =  useDispatch()
  const user = useSelector(state => state.user)
  const tags = useSelector(state => state.tags)

  //--------------------------------//
  //            States              //
  //--------------------------------//

  // state to know which tag is being edited
  const [editItem, setEditItem] = useState({ id: 0 })

  // newItem is false if no data for new content.
  const [newItem, setNewItem] = useState(false)

  // const help = useSelector(state => state.help.show)
  const tag = tags.filter(t => t.id === tagId)[0]


  //------------------------------------------------------------//
  //       update data for TextArea to control height           //
  //       useEffect is triggered by length of text content     //
  //------------------------------------------------------------//

  useEffect(() => {
    newItem === true && setEditItem(tag.content[tag.content.length-1].id)
  },[tag.content.length])

  //--------------------------------------------//
  //            New content handle              //
  //--------------------------------------------//

  const handlerNewContent =(contentType) => {
    const body = {
      data: '',
      sort: contentType,
      company: user.company
    }
    dispatch(newTagContent(user.token, body, tags, tagId))
    setNewItem(true)
  }


  //---------------------------------------------//
  //           T H E    R E T U R N              //
  //---------------------------------------------//

  return(
    <>
      <HelpText
        text='
          Voit lisätä tägin sisltöä sivun alhaalta löytyvistä napeista. Lohkoja voit muokata klikkaamalla niiden päällä.
        '
      />
      {/*----------------------------------------------------|
      |       Mapping content of tag with Line -comonent     |
      |----------------------------------------------------*/}

      {tag.content && tag.content.map((line, index) => {
        return(<div key = {line.id}>
          <Line
            button = {() => setEditItem(line.id)}
            setEditItem = {setEditItem}
            editItem = {editItem}
            line={line}
            index={index}
            setNewItem={setNewItem}
            tag = {tag}
          />
        </div>
        )
      })}

      {/*----------------------------------------------------|
      |       Buttons to add new content into tag            |
      |----------------------------------------------------*/}

      <div className="buttons-row">
        <div onClick={() => handlerNewContent('header')}>
          <ButtonTxtIcon
            className="buttonGreen"
            label="Otsikko"
            icon="D"
          />
        </div>
        <div onClick={() => handlerNewContent('text')}>
          <ButtonTxtIcon
            className="buttonGreen"
            label="Tekstiä"
            icon="D"
          />
        </div>
        <div onClick={() => handlerNewContent('link')}>
          <ButtonTxtIcon
            className="buttonGreen"
            label="Linkki"
            icon="D"
          />
        </div>
        <div onClick={() => handlerNewContent('video')}>
          <ButtonTxtIcon
            className="buttonGreen"
            label="Video"
            icon="D"
          />
        </div>
      </div>
    </>
  )
}

export default BodyEdit