import { setNotification } from '../../../UI/Notification/notificationReducer'
import {
  getTagCategories, postTagCategory, deleteTagCategory, putTagCategory
} from '../services/srvTags'


const tagsCategoriesReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_TAG_CATEGORIES':
    return action.data

  default: return state
  }
}

export const setTagCategories = (token, companyId) => {
  return async dispatch => {
    const tagCategories = await getTagCategories(token, companyId)
    dispatch({
      type: 'SET_TAG_CATEGORIES',
      data: tagCategories
    })
  }
}

export const updateTagCategories = (token, body, tagCategoryId, tagCategories) => {
  return async dispatch => {
    const newItem = await putTagCategory(token, body, tagCategoryId)
    const updatedCategories = tagCategories.map(c => {
      if(c.id === tagCategoryId) return newItem
      else return c
    })
    dispatch({
      type: 'SET_TAG_CATEGORIES',
      data: updatedCategories
    })
  }
}

export const newTagCategory = (token, body, tagCategories) => {
  return async dispatch => {

    // new item from server with correct id
    const newCategory = await postTagCategory(token, body)
    // merge new item to array:
    const updatedCategories = [].concat(tagCategories, newCategory)

    dispatch({
      type: 'SET_TAG_CATEGORIES',
      data: updatedCategories
    })
    setTimeout(() => {dispatch(setNotification({ notification: 'Kategoria on nyt luotu' }))}, 500)
  }
}

export const removeTagCategory = (token, categoryId, tagCategories) => {
  return async dispatch => {
    const deletedItem = await deleteTagCategory(token, categoryId)
    if(deletedItem.status === 200) {
      const updatedTagCategories = tagCategories.filter( c => c.id !== categoryId)
      setTimeout(() => {dispatch(setNotification({ notification: 'Kategoria on nyt poistettu' }))}, 500)
      dispatch({
        type: 'SET_TAG_CATEGORIES',
        data: updatedTagCategories
      })
    } else {
      setTimeout(() => {dispatch(setNotification({ notification: 'Kategorian poistossa tapahtui virhe: ' + deletedItem.statusText }))}, 500)
      dispatch({
        type: 'SET_TAG_CATEGORIES',
        data: tagCategories
      })
    }


  }
}

export default tagsCategoriesReducer