import React from 'react'
import IconButton from '../../../UI/IconButton/IconButton'
import UiCard from '../../../UI/UiCard/UiCard'
import BodyButtons from './BodyButtons'
import BodyComponents from './BodyComponents'


const Item = ({ item, items, setItem, setBodyContainer, bodyContainer, selectedID, setSelectedID }) => {

  return(
    <>
      {/* Back button */}
      { selectedID === item.id &&
            <div className='UIcard-exit-button'>
              <IconButton
                onClick={() => {
                  setSelectedID(null)
                  setBodyContainer(null)
                }}
                className="icon-button-secondary"
                size="20px"
                char="x"
              />
            </div>
      }
      { selectedID === item.id | selectedID === null ?
        <UiCard
          headerTitle = {item.name}
          footerInfo = {'Avainsanat: ' + item.sort}
        >
          <div className="customer-body-container">
            <BodyButtons
              item={item}
              setBodyContainer = {setBodyContainer}
              bodyContainer = {bodyContainer}
              selectedID = {selectedID}
              setSelectedID = {setSelectedID}
            />
            <div className="customer-body-container">
              <BodyComponents
                bodyContainer = {bodyContainer}
                setSelectedID = {setSelectedID}
                selectedID  = {selectedID}
                setBodyContainer  = {setBodyContainer}
                setItem = {setItem}
                items = {items}
                item={item}
              />
            </div>

          </div>

        </UiCard> : null}
    </>
  )
}

export default Item