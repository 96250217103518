import axios from 'axios'
const baseUrl = '/api/signup'

export const signUp = async (invitedUserId, user) => {
  try{
    const response = await axios.post(baseUrl + '/' + invitedUserId, user)
    return response.data

  } catch(err){
    console.log('Error at signUp(): ', err)
  }
}

export const getInvitedUser = async (invitedUserId) => {
  try{
    const response = await axios.get(baseUrl + '/' + invitedUserId)
    return response.data
  } catch(err){
    console.log('Error at signUp(): ', err.response.data.error)
    return err.response.data.error
  }
}