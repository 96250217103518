import axios from 'axios'
// import { newToDo } from '../reducers/todosReducer'

const baseUrl = '/api/todos'

export const getAllToDos = async () => {
  try{
    const toDos = await axios.get(baseUrl)
    return toDos.data
  } catch(err){
    console.log('error at getAllToDos() : ', err)
  }
}

export const createNewToDo = async (todo) => {
  try{
    const newToDo = await axios.post(baseUrl, todo)
    return newToDo
  } catch(err){
    console.log('error at createNewToDo() : ', err)
  }
}

export const updateToDoState = async (todoId) => {
  try{
    await axios.put(baseUrl + '/' + todoId)
  } catch(err){
    console.log('error at updateToDoState() : ', err)
  }
}
