import { msgGetAll, msgGetTopic } from '../MessageServices/msgService'

const initialState = {
  allThreads: [],
  currentThread: [],
  filteredThreads: []
}

const threadsReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'INIT_THREADS':{
    const newState = {
      ...state, allThreads: action.data, filteredThreads: action.data
    }
    return newState
  }
  case 'INIT_CURRENT_THREAD':{
    const newState = {
      ...state, currentThread: action.data
    }
    return newState
  }
  case 'SET_THREAD_FILTER':{
    const newState = {
      ...state, filteredThreads: filterThreads(state.allThreads, action.data)
    }
    return newState
  }

  default: return state
  }
}

export const initializeThreads = (token, company) => {
  return async dispatch => {
    const threads = await msgGetAll(token, company)

    dispatch({
      type: 'INIT_THREADS',
      data: threads
    })
  }
}

export const initializeCurrentThread = (id, token) => {
  return async dispatch => {
    const posts = await msgGetTopic(id, token)

    dispatch({
      type: 'INIT_CURRENT_THREAD',
      data: posts
    })
  }
}

export const setThreadsFilter = (filter) => {
  return{
    type: 'SET_THREAD_FILTER',
    data: filter
  }
}

const filterThreads = (threadList, filter) => {
  if(filter !== ''){
    const f = filter.toLowerCase()
    const filtered = threadList && threadList.filter(t => {
      if(t.posts[0].author.toLowerCase().includes(f)){
        return t
      }
      if(t.posts[0].firstname.toLowerCase().includes(f)){
        return t
      }
      if(t.posts[0].lastname.toLowerCase().includes(f)){
        return t
      }
      if(t.topic.toLowerCase().includes(f)){
        return t
      }
      return null
    })
    return filtered
  } else{
    return threadList
  }
}

export default threadsReducer