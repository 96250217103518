import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import MsgFooter from './MsgFooter/MsgFooter'
// import MsgTopics from './MsgTopics'
// import MsgPosts from './MsgPosts'
import './Msg.css'
import { initializeThreads } from '../MessageReducers/threadsReducer'
import ThreadList from './ThreadList/ThreadList'
import ThreadsUI from './ThreadsUI/ThreadsUI'
import Title from '../../../components/UI/Title/Title'

const Msg = () => {
  const token = useSelector(state => state.user && state.user.token)
  const company = useSelector(state => state.user && state.user.company)


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeThreads(token, company))
  }, [token, dispatch])

  return(

    <div className="container">
      <ThreadsUI />
      <Title text="Keskustelu" icon="e"/>
      <ThreadList/>
    </div>

  )
}

export default Msg



