import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateOption from './DateOption'
import { updateCard } from '../reducers/cardsReducer'
import HelpPop from '../../../components/UI/HelpPop/HelpPop'
import Button from '../../../components/UI/Button/Button'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import HelpText from '../../../components/UI/HelpText/HelpText'
import TextArea from '../../../components/UI/TextArea/TextArea'
const onlyNumbers = (value) => { return /^\d*\.?\d*$/.test(value) }   // to check is number

const GiftCardEditHolder = ({ card, setDeleted, giftNavigate, setButtonStyleEdit, setIdSelected }) => {

  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)
  const productsData = useSelector(state => state.products)
  const user = useSelector(state => state.user)
  // const theme = useSelector(state => state.theme)
  const payingMethods = cards.cards_payingMethods
  const locations = useSelector(state => state.locations)

  // ------ S T A T E S ----------------------------------

  const [newCustomerName, setNewCustomerName] = useState(card.customerName)
  const [newPayingMethod, setNewPayingMethod] = useState(card.payingMethod)
  // const [newSeller, setNewSeller] = useState(seller)
  const [newReceipt, setNewReceipt] = useState(card.receipt)
  const [newInfo, setNewInfo] = useState(card.info)
  const [newAmount, setNewAmount] = useState(card.amount)
  const [newProduct, setNewProduct] = useState(card.product)
  const [newProductAmount, setNewProductAmount] = useState(card.productAmount)
  const [newCreatedDay, setNewCreatedDay] = useState(card.createdDate.slice(8,10))
  const [newCreatedMonth, setNewCreatedMonth] = useState(card.createdDate.slice(5,7))
  const [newCreatedYear, setNewCreatedYear] = useState(card.createdDate.slice(0,4))
  const [newExpiryDay, setNewExpiryDay] = useState(card.expiryDate.slice(8,10))
  const [newExpiryMonth, setNewExpiryMonth] = useState(card.expiryDate.slice(5,7))
  const [newExpiryYear, setNewExpiryYear] = useState(card.expiryDate.slice(0,4))
  const [newCardType, setNewCardType] = useState(card.cardType)
  const [productToAmount, setProductToAmount] = useState(false)
  const [newSalesValue, setNewSalesValue] = useState(card.salesValue)
  const [newEmail, setNewEmail] = useState(card.email)
  const [newLocation, setNewLocation] = useState(card.location)
  let holderStyle = { opacity:  '1' }

  // ------ H A N D L E R S ----------------------------------

  // Deactivate Card handler

  const handleDeactivateCard = (event) => {

    event.preventDefault()
    const cardObject = { active: false }
    dispatch(updateCard(user.token, cardObject, card.id, cards ))  // update cards
    giftNavigate(0)
    setButtonStyleEdit('defaultButton')
    setDeleted(true)

    // pop notification
    dispatch(setNotification({
      notification: `Kortti numero ${card.num} on poistettu. Tarvittaessa voit palauttaa sen roskiksesta`,
      type: 'default' }))
  }

  // Edit Card handler
  const editCardDataHandler = (event) => {
    event.preventDefault()
    // if location is changed drop products to be just amount

    let product = newProduct
    if(productToAmount === true ){ product = 'ei tuotetta'}
    else {product = newProduct}
    if(newLocation !== card.location){product = 'ei tuotetta'}

    // if product removed, only amount is used
    let productAmount = newProductAmount
    if(productToAmount === true) {productAmount = ''}
    else productAmount = newProductAmount
    if(newLocation !== card.location){productAmount = ''}


    // define card object
    const cardObject =
    {
      cardType: newCardType,
      payingMethod: newPayingMethod,
      email: newEmail,
      customerName: newCustomerName,
      seller: newPayingMethod.methodName,
      amount: newAmount,
      salesValue: newSalesValue,
      info: newInfo,
      location:  newLocation,
      receipt: newReceipt,
      product: product,
      productAmount: productAmount,
      createdDate: (new Date(newCreatedYear + '-' + newCreatedMonth + '-' + newCreatedDay + card.createdDate.slice(10,24))).toISOString(),
      expiryDate: (new Date(newExpiryYear + '-' + newExpiryMonth + '-' + newExpiryDay + card.expiryDate.slice(10,24))).toISOString()

    }
    const updatedCard = updateCard(user.token, cardObject, card.id, cards )

    dispatch(updatedCard)  // update cards
    giftNavigate(0)  // back to "front page"
    setIdSelected('') // release card selection

    // pop notification about update
    dispatch(setNotification({
      notification: `Kortti numero ${card.num} on päivitetty`,
      type: 'default' }))
    setButtonStyleEdit('defaultButton')
  }

  const setNewAmountHandler = (event) => {
  // replace comma to dot
    const eventEdited = event.target.value.replace(/,/g, '.')

    // amount imput is possible to fill, if product / product amount has no value
    if(newProduct === 'ei tuotetta' && newProductAmount === '' ){
      onlyNumbers(eventEdited) && setNewAmount(eventEdited)
    } else {

      // if product is choosen amount is autofilled
      const productValue = productsData.filter(x => x.productName === newProduct)[0].productValue
      setNewAmount(newProductAmount * productValue)
    }
  }

  const setNewProductHandler = (event) => {
    setNewProduct(event.target.value)
    // setProduct(event.target.value)
    // product prize is overriding amount-input if product and amount of products is choosen
    if(event.target.value !== 'ei tuotetta' && newProductAmount !== ''){
      const productValue = productsData.filter(x => x.productName === event.target.value)[0].productValue
      setNewAmount(newProductAmount * productValue)}
  }

  const setNewProductAmountHandler = (event) => {
    setNewProductAmount(event.target.value)
    // setProductAmount(event.target.value)
    // product prize is overriding amount-input if product and amount of products is choosen
    if(newProduct !== 'ei tuotetta' && event.target.value !== ''){
      const productValue = productsData.filter(x => x.productName === newProduct)[0].productValue
      setNewAmount(event.target.value * productValue)}
  }

  const setNewSalesValueHandler = (event) => {
    setNewSalesValue(event.target.value)
  }

  const ListOfProducts = ({ newProduct }) => {
    return(
      <>
        <option value={newProduct}>{newProduct}</option>
        {productsData && productsData.filter(product => product.id === card.location).map(x => <option key={x.id} value={x.productName}>{x.productName}</option>)}
        <option value="ei tuotetta">ei tuotetta</option>
      </>
    )
  }
  const handlerPayingMethod = (event) => {
    const currentPayingMethod = payingMethods.filter(m => m.id ===event.target.value )[0]
    setNewPayingMethod(currentPayingMethod)
  }

  /* ----------------- RETURN THE HOLDER ----------------------*/

  return (
    <div>
      <div>
        {/* Title */}
        <h4 className="body-header">Muokkaa korttia</h4>
        <HelpText text="Täällä voit muokata kortin tietoja"/>
        {card.active === true &&
        <div className="giftcard-body-container" style={holderStyle}>

          <form onSubmit={editCardDataHandler}>

            {/* Location */}
            <div className=" input-group">
              <label>Toimipiste { card.productAmount && '(vaihtaminen muuttaa kortin summalle)'}</label>
              <select onChange={(e) => setNewLocation(e.target.value)}>
                <option value = {newLocation}>{newLocation && locations.filter(loc => loc.id === newLocation)[0].name}</option>
                {locations.map( loc => {
                  return(
                    <option key = {loc.id} value={loc.id}>{loc.name}</option>
                  )})}
              </select>
              {/* name */}
              <div className="input-group">
                <label>Asiakkaan nimi</label>
                <input value={newCustomerName} type="name" onChange={(event) => setNewCustomerName(event.target.value)}/>
              </div>
            </div>

            {/* email */}

            <div className=" input-group">
              <label>E-mail</label>
              <input value={newEmail} onChange={(event) => setNewEmail(event.target.value)}/>
            </div>

            {/* PayingMethod */}

            <div className=" input-group">
              <label>Maksutapa</label>
              <select onChange={handlerPayingMethod}>
                <option value={newPayingMethod.id}>{newPayingMethod.methodName}</option>
                { payingMethods.map(method =>
                  <option key = {method.id} value={method.id}>{method.methodName}</option>
                )}
              </select>
            </div>

            {/* Kuitti: */}

            <div className=" input-group">
              <label>Kuitti</label>
              <input value={newReceipt} onChange={(event) => setNewReceipt(event.target.value)}/>
            </div>

            {/* Arvoa kortilla € */}

            <div className=" input-group">
              <label>Arvoa kortilla €:
                <HelpPop
                  text="Kortin alkuperäinen arvo asiakkaalle. Jos tuote on valitty, arvo on laskettu automaattisesti, eikä sitä voi muuttaa tästä"
                />
              </label>


              { newProductAmount > 0 && <> {newAmount} €</>}
              { newProductAmount < 1 && <input value={newAmount} onChange={setNewAmountHandler}/>}

            </div>



            {/* Myyntihinta € */}

            <div className=" input-group">
              <label>Myyntihinta €
                <HelpPop
                  text="Hinta, jolla tuote on myyty, esim. alennuksella. Kirjanpitoon merkittävä arvo."
                />
              </label>
              <input
                value={newSalesValue}
                onChange={setNewSalesValueHandler}/>
            </div>



            {/* Määrä x Tuote */}

            { (card.usage.length === 0) ?

              <div className=" input-group">
                <div className='flex-row' style={{ justifyContent: 'flex-start' }}>
                  <label>Tuote
                    <HelpPop text = "Tuotteen hinnan on täsmättävä kokonaissummaan"/>
                  </label>
                </div>
                <div className='flex-row' style={{ justifyContent: 'flex-start' }}>
                  <input className="input-amount" type="number" value={newProductAmount} onChange={setNewProductAmountHandler}/> X
                  <select className="input-product" style={{ marginLeft: '10px' }} onChange={setNewProductHandler}>
                    <ListOfProducts
                      newProduct = {newProduct}
                    />
                  </select>
                </div>
              </div>
              :
              <div className=" input-group">
                <label>Tuote</label>
                Tuotetta ei voi enää valita, koska korttia on jo käytetty
              </div>

            }
            { (card.productAmount > 0) &&
            <div className=" input-group">

              <label>Muuta summaksi
                {card.usage.length > 0
                  ? <HelpPop text="Voit muuttaa känytikerrat summaksi, mutta sitä ei voi perua." position = "right"/>
                  : <HelpPop text="Voit muuttaa känytikerrat summaksi. Toiminto voidaan perua, kunnes korttia käytetään" position = "right"/>
                }
              </label>


              <input type="checkbox" className="checkbox-card-edit" onClick={() => { productToAmount === false ? setProductToAmount(true) : setProductToAmount(false)}}/>
              {productToAmount === true &&  <> muutetaan: {card.amount - card.totalUsage} / {card.amount} </> }
            </div>

            }

            {/* Tyyppi */}

            <div className=" input-group">
              <label>Tyyppi</label>
              <select className="inputThin" onChange={(event) => setNewCardType(event.target.value)}>
                <option value={card.cardType}>{card.cardType}</option>
                <option value="Lahjakortti">Lahjakortti</option>
                <option value="Sarjakortti">Sarjakortti</option>
              </select>
            </div>

            {/* Created */}

            <div className=" input-group">
              <label>Luotu: (pv/kk/vuosi)</label>

              <div style={{ display:'flex', width: '100%' }}>

                {/* day */}
                <select className = 'customer-form-date-of-year theme'
                  onChange={(event) => {setNewCreatedDay(event.target.value)}} >
                  <option
                    value = {newCreatedDay}>
                    {newCreatedDay}
                  </option>
                  <DateOption amount="31" offset="1"/>
                </select>

                {/* month */}
                <select className = 'customer-form-month-of-year theme' onChange={(event) => {setNewCreatedMonth(event.target.value)}}  >
                  <option

                    value = {newCreatedMonth}>
                    {newCreatedMonth}
                  </option>
                  <DateOption amount="12" offset="1"/>
                </select>

                {/* year */}
                <select className = 'customer-form-year-of-year theme' onChange={(event) => {setNewCreatedYear(event.target.value)}}  >
                  <option

                    value = {newCreatedYear}>
                    {newCreatedYear}
                  </option>
                  <DateOption amount="15" offset={(new Date()).getFullYear()-10}/>
                </select>
              </div>

            </div>

            <div className=" input-group">


              <label>Voimassa: (pv/kk/vuosi)</label>


              <div style={{ display:'flex', width: '100%' }}>

                {/* day */}
                <select className = 'customer-form-date-of-year'  onChange={(event) => {setNewExpiryDay(event.target.value)}}>
                  <option

                    value = {newExpiryDay} >
                    {newExpiryDay}
                  </option>
                  <DateOption amount="31" offset="1"/>
                </select>

                {/* month */}
                <select className = 'customer-form-month-of-year theme' onChange={(event) => {setNewExpiryMonth(event.target.value)}}  >
                  <option

                    value = {newExpiryMonth}>
                    {newExpiryMonth}
                  </option>
                  <DateOption amount="12" offset="1"/>
                </select>

                {/* year */}
                <select className = 'customer-form-year-of-year theme' onChange={(event) => {setNewExpiryYear(event.target.value)}} >
                  <option

                    value = {newExpiryYear}>
                    {newExpiryYear}
                  </option>
                  <DateOption amount="10" offset={(new Date()).getFullYear()-3}/>
                </select>
              </div>
            </div>



            <div className=" input-group">
              <label>Lisätiedot:</label>
              <TextArea
                onChange={(event) => setNewInfo(event.target.value)}
                value = {newInfo}
                trickers ={[newInfo]}
              />
            </div>

            <div className="buttons-row mp-mt-40">

              <Button
                className = "buttonGreen"
                label = 'Tallenna'
                icon = 'update'
              />
              <div onClick={ () => {giftNavigate(0); setIdSelected('') }}>
                <Button
                  label='Peruuta'
                  icon='undo'
                />
              </div>



              { (card.totalUsage === 0 || card.customerName === 'testi') &&

                <Button
                  onClick={handleDeactivateCard}
                  icon='bin'
                  className = "buttonAlert"
                  label = "Poista kortti"
                />

              }

            </div>
          </form>

        </div>



        }
      </div>
    </div>
  )

}
export default GiftCardEditHolder
