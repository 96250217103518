/* eslint-disable no-unexpected-multiline */
const filters = (customers, searchWord) => {

  let showCards
  if( customers && Array.isArray(customers) === true ) {
  // ------------ FILTERS --------------------------------------
    showCards = customers.filter
    (
      x => (x.firstName && x.firstName.toString().includes(searchWord)) // by firstName
        || (`${x.firstName} ${x.lastName}`.toLowerCase().includes(searchWord.toLowerCase()))  // by first amd lastName
        || (x.lastName && x.lastName.toLowerCase().includes(searchWord.toLowerCase()))  // by lastName
        || (x.phone && x.phone.toLowerCase().includes(searchWord.toLowerCase())) // by phone
    )
  }
  return showCards
}

export default filters