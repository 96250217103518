import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonTxtIcon from '../../../UI/ButtonTxtIcon/ButtonTxtIcon'
import HelpText from '../../../UI/HelpText/HelpText'
import IconButton from '../../../UI/IconButton/IconButton'
import { setNotification } from '../../../UI/Notification/notificationReducer'
import { delete_product, put_product } from '../reducers/productsReducer'




const Item = ({ item }) => {
  const dispatch =  useDispatch()
  const help = useSelector(state => state.help.show)
  const user = useSelector(state => state.user)
  const items = useSelector(state => state.products)
  const locations = useSelector(state => state.locations)

  const handleDelete =  () => {
    dispatch(setNotification({
      notification: 'Ookkonää ny iha varma?',
      type: 'modal',
      onClickConfirm: () => dispatch(delete_product(user.token, items, item.id )),
      onClickBack: () => console.log('delete cancelled')
    }))
  }

  const DataInput = ({ title, data, name }) => {

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    const handlerUpdateClient = (event) => {
      event.preventDefault()
      // close input
      setOpenform(false)
      //  bracket notation, so only modified key is updated
      const object = {
        [name]: newData }
      // token, body, products, productId
      dispatch(put_product(user.token, object, items, item.id  ))
    }
    // -------- delete handle -------------------





    return(
      <>
        <div className="card-list even-odd">
          <div className="flex-container-info">{title}</div>
          { openForm === false ?
            <>
              <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{data}</div>
              {name && help === true &&
          <div className="flex-item-right align-items-end">
            <IconButton
              onClick={() => { setOpenform(true)}}
              className="icon-button-secondary"
              char="L"
              text="Muokkaa"
              size="30px"
            />
          </div>}
            </>
            :
            <form onSubmit={handlerUpdateClient} className="form-data-line">
              <div className="flex-item-right">
                <input
                  className="passed"
                  placeholder={data}
                  value = {newData}
                  onChange={(e) => {setNewData(e.target.value)}}
                />
              </div>
              {name &&
          <>
            <div className="flex-item-right align-items-end">
              <IconButton
                tupe="submit"
                className="icon-button-main"
                char="u"
                size="30px"
                text="OK"
              />
            </div>
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(false)}}
                className="icon-button-gray"
                char="X"
                text="Peru"
                size="30px"
              />
            </div>
          </>
              }
            </form>
          }
        </div>
      </>
    )
  }
  const DataSelect = ({ title, data, name }) => {

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    const handlerUpdateClient = (event) => {
      event.preventDefault()
      // close input
      setOpenform(false)
      //  bracket notation, so only modified key is updated
      const object = {
        [name]: newData }
      // token, body, products, productId
      dispatch(put_product(user.token, object, items, item.id  ))
    }
    // -------- delete handle -------------------





    return(
      <>
        <div className="card-list even-odd">
          <div className="flex-container-info">{title}</div>
          { openForm === false ?
            <>
              <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>
                {data && locations.filter( loc => loc.id === data)[0].name}
              </div>
              {name && help === true &&
          <div className="flex-item-right align-items-end">
            <IconButton
              onClick={() => { setOpenform(true)}}
              className="icon-button-secondary"
              char="L"
              text="Muokkaa"
              size="30px"
            />
          </div>}
            </>
            :
            <form onSubmit={handlerUpdateClient} className="form-data-line">
              <div className="flex-item-right">
                <select onChange={(e) => {setNewData(e.target.value)}}>
                  <option value={data}>{data && locations.filter( loc => loc.id === data)[0].name}</option>
                  { locations.map(loc => {
                    return(
                      <option key={loc.id} value = {loc.id}>{loc.name}</option>
                    )
                  })

                  }
                </select>
              </div>
              {name &&
              <>
                <div className="flex-item-right align-items-end">
                  <IconButton
                    type="submit"
                    className="icon-button-main"
                    char="u"
                    size="30px"
                    text="OK"
                  />
                </div>
                <div className="flex-item-right align-items-end">
                  <IconButton
                    onClick={() => { setOpenform(false)}}
                    className="icon-button-gray"
                    char="X"
                    text="Peru"
                    size="30px"
                  />
                </div>
              </>
              }
            </form>
          }
        </div>
      </>
    )
  }

  return(
    <div>
      <HelpText
        text='
          Voit muokata tagin kategoriaa täälllä. Avainsanat on tarkoitettu tarkentamaan kategoriaa ja helpottamaan hakutoimitoa.
        '
      />
      <DataInput title = "Tuotteen nimi"   data = {item.productName}    name = "productName"/>
      <DataInput title = "Hinte (€)"       data = {item.productValue}   name = "productValue"/>
      <DataInput title = "Kesto (min.)"    data = {item.duration}       name = "duration"/>
      <DataInput title = "Kategoria"       data = {item.category}       name = "category"/>
      <DataInput title = "Kuvaus"          data = {item.description}    name = "description"/>
      <DataSelect title = "Toimipaikka"     data = {item.locationId}       name = "locationId"/>
      <ButtonTxtIcon
        label = "Poista tuote"
        className = "buttonRed mp-ml-20"
        onClick = {handleDelete}
        icon = "M"
      />
    </div>
  )
}

export default Item