import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getOneCustomer } from '../../reducers/rdc-oneCustomer'
import './customerRaport.css'
import Line from './Line'
// import Line from './Line'


const CustomerRaport = () => {

  // lets find out customer id -> split href to array by '/'
  let customerId = window.location.href.split('/')
  // take the last item from array
  customerId = customerId[customerId.length - 1]

  const token = useSelector(state => state.user.token)
  const customer = useSelector(state => state.oneCustomer)
  const company = useSelector(state => state.companies[0])

  const dispatch = useDispatch()

  useEffect( () => { dispatch(getOneCustomer( token, customerId))}, [])
  const history = useHistory()


  return(
    <div className = "customerRaport-container hover-pointer" onClick={() => {history.push('/customers/')}}>
      <span className="customerRaport-back">E</span>
      <h1>Asiakasraportti</h1>

      <div className = "customerRaport-data">
        <div><b>Yritys:</b> {company.companyName}</div>
        <div><b>Osoite:</b> {company.street}, {company.postNro}, {company.city}</div>
        <div><b>Puh.</b> {company.tel}, <b>y-tunnus:</b> {company.vat}</div>
        <div style={{ marginTop: '0.5rem' }}><b>Asiakkaan tiedot</b></div>
        <div><b>Asiakkaan nimi:</b> {customer.firstName} {customer.lastName}</div>
        <div><b>Syntymäaika:</b> {customer.birthDate}</div>
        <div><b>Sosiaaliturvatunnus loppuosa:</b> {customer.sosId}</div>
      </div>

      <h2>Tapahtumat</h2>
      {customer && customer.appointments.map(app => {
        return(
          <div key = {app.id}>
            <Line app = {app} />
          </div>
        )
      })

      }
    </div>
  )
}
export default CustomerRaport