import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImageListItem from './ImageListItem/ImageListItem'
import { removeImage } from '../../../../MessageReducers/newPostReducer'
import './Images.css'

const Images = () => {

  const postImages = useSelector(state => state.newPost.images)
  const dispatch = useDispatch()
  const [images, setImages] = useState([1])

  useEffect(() => {
    const removeImageFromList = (image) => {
      dispatch(removeImage(image))
    }
    const newImageList = postImages.map(img => <ImageListItem
      image={img}
      remove={removeImageFromList}
      key={Math.random() * 9999999 }
    />)
    setImages(newImageList)
  }, [postImages, dispatch])

  return(
    <>
      {images && images.length > 0 && <h5 style={{ margin:' 15px' }}>Lähetä seuraavat kuvat:</h5>}
      <div id="attached-image-list">
        {images}
      </div>
    </>
  )
}

export default Images