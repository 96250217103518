import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCardUsage } from '../reducers/cardsReducer'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import DayAndTime from '../../../functions/DayAndTime'
import Item_TitleAndData from '../../../components/UI/Item_TitleAndData/Item_TitleAndData'
import Button from '../../../components/UI/Button/Button'
import IconButton from '../../../components/UI/IconButton/IconButton'

let confirmTxt = ''

const GiftCardHistoryHolder = ({ card, setEmptyFilter, setIdSelected, giftNavigate }) => {
  const dispatch = useDispatch()

  // local states
  const [usageToDelete, setUsageToDelete] = useState(false)

  // selectors
  const cards = useSelector(state => state.cards)
  const token = useSelector(state => state.user.token)

  // if no use, echo not used yet
  let message = ''
  if(card.usage.length === 0){ message = <p className="card-list even-odd" style={{ marginBottom: '30px' }}>Korttia ei ole vielä käytetty</p> }

  // delete usage handle
  const toTrachHandle = async () => {

    // export const deleteCardUsage = (token, usageId, cards, cardId) => {
    const newCardsData = deleteCardUsage(token, usageToDelete[0], cards, card.id)
    dispatch(newCardsData)  // update cards to DB
    giftNavigate(0)
    setTimeout( () => {
      dispatch(setNotification({
        notification:  `Kortin nro ${card.num} käyttö on palautettu onnistuneesti`,
        type: 'default' }))}, 500)
    setEmptyFilter(false)

    setIdSelected('')

    // setTimeout(() => { setPing(new Date())}, 1000)
  }
  confirmTxt = `Vahvista palautus ( nro ${usageToDelete[1] + 1} ) `


  return(
    <div>
      {/* Title */}
      <h4 className="body-header">Kortin käyttöhistoria</h4>
      <div className="giftcard-body-container-noPadding" style={{ opacity:  '1' }}>

        <div style={{ marginTop: '1em', marginBottom: '30px' }} >
          {card.usage.map( (x,i) =>

            <div key = {x.id} className="even-odd">
              <Item_TitleAndData
                title = {i+1}
                data = {<DayAndTime date = {x.usageDate} />}
                data2 = {x.amount + ' €'}
                data3 = {x.worker}
                data4 = {
                  <IconButton
                    onClick={() => {
                      // usage id for usageToDelete Button
                      setUsageToDelete([x.id, i])
                    }
                    }
                    className="icon-button-gray"
                    size= "30px"
                    char="M"
                  />
                }
              />
            </div>

          )}

          {usageToDelete &&
        <div className = "buttons-row"><div className="giftcard-body-container">
          <Button
            className="buttonRed"
            onClick={() => toTrachHandle()}
            label={confirmTxt} />
        </div><div className="giftcard-body-container">
          <Button
            className="default"
            onClick={() => setUsageToDelete(false)}
            label='Peruuta' />
        </div></div>
          }

          {message}
        </div>
      </div>
    </div>
  )}


export default GiftCardHistoryHolder

