import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { initializeTrashcan } from '../../../../reducers/userTrashcanReducer'
import { unDeleteUser } from '../../../../services/users'
import dayjs from 'dayjs'
import Title from '../../../UI/Title/Title'
import './UserTrashcan.css'
import AuthorCircle from '../../../UI/AuthorCircle/AuthorCircle'
import Button from '../../../UI/Button/Button'
import langs from '../../../../langs'
import { setNotification } from '../../../UI/Notification/notificationReducer'


const UserTrashcan = () => {

  const trans = langs.lang
  const lN = useSelector(state => state.user.language)
  const dispatch = useDispatch()
  const token = useSelector(state => state.user && state.user.token)
  const company = useSelector(state => state.user && state.user.company)
  const deletedUsers = useSelector(state => state.deletedUsers)
  const userRole = useSelector(state => state.user.role)

  useEffect(() => {
    dispatch(initializeTrashcan(token, company, userRole))
  }, [dispatch, token])

  const unDelete = async (id) => {
    await unDeleteUser(token, id)
    dispatch(initializeTrashcan(token, company))
    dispatch(setNotification({ notification: 'Käyttäjä palautettu!' }))
  }
  const displayUsers = deletedUsers && deletedUsers.map(u => {
    return(


      <div key={u.id}  className="user-trashcan-item container">
        <div className="user-trashcan-acircle"><AuthorCircle firstName={u.firstname} lastName={u.lastname}/></div>
        <h4 className="user-trashcan-item-name">{u.firstname} {u.lastname} </h4>
        <div className="user-trashcan-item-user">{u.username}</div>
        <div className="user-trashcan-deleted">poistettu:</div>
        <div className="user-trashcan-item-date"> {dayjs(u.deletedDate).format('DD.MM.YY')}</div>
        <div className="user-trashcan-item-button">
          <Button
            type="buttonGreen"
            onClick={ () => unDelete(u.id) }
            icon="undo"
            label="Palauta"
          />
        </div>
      </div>

    )
  })

  return(
    <>
      <Title text= {trans.removedUsers[lN]} icon="bin"/>


      {displayUsers && displayUsers}

    </>
  )
}

export default UserTrashcan