import { s3ImageUpload } from '../services/s3ImageUpload'

const s3ImageUploadReducer = (state = null, action) => {
  switch(action.type){
  case 'UPLOAD_S3_IMAGE':
    return action.data

  default: return state
  }
}

export const s3uploadImage = (file) => {
  return async dispatch => {
    const image = await s3ImageUpload(file)
    dispatch({
      type: 'UPLOAD_S3_IMAGE',
      data: image
    })
  }
}


export default s3ImageUploadReducer