import React from 'react'
import './SimpleModal.css'
import Button from '../Button/Button'

const Modal = ({ title, message, onClickConfirm, onClickCancel }) => {
  return(
    <div className="modal-bg">
      <div id="modal">
        <div className="modal-body">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
        <div className="btn-footer">
          <Button type="buttonGreen" label="OK" icon="update"  onClick={onClickConfirm}/>
          <Button type="buttonCancel" label="peruuta"  icon="undo" onClick={onClickCancel}/>
          {/* <button className="modal-button modal-confirm-button" onClick={onClickConfirm}>OK</button>
          <button className="modal-button modal-cancel-button" onClick={handleCancel}>Peruuta</button> */}
        </div>
      </div>
    </div>
  )
}

export default Modal
