import React from 'react'
import filters from './filters'
import Body_NewCustomer from '../AppBody/BodyComponents/Body_NewCustomer/Body_NewCustomer'
import UiCard from '../../../../components/UI/UiCard/UiCard'
import Title from '../../../../components/UI/Title/Title'
import HelpText from '../../../../components/UI/HelpText/HelpText'
import IconButton from '../../../../components/UI/IconButton/IconButton'
const AppHeader = ({ searchWord, setSearchWord, selectedID, customers, setFilterdCustomers, setBodyContainer, bodyContainer }) => {


  // -------- handler to apply filters from search form ----------------
  const handleFilter = (event) => {
    setSearchWord(event.target.value.toString())
    setFilterdCustomers(filters(customers, event.target.value.toString()))
  }
  // ----------------------- RETURN PAGE ------------------------------
  return(
    <>
      {
        selectedID === null && bodyContainer !== '0'
          &&
          <><div className='add-item-search-top-bar'>

            {/* ------------- BUTTON - add new appointment  ----------------- */}

            <IconButton
              char="j"
              className="icon-button-main"
              onClick={() => { setBodyContainer('0') } } />

            {/* -------------------- SEARCH INPUT  ------------------ */}
            <input className = 'input-cardholder-find theme' value={searchWord} onChange={handleFilter} placeholder='etsi asiakas' />


            {/* Clear search button */}

            {searchWord !== '' &&
            <IconButton
              char="x"
              size="30px"
              className="icon-button-gray"
              onClick={() => {
                setSearchWord('')
                setFilterdCustomers(customers, '')
              } } />}


          </div><Title text="Asiakkaat" icon="w" />
          <HelpText text= {<>Tarkastele ja muokkaa asiakaskirjauksia. <br/> Kirjauksiin on mahdollista lisätä tageja. Seuranta on kirjauksesta erillinen toiminto.</>}/>

          </>
      }

      {/* -------------FORM to add new customer -------------------*/}
      {
        bodyContainer === '0' &&
          <div>
            <div className='space-30'/>
            <UiCard
              headerTitle = "Lisää uusi asiakas"
              headerInfo = ""
              footerInfo = "joku ohje?"
            >
              <Body_NewCustomer setFilterdCustomers = {setFilterdCustomers} setBodyContainer = {setBodyContainer} customers = {customers} setSearchWord={setSearchWord}/>
            </UiCard>
          </div>
      }

    </>

  )
}
export default AppHeader