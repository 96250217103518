import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UiCard from '../../../../../components/UI/UiCard/UiCard'
import Button from '../../../../../components/UI/Button/Button'
import HoverPlate from '../../../../../components/UI/HoverPlate/HoverPlate'
import Wrapper from '../../../../../components/UI/Wrapper/Wrapper'
import { addNewCardPayingMethod, deleteCardPayingMethod } from '../../../reducers/cardsReducer'
import Loader from '../../../../../components/UI/Loader/Loader'
import './payingMethods.css'

const PayingMethodSettings = () => {

  const dispatch = useDispatch()
  const cards = useSelector(state =>  state.cards)
  const payingMethods = useSelector(state =>  cards && state.cards.cards_payingMethods)
  const accountId = useSelector(state =>   cards && state.cards.id)
  const token = useSelector(state =>  state.user.token)
  const [newMethodName, setNewMethodName] = useState('')
  const [newIsPersonal, setNewIsPersonal] = useState(false)
  const [newNeedReceipt, setNewNeedReceipt] = useState(false)

  let currentPayingMethods = <Loader />

  // ------------- Handlers ----------------------------->

  // new paying method
  const handleSubmit = async (event) => {
    event.preventDefault()
    const cardObject = {
      methodName: newMethodName,
      isPersonal: newIsPersonal,
      needReceipt: newNeedReceipt
    }
    dispatch(addNewCardPayingMethod(token, accountId, cardObject ))  // update cards to DB
    setNewMethodName('')
    setNewNeedReceipt(false)
    setNewNeedReceipt(false)
  }

  // delete paying method
  const handleDeletePayingMethd = async (event) => {
    const buttonId = event.target.id
    dispatch(deleteCardPayingMethod(token, accountId, buttonId))
  }

  // is personal
  const handleIsPersonal = () => {
    newIsPersonal === true ?  setNewIsPersonal(false) : setNewIsPersonal(true)
  }

  // need receipt
  const handleneedReceipt = () => {
    newNeedReceipt === true ?  setNewNeedReceipt(false) : setNewNeedReceipt(true)
  }



  if(payingMethods){
    currentPayingMethods = payingMethods.map((m) => {

      const personal = (v) => { if(v === true) return ' 👤'; else return ''}
      const receipt = (v) => { if(v === true) return ' 📌'; else return ''}
      return (
        <div key={m.id} style={{ position: 'relative' }}>
          <HoverPlate key={`hover-plate-${m.id}`} deleteHandler={handleDeletePayingMethd} id={m.id}>
            <Button type = 'hotButton' className="hotButton" label={m.methodName + personal(m.isPersonal) + receipt(m.needReceipt)} key={m.id}/>

          </HoverPlate>
        </div>
      )
    })}

  return (
    <UiCard
      footerTitle = "Ohje"
      footerInfo = "Lisäämällä maksutavat, voit hallinnoida vaatiiko kortti kuitin numeron, ja onko kortti käyttäjälle henkilökohtainen"
      headerTitle = 'Maksutapojen hallinta'
    >
      <div className = "giftcard-body-container">
        <h4>Luodut maksutavat:</h4>
        <div style = {{ height: 40 }}/>
        <Wrapper>
          {currentPayingMethods}
        </Wrapper>

        <h4>Lisää uusi maksutapa</h4>
        <form onSubmit={handleSubmit} className="flex-column-container" style={{ marginTop: 0, paddingTop: 0 }}>

          {/* Methods Name  */}
          <div className="flex-container">
            <div className="flex-item-left">
               Maksutavan nimi
            </div>
            <div className="flex-item-right">
              <input
                value={newMethodName}
                onChange={(event) => setNewMethodName(event.target.value)}
                className = 'passed'
              />
            </div>
          </div>


          {/* isPersonal */}
          <div className="payMeth-input-group" >
            <input checked={ newIsPersonal === true ? true : ''} readOnly = {true}
              id="isPersonal"           name="isPersonal"       data-testid="isPersonal"
              type="checkbox"         value="isPersonal"        onClick={handleIsPersonal}
            />
            <label htmlFor="GiftCard">👤 Kortti on henkilökohtainen</label>
          </div>


          {/* needReceipt */}
          <div className="payMeth-input-group">
            <input checked={ newNeedReceipt === true ? true : ''} readOnly = {true}
              id="needReceipt"           name="needReceipt"       data-testid="needReceipt"
              type="checkbox"            value="needReceipt"      onClick={handleneedReceipt}
            />
            <label htmlFor="GiftCard">📌 Kutin numero vaaditaan</label>
          </div>

          <div >
            <Button icon = 'add' label = 'Lisää uusi maksutapa' type = "buttonGreen" />
          </div>

        </form>
      </div>
    </UiCard>
  )
}

export default PayingMethodSettings