import React, { useState } from 'react'
import UiCard from '../../../../../components/UI/UiCard/UiCard'
import Loader from '../../../../../components/UI/Loader/Loader'
import { updateAccount } from '../../../reducers/cardsReducer'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../components/UI/Button/Button'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'

const ExpirySettings = () => {

  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)

  // local states

  const [newExpTime, setNewExpTime] = useState(cards ? cards.expiryTime : null)
  const token = useSelector(state => state.user.token)
  const accountId = cards ? cards.id : null
  // update halndler

  const updateExpTimeHandler = (event) => {
    event.preventDefault()
    const object = { expiryTime: newExpTime }
    dispatch(updateAccount(token, accountId, object))  // update cards to DB
    dispatch(setNotification({
      notification: 'Voimassaoloaika on päivitetty!',
      type: 'default' }))

  }
  return(
    newExpTime !== undefined ?
      <>
        <UiCard
          footerTitle="Ohje"
          footerInfo="Syötä voimassaoloaika täysinä kuukausina kenttään ja paina 'tallenna'."
          headerTitle = {`Korttien voimassaoloaika ( ${newExpTime} kk )`}
        >

          <div className="giftcard-body-container">
            <p>Luotavat lahjakortit ovat voimassa (kk), muutos vaikutta ainoastaa uusiin kortteihin.</p>

            <form className="flex-row-container">
              <div>
                <input
                  type="number"
                  name="expiryTime"
                  value={newExpTime}
                  onChange={ (event) => setNewExpTime(event.target.value)}
                  className = 'passed'
                />
              </div>
              <div>kk</div>
              <Button icon = 'update' type = "buttonGreen" label = 'Tallenna' onClick={updateExpTimeHandler}/>

            </form>

          </div>
        </UiCard>
      </>
      :
      <>
        <Loader
          title= "ExpirySettings.js - Toiminto ei onnistunut"
          text= "Jos olet luonut uuden tilin, luo ensin etusivulla tietokanta korteille."
        />
      </>
  )
}
export default ExpirySettings