import React from 'react'
import { useSelector } from 'react-redux'
import IconButton from '../IconButton/IconButton'
import './Footer.css'


const Footer = () => {
  const theme = useSelector(state => state.theme)
  const d = new Date()
  let year = d.getFullYear()

  return(
    <div className = {'footer' + theme}>
      <IconButton char = "Z" className="icon-button-secondary" size="50px"/>
      <div className="footer-logo">
        AHJOMEDIA</div>
      <div className="rights">All rights reserver Ahjomedia © {year}</div>
    </div>
  )
}

export default Footer