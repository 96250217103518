import React, { useState } from 'react'
import './NavBar.css'
import { useHistory } from 'react-router-dom'
import { logout } from '../../../reducers/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import SliderMenu from '../SliderMenu/SliderMenu'
import UserMenu from './UserMenu/UserMenu'
import SettingsMenu from './SettingsMenu/SettingsMenu'
import Help from '../Help/Help'
import IconButton from '../IconButton/IconButton'

const NavBar = ({ activeModules }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useSelector(state => state.theme)

  // user
  const user = useSelector(state => state.user)

  // States
  const [userMenuState, setUserMenuState] = useState('')
  const [setingsMenuState, setSetingsMenuState] = useState('close')
  const [browseMenuState, setBrowseMenuState] = useState('')

  // handlers
  const logoClick = () => {
    browseMenuState === 'open' && setBrowseMenuState('close')
    userMenuState === 'open' && setUserMenuState('close')
    setSetingsMenuState('close')
    history.push('/')
  }
  const onClickLogout = () => {
    history.push('/login')
    dispatch(logout())
  }

  const toggleUserMenu = () => {
    // Menu state
    const s = userMenuState === 'open' ? 'close' : 'open'
    setUserMenuState(s)
    browseMenuState === 'open' && toggleBrowseMenu()
    closeMenus()
  }
  const toggleSettingsMenu = () => {
    setingsMenuState === false ? setSetingsMenuState(true) : setSetingsMenuState(false)
  }

  const toggleBrowseMenu = () => {
    // Menu state
    const s = browseMenuState === 'open' ? 'close' : 'open'
    setBrowseMenuState(s)
    userMenuState === 'open' && toggleUserMenu()
  }

  const closeMenus = () => {
    setingsMenuState  === 'open' && setSetingsMenuState('close')
    userMenuState     === 'open '&& setUserMenuState('close')
    browseMenuState   === 'open' && setBrowseMenuState('close')
  }

  const forceCloseMenus = () => {
    setSetingsMenuState('close')
    setUserMenuState('close')
    setBrowseMenuState('close')
  }

  const settingsHandler = () => {
    userMenuState === 'open' && setUserMenuState('close')
    setingsMenuState === 'open' ? setSetingsMenuState('close') : setSetingsMenuState('open')
  }

  return(
    <>
      {/* Branding */}
      <div id="nav-bar" className={'nav-bar' + theme}>
        <IconButton
          char = "Z"
          onClick = {logoClick}
          className = 'icon-button-white'
          size="20px"
          text="AHJO"
        />

        {/*  ----------- Apps ------------------------- */}
        {/* Messages App Nav Link */}
        { activeModules && activeModules.messages === true &&
        <IconButton
          onClick = {() => { forceCloseMenus(); history.push('/msg') }}
          char = "e"
          size="20px"
          className = 'icon-button-gray'
          text="MESE"
        />
        }

        {/* Gift Cards App Nav Link */}
        { activeModules && activeModules.giftCard === true &&
        <IconButton
          onClick = {() =>  { forceCloseMenus(); history.push('/cards') }}
          char = "f"
          className = 'icon-button-gray'
          size="20px"
          text="KORTIT"
        />
        }


        {/* Rapsat Link */}
        { activeModules && activeModules.giftCard === true &&
        <IconButton
          onClick = {() =>  { forceCloseMenus(); history.push('/rapsat') }}
          char = "V"
          className = 'icon-button-gray'
          size="20px"
          text="Rapsat"
        />
        }

        {/* Customers App Nav Link */}
        { activeModules && activeModules.customers === true &&
        <IconButton
          onClick={() =>  { forceCloseMenus(); history.push('/customers') }}
          char = "w"
          className = 'icon-button-gray'
          size = "20px"
          text="ASIAKKAAT"
        />
        }
        {/* GAP */}
        <div className="nav-gap"/>

        {/* ------------- user & settings  ----------------*/}

        {/* User button */}
        <IconButton
          id = "user-menu-button"
          data-testid = "user-menu-button"
          onClick = {toggleUserMenu}
          size = "20px"
          className = 'icon-button-gray'
          char = "Y"
          text = "TILI"
        />

        {/* Settings button */}
        { activeModules &&  activeModules.giftCard === true &&
        <IconButton
          className = "icon-button-gray"
          char = "m"
          onClick={settingsHandler}
          size="20px"
          text = "ASETUKSET"
        />}



        <Help/>
      </div>

      {/* User menu, dropdwn*/}
      <SliderMenu menuState={userMenuState}>
        <UserMenu
          logout={onClickLogout}
          username={user && user.username}
          userRole={user && user.role}
          toggleUserMenu={toggleUserMenu}
        />
      </SliderMenu>

      {/* settings menu, dropdawn*/}
      <div className={`slider-menu ${setingsMenuState}`}>
        {setingsMenuState === 'open' &&
        <>
          <SettingsMenu
            logout={onClickLogout}
            username={user && user.username}
            userRole={user && user.role}
            toggleSettingsMenu={toggleSettingsMenu}
          />
        </>
        }
      </div>

      {(browseMenuState === 'open' || userMenuState === 'open' || setingsMenuState === 'open') && <div className="off-menu" onClick={closeMenus}/>}
    </>
  )
}

export default NavBar