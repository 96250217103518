//-------------------------------------------------------------------------|
//    BodyButtons is taking care of body contents upper links of UIcard    |
//    is dependent by bodyContent                                          |
//-------------------------------------------------------------------------|

import React from 'react'
import srcollTo from '../../../../../functions/scrollTo'
import IconButton from '../../../../UI/IconButton/IconButton'

const BodyButtons = ({ setBodyContainer, tag, setSelectedID }) =>
{
  //----------------------------------------------------------------------------------|
  //     upper buttons in body of UIcard. Every button is calling sertain container   |
  //     container items are defined below                                            |
  //----------------------------------------------------------------------------------|

  const BodyButton = ({ container, label, text }) => {
    return(
      <IconButton
        char={label}
        className="icon-button-secondary"
        text = {text}
        onClick={ () => { setBodyContainer(container); srcollTo(0); setSelectedID(tag.id)}}
      />
    )
  }
  return(
    <div className='body-buttons-container'>

      {/* --------------------------------------------------------------------------------|
      |    • container number, needed in bodyEdit component to trcker desired content     |
      |    • label is which icon to use                                                   |
      |    • text is shown when help state is true                                        |
      | -------------------------------------------------------------------------------|*/}

      <BodyButton container = "1" label="L" text="Muokkaa sisältöä"/>
      <BodyButton container = "2" label="S" text="Muokkaa tietoja"/>
      <BodyButton container = "3" label="J" text="Tagit"/>
    </div>
  )
}

export default BodyButtons