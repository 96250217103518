import React from 'react'
import { useSelector } from 'react-redux'
import ProfileForm from './ProfileForm/ProfileForm'
import Title from '../../UI/Title/Title'
import langs from '../../../langs'
import AddAvatar from './AddAvatar/AddAvatar'

const UserProfile = () => {

  const user = useSelector(state => state.user)
  const lN = user.language // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  return(
    <>
      <Title text={trans.personaInfo[lN]} icon="Y"/>
      <div className="container">
        <ProfileForm/>
        <AddAvatar/>
      </div>
    </>
  )
}

export default UserProfile