import React, { useEffect, useState, useRef } from 'react'
import PostList from './PostList/PostList'
import PostManager from '../PostManager/PostManager'
import { useSelector, useDispatch } from 'react-redux'
import { initializeCurrentThread } from '../../MessageReducers/threadsReducer'
import { useHistory } from 'react-router-dom'
import IconButton from '../../../../components/UI/IconButton/IconButton'

const Thread = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const token = useSelector(state => state.user && state.user.token)
  const threads = useSelector(state => state.threads && state.threads.allThreads)
  const currentThread = useSelector(state => state.threads && state.threads.currentThread)
  const [showPostManager, setShowPostManager] = useState(false)

  const [posts, setPosts] = useState([])
  const postTextareaRef = useRef()

  const focusInput = () => {
    (postTextareaRef && postTextareaRef.current) && postTextareaRef.current.focus()
  }

  const initPosts = () => {
    const url = window.location.href
    const idIndex = url.lastIndexOf('/')
    const threadId = url.substring(idIndex + 1)
    dispatch(initializeCurrentThread(threadId, token))
  }
  useEffect(initPosts, [threads, token])

  useEffect(() => {
    currentThread && setPosts(currentThread.posts)
  }, [currentThread])


  const onClickBackButton = () => {
    history.push('/staff/msg')
  }

  const backHandle = () => {
    history.push('/msg')
  }

  return(
    <>
      { showPostManager === true ?
        <PostManager
          initPosts={initPosts}
          postTextareaRef={postTextareaRef}
          resetUi={onClickBackButton}
          setShowPostManager = {setShowPostManager}
        ></PostManager>
        :

        <div className='cpostList-cont container'>
          <div className="gap-20"/>
          <div className="profile-form-buttons">
            {/* back button */}
            <IconButton
              text = 'Takaisin'
              char = 'q'
              size="30px"
              onClick={backHandle}
              className="icon-button-secondary"
            />
            <IconButton
              text = 'Kirjoita'
              char = 'L'
              size="30px"
              onClick = { () => setShowPostManager(true)}
              className="icon-button-secondary"
            />
          </div>

        </div>
      }
      <div>
        <h3 style={{ marginLeft: '5%' }}>{currentThread && currentThread.topic}</h3>
        <PostList posts={posts} focusInput={focusInput} setShowPostManager={setShowPostManager}/>
      </div>

    </>
  )
}

export default Thread

