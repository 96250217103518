import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCard } from '../reducers/cardsReducer'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import { setFilters } from '../../../reducers/filtersReducer'
import IconButton from '../../../components/UI/IconButton/IconButton'

const BodyButtons = ({
  card, setIdSelected,
  giftNavigate, setButtonStyleEdit, deleted }) =>
{
  const dispatch = useDispatch()

  // selectors
  const token = useSelector(state => state.user.token)
  const cards = useSelector(state => state.cards)
  const filters = useSelector(state => state.filters && state.filters)
  // const theme = useSelector(state => state.theme)

  const handleActivateCard = (event) => {
    event.preventDefault()

    const cardObject = { active: true }
    dispatch(updateCard(token, cardObject, card.id, cards ))  // update cards
    giftNavigate(0)
    setButtonStyleEdit('defaultButton')


    // pop notification about update
    dispatch(setNotification({
      notification: 'Kortti on aktivoitu onnistuneesti',
      type: 'default' }))

    // dont show cards in trach anymore
    dispatch(setFilters({ ...filters, cardsTrach: false }))

    // show selected card
    setIdSelected(card.id)
    giftNavigate(0)
  }


  if (deleted === false){return(
    <div className = 'body-buttons-container'>
      { filters.cardsTrach === false &&

        <IconButton
          char="N"
          text='Veloita'
          onClick={() => {
            giftNavigate(1)
            setIdSelected(card.id)
          }}
          id="gift-cards-use"
          className = 'icon-button-secondary'
        />

      }
      <IconButton
        char="S"
        onClick={() => {
          setIdSelected(card.id)
          giftNavigate(2)
        }}
        id="gift-cards-info"
        text='Tiedot'
        className = 'icon-button-secondary'
      />

      <IconButton
        char="Q"
        text='Käyttöhistoria'
        onClick={() => {
          setIdSelected(card.id)
          giftNavigate(3)
        }}
        id="gift-cards-history"
        className = 'icon-button-secondary'

      />
      <IconButton
        char="V"
        onClick={() => {
          setIdSelected(card.id)
          giftNavigate(8)
        }}
        id="gift-cards-print"
        text = 'Tulosta'
        className = 'icon-button-secondary'
      />

      { filters.cardsTrach === false &&
        <IconButton
          char="L"
          onClick={() => {
            setIdSelected(card.id)
            giftNavigate(4)
          }}
          id="gift-cards-edit"
          text = 'Muokkaa'
          className = 'icon-button-secondary'
        />
      }
      { filters.cardsTrach === true &&

            <button className="buttonGreen-small" type="submit"onClick={handleActivateCard}>
              Palauta kortti
            </button>

      }
    </div>
  )
  } else {return (
    <></>
  ) }}

export default BodyButtons