import React from 'react'
import IconButton from '../../../../../../UI/IconButton/IconButton'

const MapTags = ({ tagsToMap, handleSelectTags,   handlePreview,value  }) => {

  return(
    <>
      { tagsToMap.map(tag =>
      {
        // cahancing style of button if tag is active or not
        let style = 'tag-selected '
        let className = 'icon-button-white tag-eye'

        // if array of alla tags includes active tag, cahnge style
        if(value.includes(tag.id)) {
          style = ''
          className = 'icon-button-secondary tag-eye'
        }

        return(
          <div
            className = {'tag-card ' + style}
            key={tag.id}>

            <p className = "tag-name" onClick={() => handleSelectTags(tag.id)}>

              {tag.name}

            </p>

            {/*------------------------------------------------------------------|
      |     Button to send id for handlePreview for showing tag preview    |
      |------------------------------------------------------------------*/}
            <IconButton
              className={className}
              size="13px"
              char='G'
              id={tag.id}
              onClick={() => handlePreview(tag.id)}
            />
          </div>
        )
      }
      )}
    </>
  )
}

export default MapTags