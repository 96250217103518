import React, { useState } from 'react'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../../components/UI/Button/Button'
import { updateCustomer } from '../../../../reducers/rdc-oneCustomer'
import TextArea from '../../../../../../components/UI/TextArea/TextArea'
import Text from '../../../../../../components/UI/Text/Text'
import BodyInTags from '../../../../../../components/Private/Tags/components/BodyComponents/BodyInTags/BodyInTags'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'

const Line = ({ line, setEditItem, saveTags, editItem, type, title, item, data, className, button, linkText, tags }) => {
  const token = useSelector(state => state.user.token)
  const customer = useSelector(state => state.oneCustomer)
  const dispatch = useDispatch()
  const id = line.id
  const [value, SETvalue] = useState( line !== undefined ? line[item] : null)
  const locations = useSelector(state => state.locations)
  console.log('data: ', data)

  let updatedData
  if(editItem && value !== null) {
    updatedData = line
    updatedData[item] = value
  }

  const handleRaport = () => {
    console.log('print')
  }
  const hanleUpdateData = () => {

    let updatedAppointments = customer.appointments
    updatedAppointments = updatedAppointments.map(a => {
      if(a.id === id){
        return updatedData
      } else {
        return a
      }}
    )
    let updatedCustomer = customer
    updatedCustomer.appointments = updatedAppointments
    dispatch(updateCustomer(token, updatedCustomer, customer.id ))
    setEditItem({})
    dispatch(setNotification({ notification: 'Muutokset kirjaukseen on tallennettu' }))

  }

  const handleDeleteAppointment = () => {

    dispatch(setNotification({
      notification: 'Oletko varma, että haluat poistaa asiakaskäynnin, toimintoa ei voida peruuttaa!', // title
      type: 'modal',
      onClickConfirm: () => {
        let updatedAppointments = customer.appointments
        // filtering current appointment
        updatedAppointments = updatedAppointments.filter(appointment => appointment.id !== id)

        let updatedCustomer = customer
        updatedCustomer.appointments = updatedAppointments
        dispatch(updateCustomer(token, updatedCustomer, customer.id))
        setTimeout(() => {dispatch(setNotification({ notification: 'Asiakaskäynti on nyt poistettu!' }))},500)
      }, // apply function
      onClickBack: () => setEditItem({}) // cancel function
    }, 100000 // time for modal to be shown if not clicked anything
    ))
  }

  // ------------ RETURN PAGE ----------------------------------
  if( editItem && id ){

    if(editItem !== id || button){ return(
      <div className= {className + ' even-odd'} >
        <div className="card-list">
          <div className="flex-container-info"  ><b>{title}</b></div>
          <div className="flex-item-right">
            {type !== 'select'
              ? <Text data={data}/>
              : <Text data={locations.filter(loc => loc.id === value)[0].name}/>
            }
          </div>

          {/* buttons for header line */}
          { button && editItem !== id ?
            <IconButton
              className="icon-button-white"
              char="L"
              onClick={button}
              size="30px"
              text="muokkaa"
            />
            : !item &&
        <>
          <IconButton
            className="icon-button-white"
            char="x"
            size="20px"
            onClick={() => setEditItem({})}
            text="takaisin"
          />
        </>
          }
        </div>
        { saveTags &&
          <BodyInTags
            items = {tags}
            itemId = {line.id}
            selectedID={line.id}
            saveTags = {saveTags}
            linkText = {linkText}
            editTitle = '-'
            sortBy = {item}
          />}
      </div>
    )}
    else {return(
      <>
        <div className= {className + ' even-odd'} >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="card-list">
              <div className="flex-container-info"  ><b>{title}</b></div>

              { type === 'input' &&
            <input
              className="passed"
              value = {value}
              onChange={(e) => {
                SETvalue(e.target.value)
                updatedData[item]=e.target.value
              } }
            />}

              { type === 'textArea' &&
            <TextArea
              onChange={(e) => { SETvalue(e.target.value)
                updatedData[item]=e.target.value }}
              className = "passed"
              value = {value}
              trickers ={[value, editItem]}
            />}
              { type === 'select' &&
               <select onChange={(e) => { SETvalue(e.target.value)} } >
                 <option value = {value}>{locations.filter(loc => loc.id === value)[0].name}</option>
                 { locations.map(loc => {
                   return(
                     <option key={loc.id} value={loc.id}>{loc.name}</option>
                   )
                 })
                 }
               </select>
              }
            </div>
          </div>

          {saveTags &&
            <BodyInTags
              items = {tags}
              itemId = {line.id}
              selectedID={line.id}
              saveTags = {saveTags}
              linkText = {linkText}
              editTitle = '-'
              sortBy = {item}
            />}
        </div>

        {/* update button */}
        {item === 'notes' &&
      <div className="buttons-row">
        { editItem !== customer.appointments &&
          <Button
            className="buttonGreen"
            label="Päivitä tiedot"
            onClick={hanleUpdateData}
          />
        }
        <Button
          className=""
          label="Peruuta"
          onClick={() => setEditItem({})}
        />
        <Button
          className="buttonRed"
          label="Poista kirjaus"
          onClick={handleDeleteAppointment}
        />
        <Button
          label="Tulosta raportti"
          onClick={handleRaport}
        />
      </div>}

      </>
    )}
  } else return null}

export default Line