import { getInvitedUser } from '../services/signUp'

const invitedUserReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_INVITED_USER':
    return action.data

  default: return state
  }
}

export const initializeInvitedUser = (id) => {
  return async dispatch => {
    const user = await getInvitedUser(id)
    dispatch({
      type: 'SET_INVITED_USER',
      data: user
    })
  }
}

export default invitedUserReducer