import React, { useState } from 'react'
import HelpText from '../../../../../../components/UI/HelpText/HelpText'
import Line from './Line'
import DayAndTime from '../../../../../../functions/DayAndTime'
// import BodyInTags from '../../../../../../components/Private/Tags/components/BodyComponents/BodyInTags/BodyInTags'
import { useDispatch } from 'react-redux'
import { updateCustomerAppointmentsTags, updateCustomerCheckTags, updateCustomerInstructionTags } from '../../../../reducers/rdc-oneCustomer'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import { useHistory } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import { useSelector } from 'react-redux'

const Body_History = ({ setBodyContainer, customer }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [editItem, setEditItem] = useState({ id: 0 })


  const saveTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateCustomerAppointmentsTags(token, newTags, customer.id, appointmentId, tags ))
  }
  const instructionTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateCustomerInstructionTags(token, newTags, customer.id, appointmentId, tags ))
  }
  const saveCheckTags = (token, newTags, tags, appointmentId) => {
    dispatch(updateCustomerCheckTags(token, newTags, customer.id, appointmentId, tags ))
  }
  const handleRaport = () => {
    history.push('/customer/' + customer.id)
  }
  return(
    <>
      <h4 className="body-header">Kirjaukset</h4>
      <HelpText
        text="Täällä voit tehdä uusia kirjauksia, tai muokata niitä.
          + -merkistä voit tehdä uuden kirjauksen.
          Kynä -kuvakkeesta voit muokata kirjausta."
      />
      {/* add new appointment */}
      <div className="customer-add-new-appointment">
        <div className="buttons-row mp-center">
          <IconButton
            char="j"
            className="icon-button-main"
            onClick={() => { setBodyContainer('5') }}
            text="Tee uusi kirjaus"
          />
          <IconButton
            char="V"
            onClick={handleRaport}
            className="icon-button-gray"
            text="tulosta raportti"
          />
        </div>
      </div>
      <div className = "flex-column-reverse">
        {customer && customer.appointments !== undefined && customer.appointments.map(line =>
          <div key={line.id} className="customers-body-container">

            <Line
              editItem = {editItem}
              title={<DayAndTime date ={line.date}/>}
              data ={line.product}
              className="customers-historylist-headerrow"
              button = {() => setEditItem(line.id)}
              setEditItem = {setEditItem}
              line={line}
              type="input"
            />
            {line.location &&
            <Line
              editItem = {editItem}
              title="Toimipiste"
              data ={line.location}
              item = "location"
              line={line}
              type="select"
              setEditItem = {setEditItem}
            />}
            <Line
              editItem = {editItem}
              title="Tuote"
              data ={line.product}
              item = "product"
              line={line}
              type="input"
              setEditItem = {setEditItem}
            />

            <Line
              title="Työntekijä"
              editItem = {editItem}
              data ={line.staff_ID}
              item = "staff_ID"
              line={line}
              setEditItem = {setEditItem}
              type="input"
            />

            <Line title="Hoidon kuvaus"
              editItem = {editItem}
              data ={line.treatment}
              line={line}
              item = "treatment"
              setEditItem = {setEditItem}
              type="textArea"
            />

            <Line
              title="Tutkimus / havainnot"
              editItem = {editItem}
              line={line}
              item = "check"
              setEditItem = {setEditItem}
              data ={line.check}
              type="textArea"
              saveTags = {saveCheckTags}
              linkText = "tagit"
              tags = {line.checkTags}
            />

            <Line
              title="Ohjeistukset"
              editItem = {editItem}
              line={line}
              item = "instructions"
              data ={line.instructions}
              setEditItem = {setEditItem}
              type="textArea"
              saveTags = {instructionTags}
              linkText = "tagit"
              tags = {line.instructionTags}
            />

            <Line
              title="Muistiinpanot"
              editItem = {editItem}
              line={line}
              item = "notes"
              setEditItem = {setEditItem}
              data ={line.notes}
              type="textArea"
              saveTags = {saveTags}
              linkText = "Tagit tapaamiselle"
              editTitle = 'tagit'
              tags = {line.tags}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default Body_History