// Colors for Authors, stayin in area of green/blue/red - no yellow.

let R,G,B
const authorColors = (prop) => {
  const author = prop[0]
  const color = prop[1]
  if(      author.length < 5 ) { R = 20;  G = 137; B = 250 }
  else if( author.length < 7 ) { R = 250; G = 137; B = 235 }
  else if( author.length >=8 ) { R = 46;  G = 250; B = 74  }

  R = (R + 4000 - (author.slice(0,1).toLowerCase().charCodeAt(0))*40 + 600) /2
  G = (G + 4100 - (author.slice(1,2).toLowerCase().charCodeAt(0))*40 + 250) /3
  B = (B + 4000 - (author.slice(2,3).toLowerCase().charCodeAt(0))*40 + 300) /3
  if(color && color === 'no'){
    R=190
    G=190
    B=190}

  return R+','+G+','+B
}

export default authorColors