import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableOfUse from './components/TableOfUse'
import TableOfSells from './components/TableOfSells'
import Loader from '../../../../components/UI/Loader/Loader'
import Title from '../../../../components/UI/Title/Title'
import { getCards } from  '../../reducers/cardsReducer'

import './RaportsHolder.css'



let thereIsUsage = false
const RaportsHolder = () => {
  const user = useState(useSelector(state => state.user))[0]
  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)
  useEffect(() => {
    !cards && dispatch(getCards( user.token, user.company))
  }, [user, dispatch])

  const giftCardData = useSelector(state => cards && state.cards.cards_cards)
  const usage = giftCardData && giftCardData.filter(card => card.usage.length > 0)
  if(giftCardData && giftCardData[0] && usage.length > 0 ){
    thereIsUsage = true
  }

  // getDaysInMonth(12, 2012) // how to define numbers of days in month...

  // ------ R E T U R N   P A G E -------//
  return(

    <div>
      <Title icon = "V" text = 'Raportit'/>
      {(giftCardData && giftCardData[0] !== 'empty' && giftCardData.length > 0 && thereIsUsage === true) ?
        <>
          <TableOfUse giftCardData={giftCardData}/>
          <TableOfSells giftCardData={giftCardData}/>
        </>:
        <>
          <Loader
            title= "Raportteja ei pystytä muodostamaan"
            text= "Jos olet luonut uuden tilin, ei raportteja vielä voida muodostaa, ennen kuin korteille on syntynyt käyttöä."
          />
        </>
      }
    </div>
  )
}

export default RaportsHolder