import React from 'react'
import { useSelector } from 'react-redux'
import './UiCardFooter.css'

const UiCardFooter = ({ footerInfo, helpTxt, footerInfoRight }) => {
  const help = useSelector(state => state.help && state.help.show)

  return (
    <div className= 'ui-card-footer'>

      {/* <h5>{props.footerTitle}</h5> */}
      <div className = "uiCard-footer-icon">
      </div>

      <div className='footer-info'>{footerInfo}</div>
      {help === true && footerInfo && <><br/>{helpTxt}</>}
      {help === true && footerInfo === undefined && <>{helpTxt}</>}
      <div className = "footer-info-right">
        {footerInfoRight}
      </div>
    </div>

  )
}

export default UiCardFooter

