import { setNotification } from '../../../UI/Notification/notificationReducer'
import {
  getLocations, postLocation, putLocation, deleteLocation
} from '../services/srvLocations'


const locationsReducer = (state = [], action) => {
  switch(action.type){
  case 'SET_LOCATONS':
    return action.data

  default: return state
  }
}

// get locations

export const get_locations = (token, companyId) => {
  return async dispatch => {
    const locations = await getLocations(token, companyId)
    dispatch({
      type: 'SET_LOCATONS',
      data: locations.filter(loc => loc.state === true)
    })
  }
}

// post location
export const post_location = (token, body, locations) => {
  return async dispatch => {
    const newLocation = await postLocation(token, body)
    // merge new location to existing ones
    const updatedLocations = [].concat(locations, newLocation)
    dispatch({
      type: 'SET_LOCATONS',
      data: updatedLocations
    })
  }
}

// put location
export const put_location = (token, body, locations, locationId) => {
  return async dispatch => {
    // updated location from db
    const updatedLocation = await putLocation(token, body, locationId)

    // mapping updated location to exsisting locations array
    const updatedLocations = locations.map( location => {
      if(location.id === locationId) return updatedLocation
      else return location
    })
    dispatch({
      type: 'SET_LOCATONS',
      data: updatedLocations
    })
  }
}

// delete location
export const delete_location = (token, locations, locationId) => {
  return async dispatch => {
    // we dont need respond from server
    const query = await deleteLocation(token, locationId)
    const updatedLocations = locations.filter( location => location.id !== locationId)
    if(query.status === 200){
      setTimeout( () => { dispatch(setNotification({ notification: 'Tuote on poistettu!' })) } ,500 )
      dispatch({
        type: 'SET_LOCATONS',
        data: updatedLocations
      })
    } else setTimeout( () => { dispatch(setNotification({ notification: 'Hempura, jotain meni pieleen! Kokeile uudestaan myöhemmin!' })) } ,500 )
  }
}

export default locationsReducer