// function to replace new value for named key in object
// you can modify object using variables.
// 1. paramater is object to modify
// 2. parameter is key in object to modify
// 3. is value u want to give for key

const modifyKey = (objectToModify, keyToModify, value) => {
  objectToModify[keyToModify] = value
  return objectToModify
}

export default modifyKey