import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from '../components/Public/Login/Login'
import ResetPassword from '../components/Public/ResetPassword/ResetPassword'
import ForgotPassword from '../components/Public/ForgotPassword/ForgotPassword'
import SignUp from '../components/Public/SignUp/SignUp'


import Footer from '../components/UI/Footer/Footer'


const LoginRoutes = () => {

  return(

    <div className="router-login-container">
      <Switch> <Route exact path="/">     <Login />           <Footer/></Route>
        <Route exact path="/reset">       <ResetPassword />   <Footer/> </Route>
        <Route exact path="/forgot">      <ForgotPassword />  <Footer/>  </Route>
        <Route path="/signup">            <SignUp/>           <Footer/></Route>
        <Route path="/*">                 <Login />           <Footer/></Route>
      </Switch>
    </div>
  )

}

export default LoginRoutes