import { useDispatch } from 'react-redux'

const activatedAppModules = ({ user, companies, userCompany, useEffect, setActiveModules }) => {
  // get users
  useDispatch
  useEffect(() => {
    if(companies && companies.length > 0 && userCompany.id !== '') {
      setActiveModules(companies.filter(c => c.id === user.company)[0].activeModules)
    }
  },[user, history, companies])
}

export default activatedAppModules
