import React from 'react'
import { useSelector } from 'react-redux'
import './iconButton.css'
const IconButton = ({ char, onClick, id, className, text, size }) => {
  const help = useSelector(state => state.help.show)
  if(!className) {className = ''}
  if(!id) {id = ''}
  if(!size) {size = '35px'}

  return(

    <div className='icon-container'>
      <button onClick={onClick} id={id} className={'icon-symbol ' + className}><span style={{ fontSize: size }}>{char}</span></button>
      { help === true && <div className="icon-txt" >{text}</div>}
    </div>

  )
}

export default IconButton