import React from 'react'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import DayAndTime from '../../../../../../functions/DayAndTime'


const DataLoopContent = ({ data, editdataHandler, setEditData }) => {
  console.log('data in db: ', data)
  return(
    <div key={data.id} className="card-list even-odd">
      <div className="flex-container-info">
        <DayAndTime date = {data.date}/>
      </div>
      <div className="flex-item-right">
        {data.data}
      </div>
      <div className="flex-item-right align-items-end">
        <IconButton
          className="icon-button-secondary"
          char="L"
          size="20px"
          text="muokkaa"
          onClick={() => {
            editdataHandler
            setEditData(data.id)}}/>
      </div>
    </div>
  )
}

export default DataLoopContent