import React from 'react'
import { useSelector } from 'react-redux'
import './Button.css'

const Button = ({ onClick, id, className, label }) => {
  if(!className){className = 'default-button'}
  const theme = useSelector(state => state.theme)
  return(
    <button onClick={onClick} id={id} className={className + ' ' + theme + '-button'}>
      {label}
    </button>
  )
}

export default Button