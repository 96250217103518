// ToggeleButton 6.3.2022 by Antti Haikonen
// toggles from down to up (up is on)

// expample:
// <ToggleButton onClick = {handler} label = 'Label' defaultState = 'true' />

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './ToggleButton.css'


const ToggleButton = ({ onClick, label, checked }) => {
  const theme = useSelector(s => s.theme)
  let style
  if(theme === ''){style ={ backgroundColor: 'white' }} else {style ={ backgroundColor: 'var(--main-color-dark)' }}

  const [styleContainer, setStyleContainer] = useState(checked === true ? { backgroundColor: 'white' } : null)
  const [styleItem, setStyleItem] = useState(checked === true ? { top: '0', backgroundColor: 'var(--passed-color)' } : { backgroundColor: 'var(--gray-medium)' })

  const toggler = () => {
    onClick()
    styleContainer === null ? setStyleContainer(style) : setStyleContainer(null)
    styleContainer === null ? setStyleItem({ top: '0', backgroundColor: 'var(--passed-color)' }) : setStyleItem({ backgroundColor: 'var(--gray-medium)' })
  }

  return(
    <div className={'button-toggle-container' + theme} style = {styleContainer} onClick = {toggler}>
      <div className= {'button-toggle-item' + theme} style = {styleItem}>{label}</div>
    </div>
  )
}

export default ToggleButton