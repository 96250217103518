import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'
import DayAndTime from '../../../../../../functions/DayAndTime'
import { editDatadb, deleteData } from '../../../../reducers/rdc-oneCustomer'

const EditData = ({ data, datapointId, setEditData, customer }) => {
  const [value, setValue] = useState(data.data)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const sendNewDataHandler = () => {

    const body = {
      dataId: data.id,
      data: value,
      datapointId: datapointId
    }
    dispatch(editDatadb(user.token, body, customer.id))
    dispatch(setNotification({
      notification: `Seurantapisteen arvo: ${value} on pävitetty onnistuneesti`,
      type: 'default' }))
    setValue('')
  }
  return(
    <div className="even-odd">
      <div className="space-20"/>
      <h5>Syötä päivitettävä arvo:</h5>
      <div className="card-list">
        <div className="flex-container-info">
          <div>
            <DayAndTime date={data.date}/>
          </div>
        </div>
        <div className="flex-container">
          <input
            className="passed"
            placeholder={data.data}
            value = {value}
            onChange={(e) => {setValue(e.target.value)}}
          />
        </div>
        <div className="flex-row">
          <IconButton
            className="icon-button-main"
            size="20px"
            char="u"
            text="OK"
            onClick={sendNewDataHandler}
          />
          <IconButton
            className="icon-button-gray"
            char="q"
            text="Peruuta"
            size="20px"
            onClick={() => setEditData(null)}
          />
          <IconButton
            className="icon-button-alert"
            char="M"
            text="Poista"
            size="20px"
            onClick={
              () => {
                dispatch(setNotification({
                  notification: 'Ookkonää ny iha varma?',
                  type: 'modal',
                  onClickConfirm: () => dispatch(deleteData(user.token, customer.id, data.id)),
                  onClickBack: () => setEditData(null)
                },
                100000
                ))
              }
            }
          />
        </div>
      </div>

    </div>
  )
}

export default EditData