import React from 'react'
import './TitleInput.css'
import { useDispatch, useSelector } from 'react-redux'
import { setNewThreadTopic } from '../../../MessageReducers/newPostReducer'

const TitleInput = () => {

  const dispatch = useDispatch()
  const topic = useSelector(state => state.newPost && state.newPost.newThreadTopic)

  const onChange = (e) => {
    dispatch(setNewThreadTopic(e.target.value))
  }

  return(
    <div id="title-input">
      <label htmlFor="thread-title">
        Otsikko:
      </label>
      <input
        type="text"
        name="thread-title"
        id="thread-title-input"
        value={topic}
        onChange={onChange}
      />
    </div>
  )
}

export default TitleInput