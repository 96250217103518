import { useDispatch } from 'react-redux'
import storageManager from '../services/storageManager'
import { setUser, logout } from '../reducers/userReducer'

const isUserLogged = ({ user, setCompanies, dispatch, useEffect, history }) => {
  // get users
  useDispatch
  useEffect(() => {
    user && dispatch(setCompanies(user.token, user.company))
    const userFromStorage = storageManager.getUser()
    if(!user){
      if(userFromStorage){
        const jsonUser = JSON.parse(userFromStorage)
        dispatch(setUser(jsonUser))
      } else { dispatch(logout())}
    } else {
      (!userFromStorage && user) && dispatch(logout())
    }
  },[dispatch, history, user])

}

export default isUserLogged
