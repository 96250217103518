import { msgNewPost, msgGetTopic, newThread } from '../MessageServices/msgService'

const initialPost = {
  body: '',
  images: [],
  links: [],
  company: '',
  threadId: null,
  quotes: [],
  textarea: null,
  newThreadTopic: ''
}

// const initialQuote = {
//   body: '',
//   images: [],
//   links: [],
//   name: '',
//   date: ''
// }

const newPostReducer = (state = initialPost, action) => {
  switch (action.type) {
  case 'RESET_POST':
    return initialPost

  case 'SET_POST_THREAD_ID': {
    const newPost = { ...state, threadId: action.data }
    return newPost
  }

  case 'SET_POST_BODY': {
    const newPost = { ...state, body: action.data }
    return newPost}

  case 'SET_POST_IMAGES': {
    const newImages = [...state.images]
    newImages.push(action.data)
    const newPost = { ...state, images: newImages }
    return newPost
  }

  case 'REMOVE_POST_IMAGE': {
    const newImages = state.images.filter(img => img !== action.data)
    const newPost = { ...state, images: newImages }
    return newPost
  }

  case 'SET_POST_LINKS': {
    const newLinks = [...state.links]
    newLinks.push(action.data)
    const newPost = { ...state, links: newLinks }
    return newPost
  }

  case 'REMOVE_POST_LINK': {
    const newLinks = state.links.filter(link => link !== action.data)
    const newPost = { ...state, links: newLinks }
    return newPost
  }

  case 'SET_POST_QUOTES': {
    let newQuotes = []
    const q = action.data.post

    if(q.quotes.length > 0){
      newQuotes = createListOfQuotes(q.quotes)
    }

    const quoteFromPost = {
      body: q.body,
      images: q.images,
      links: q.links,
      name: `${q.firstname} ${q.lastname}`,
      date: q.date
    }

    newQuotes.push(quoteFromPost)
    const newPost = { ...state, quotes: newQuotes }
    return newPost
  }

  case 'SET_TOPIC': {
    const newState = { ...state, newThreadTopic: action.data }
    return newState
  }

  case 'INIT_TEXTAREA':{
    const newState = { ...state, textarea: action.data }
    return newState
  }

  default: return state
  }
}

const createListOfQuotes = (quotes) => {
  const mappedQuotes = quotes && quotes.map(q => {
    const newQuote = {
      body: q.body,
      images: q.images,
      links: q.links,
      name: q.name ? q.name :`${q.firstname} ${q.lastname}`,
      date: q.date
    }
    return newQuote
  })
  return mappedQuotes
}

export const initializeTextarea = (textarea) => {
  return({
    type: 'INIT_TEXTAREA',
    data: textarea
  })
}

export const setPostThreadId = (id) => {
  return({
    type: 'SET_POST_THREAD_ID',
    data: id
  })
}

export const setPostBody = (body) => {
  return({
    type: 'SET_POST_BODY',
    data: body
  })
}

export const addImage = (image) => {
  return({
    type: 'SET_POST_IMAGES',
    data: image
  })
}

export const removeImage = (image) => {
  return({
    type: 'REMOVE_POST_IMAGE',
    data: image
  })
}

export const addLink = (link) => {
  return({
    type: 'SET_POST_LINKS',
    data: link
  })
}

export const removeLink = (linkToRemove) => {
  return({
    type: 'REMOVE_POST_LINK',
    data: linkToRemove
  })
}

export const setNewThreadTopic = (topic) => {
  return({
    type: 'SET_TOPIC',
    data: topic
  })
}

export const addQuotes = (quotedpost) => {
  return({
    type: 'SET_POST_QUOTES',
    data: quotedpost
  })
}

export const resetPost = () => {
  return({
    type: 'RESET_POST'
  })
}

export const submitPost = (token, post, topicId) => {

  return async dispatch => {
    await msgNewPost(token, post, topicId)
    dispatch({
      type:'RESET_POST'
    })

    const posts = await msgGetTopic(topicId, token)

    dispatch({
      type: 'INIT_CURRENT_THREAD',
      data: posts
    })
  }
}

export const submitThread = (token, post) => {
  return async dispatch => {
    await newThread(token, post)
    dispatch({
      type: 'RESET_POST'
    })
  }
}

export default newPostReducer