import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCustomer } from '../../../reducers/rdc-oneCustomer'
import Button from '../../../../../components/UI/Button/Button'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'
import { deleteCustomer } from '../../../reducers/rdc-oneCustomer'
import ToggleButton from '../../../../../components/UI/ToggleButton/ToggleButton'
import HelpPop from '../../../../../components/UI/HelpPop/HelpPop'
import HelpText from '../../../../../components/UI/HelpText/HelpText'
import DayAndTime from '../../../../../functions/DayAndTime'
import TextArea from '../../../../../components/UI/TextArea/TextArea'
import IconButton from '../../../../../components/UI/IconButton/IconButton'

const Body_BasicData = ({ customer, customers, setBodyContainer, setFilterdCustomers, token, setSelectedID }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const help = useSelector(state => state.help.show)

  // function to replace new value for named key in object
  function modifyKey(objectToModify, keyToModify, newValue){
    objectToModify[keyToModify] = newValue
    return objectToModify
  }
  // ------------- input with update handler -------------------------------
  const DataInput = ({ title, data, name }) => {
    const handlerUpdateClient = (event) => {
      event.preventDefault()
      // close input
      setOpenform(false)

      // New object to update
      const modifiedCustomer = modifyKey(customer, name, newData)

      // updating customer to db
      modifiedCustomer && dispatch(updateCustomer( user.token, modifiedCustomer, customer.id))

      // mapping updating customer to customers array
      const modifiedCustomers = customers.map(x => {
        if (x.id === customer.id){
          return modifyKey(x, name, newData)
        } else return x
      })

      // updating customers state
      setFilterdCustomers(modifiedCustomers)
    }

    // states
    const [openForm, setOpenform] = useState(false)
    const [newData, setNewData]= useState(data)

    return(
      <div className="card-list even-odd">
        <div className="flex-container-info hover-pointer" onClick={() => { setOpenform(false)}}>{title}</div>
        { openForm === false ?
          <>
            <div className="flex-item-right hover-pointer" onClick={() => { setOpenform(true)}}>{data}</div>
            {name && help === true &&
            <div className="flex-item-right align-items-end">
              <IconButton
                onClick={() => { setOpenform(true)}}
                className="icon-button-secondary"
                char="L"
                text="Muokkaa"
                size="30px"
              />
            </div>}
          </>
          :
          <form onSubmit={handlerUpdateClient} className="form-data-line">
            <div className="flex-item-right">
              <TextArea
                onChange={(e) => {setNewData(e.target.value)}}
                className = "passed"
                value = {newData}
                trickers ={newData}
              />

            </div>
            {name &&
            <>
              <div className="flex-item-right align-items-end">
                <IconButton
                  type="submit"
                  className="icon-button-main"
                  char="u"
                  text="OK"
                  size="30px"
                />
              </div>
              { help === true &&
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={() => { setOpenform(false)}}
                  className="icon-button-gray"
                  char="X"
                  text="Peru"
                  size="30px"
                />
              </div>}
            </>
            }
          </form>
        }
      </div>
    )
  }


  const handleDeleteCustomer = () => {
    dispatch(setNotification({
      notification: 'Asiakas, -tiedot ja käyntihistoria poistetaan! Toimintoa ei voi peruuttaa!!!', // title
      type: 'modal',
      onClickConfirm: () => {
        setBodyContainer(null)
        setSelectedID(null)
        dispatch(deleteCustomer(token, customer.id))
        setTimeout(() => {
          dispatch(setNotification({ notification: 'Asiakas on nyt poistettu!' }))
          setFilterdCustomers(customers.filter(cust => cust.id !== customer.id))
        },500)
      }
    }
    ))

  }
  // ----------------- ToggInput ----------------------------------//
  const ToggInput = ({ title, data, name, help }) => {

    // UPDATE CUSTOMER HANDLER
    const handlerUpdateClient = () => {
      // close input
      setOpenform(false)

      //  bracket notation, so only modified key is updated
      const object = { [name]: newData }

      // updating customer to db
      dispatch(updateCustomer( user.token, object, customer.id))

      // mapping updating customer to customers array
      const modifiedCustomers = customers.map(x => {
        if (x.id === customer.id){
          return modifyKey(x, name, newData)
        } else return x
      })

      // updating customers state
      setFilterdCustomers(modifiedCustomers)
    }

    // states
    const [openForm, setOpenform] = useState(false)
    let d
    if (!data || data === false) {d = false} else d = true
    const [newData, setNewData]= useState(d)

    return(
      <div className="card-list even-odd">
        <div className="flex-container-info hover-pointer"  onClick={() => { setOpenform(false)}}>{title}
          {help !== undefined &&
              <HelpPop
                text={help}
                position = "left"
              />
          }
        </div>
        { openForm === false ?
          <>
            <div onClick={() => { name && setOpenform(true)}} className="flex-item-right">{newData.toString()}</div>
            <div className="flex-item-right align-items-end hover-pointer">
              {name && help === true &&
              <IconButton
                onClick={() => { setOpenform(true)}}
                className="colorfulButton colorfulButton-small"
                char="L"
                text="Muokkaa"
                size="30px"
              />}
            </div>
          </>
          :
          <>
            <div className="flex-item-right" >
              <ToggleButton
                onClick = { () => {newData === false ? setNewData(true): setNewData(false)}}
                checked = { newData }
                label = { newData.toString() }
              />
            </div>
            {name &&
            <>
              <div className="flex-item-right align-items-end">
                <IconButton
                  onClick={handlerUpdateClient}
                  className="icon-button-main"
                  char="u"
                  text="OK"
                  size="30px"
                />
              </div>
              <div className="flex-item-right align-items-end">

                <IconButton
                  onClick={() => { setOpenform(false)}}
                  className="icon-button-gray"
                  char="X"
                  text="Peru"
                  size="30px"
                />
              </div>
            </>
            }
          </>
        }
      </div>
    )
  }

  return(
    <>
      <h4 className="body-header">Perustiedot</h4>
      <HelpText
        text={
          <>
            <p>Täällä voit tarkastella asiakkaan perustietoja ja muokata niitä.</p>
            <p>Klikkaamalla oikealla olevaa kynän kuvaketta, voit muokata tietoja. Syötä uusi arvo aukeavaan kenttään.</p>
            <p> VIhje, voit napsauttaa muokataksesi suoraan tekstiä. Muokkauksen voi keskeyttää napsauttamalla otsikkoa.</p>
          </>
        }
      />
      { customer && customer !== [] &&
        <div>
          <div className="customers-body-container" style={{ marginBottom: '30px' }}>

            <DataInput title = "Ilmoitetut sairaudet"   data = {customer.disease}                 name = "disease"/>
            <DataInput title = "Muut kontraindikaatiot" data = {customer.contraindication}        name = "contraindication"/>
            <DataInput title = "Allergiat"              data = {customer.allergy}                 name = "allergy"/>
            <DataInput title = "Lääkitykset"            data = {customer.medicines}               name = "medicines"/>
            <DataInput title = "Etunimi"                data = {customer.firstName}               name = "firstName"/>
            <DataInput title = "Sukunimi"               data = {customer.lastName}                name = "lastName"/>
            <DataInput title = "e-mail"                 data = {customer.email}                   name = "email"/>
            <DataInput title = "puh"                    data = {customer.phone}                   name = "phone"/>
            <DataInput title = "Syntymäaika"            data = {customer.birthDate}               name = "birthDate"/>
            <DataInput title = "Käyntien määrä"         data = {customer.appointments ? customer.appointments.length : 'Ei käyntejä'}name = {null}/>
            <DataInput title = "Ammatti"                data = {customer.profession}              name = "profession"/>
            <DataInput title = "Harrastukset"           data = {customer.hobby}                   name = "hobby"/>
            <DataInput title = "Osoite"                 data = {customer.address}                 name = "address"/>
            <DataInput title = "Lisätiedot"             data = {customer.info}                    name = "info" inputType = "txtArea" />
            <ToggInput title = "Markkinointilupa"       data = {customer.marketingPermission}     name = "marketingPermission"/>
            <ToggInput
              title = "Lupa muiden kirjauksiin"
              data = {customer.rightsForAllStaff}
              help = "Hoitopaikassa muilla kuin potilaan hoitoon osallistuvilla ei ole oikeutta lukea potilasasiakirjoja. Koska Fysio Up ei ole hoitolaitos, on asiakas antanut luvan ammatinharjoittajille tarkastella hänen aikaisempia kirjauksiaan silloin kun hän on hoitosuhteessa."
              name = "rightsForAllStaff"/>
            <DataInput title = "Asiakasnumero"          data = {customer.id}                      name = {null}/>
            <DataInput title = "Dokumentti luotu"       data = {<DayAndTime date = {customer.creationTime} />}            name = {null}/>
            <div className='buttons-row mp-mt-20'>
              <Button
                label = 'Poista asiakas tietokannasta'
                className="buttonRed"
                onClick={handleDeleteCustomer}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Body_BasicData
