import React, { useState }  from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../../../components/UI/Button/Button'
import { addNewCustomer } from '../../../../reducers/rdc-oneCustomer'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'
import Tietosuojaseloste from '../Tietosuojaseloste'
import ToggleButton from '../../../../../../components/UI/ToggleButton/ToggleButton'
import HelpPop from '../../../../../../components/UI/HelpPop/HelpPop'
import DateOption from '../../../../../Cards/components/RaportsHolder/components/DateOption'
import Body_ThankYou from './Body_ThankYou'
import TextArea from '../../../../../../components/UI/TextArea/TextArea'

const onlyNumbers = (value) => { return /^\d*\.?\d*$/.test(value) }   // to check is number

const Body_NewCustomer = ({ setBodyContainer }) => {
  // selectors
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  // states
  const [info, setInfo] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [disease, setDisease] = useState('')
  const [contraindication, setContraindication] = useState('')
  const [profession, setProfession] = useState('')
  const [allergy, setAllergy] = useState('')
  const [birthDate, setBirthDate] = useState({})
  const [hobby, setHobby] = useState('')
  const [marketingPermission, setMarketingPermission] = useState(true)
  const [privacyStatement, setPrivacyStatement] = useState(false)
  const [statementButton, setStatementButton] = useState(false)
  const [rightsForAllStaff, setRightsForAllStaff] = useState(true)
  const [medicines, setMedicines] = useState ('')
  const [thanks, setThanks] = useState('no')
  const [sosId, setSosId] =  useState('')

  // ------------  handlers -------------------------------------- /
  const handleCancel = () => {
    setThanks('boo')
  }
  const handleAddNewCustomer = (event) => {
    event.preventDefault()

    const body =
    {
      info: info,
      sosId: sosId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      address: address,
      disease: disease,
      medicines: medicines,
      contraindication: contraindication,
      profession: profession,
      allergy: allergy,
      birthDate: birthDate.date + '.' + birthDate.month + '.' + birthDate.year,
      hobby: hobby,
      company:user.company,
      marketingPermission: marketingPermission,
      rightsForAllStaff: rightsForAllStaff
    }
    // Starting plaaplaas for not valid data message
    let murmur = 'Seuraavat tiedot puuttuvat, tai ovat virheellisiä: '

    // lets check if inputs are valid data
    let isValid = false
    if(
      body.firstName !== '' &&
      body.lastName !== '' &&
      body.email !== '' &&
      (body.email.includes('@') === true || body.email.length > 6) &&
      body.phone!== '' &&
      body.phone.length > 3 &&
      body.address!== '' &&
      body.address.length > 9 &&
      body.birthDate !== {} &&
      statementButton === true &&
      (body.birthDate.length === 10 || body.birthDate.includes('.') !== false)

    ){isValid = true }
    // isValid = true

    if(isValid === true){
      // when data is valid add new customer
      const newCustomer = addNewCustomer( user.token, body, user.company)
      dispatch(newCustomer)

      window.scrollTo(0, 0)
      // move to thank you page for custoemr
      setThanks('yes')
    }
    // if not show some messages:
    else {
      if(body.firstName === '') {murmur = murmur + '• ETUNIMET '}
      if(body.phone === '') {murmur = murmur + '• PUHELIN '}
      if(body.phone.length < 4) {murmur = murmur + '• PUHELINNUMERO ON VIRHEELLINEN '}
      if(body.lastName === '') {murmur = murmur + '• SUKUNIMI '}
      if(body.email === '') {murmur = murmur + '• EMAIL '}
      if(body.email.includes('@') !== true || body.email.length < 7) {murmur = murmur + '• EMAIL EI OLE KELVOLLINEN '}
      if(body.address === '') {murmur = murmur + '• OSOITE '}
      if(body.address.length < 10) {murmur = murmur + '• OSOITE on VIRHEELLINEN '}
      if(body.birthDate === '') {murmur = murmur + '• SYNTYMÄAIKA '}
      if(body.birthDate.length !== 10 || body.birthDate.includes('.') === false) {murmur = murmur + '• SYNTYMÄAIKA ON VÄÄRÄSSÄ MUODOSSA, ILMOITA ESIM. 01.01.1987 '}
      if(statementButton === false) {murmur = murmur + '• HYVÄKSY TIETOSUOJASELOSTE '}

      // notification if something is missing of not correctly (murmur)
      dispatch(setNotification({ notification: murmur }, 8000)) // 5s delay
    }

  }

  return(
    <>
      {thanks === 'no' &&
      <div className = "new-customer-container">
        <div className = 'new-customer'>
          <div className='flex-column-container'>

            <h1>Asiakastietolomake</h1>
            {/* This text should be rendered from db, must be added soon */}
            <p>
            Tämä on Fysio Up Oy:n asiakastietolomake. Tallennamme tietosi järjestelmäämme. Tietojasi ei luovuteta kolmansille osapuolille. Voit tutustusa tietosuojaselosteeseemme alla olevan linkistä:
            </p>
            <div>
              {/* shoe privacyStatement (toggler) button*/}
              {privacyStatement !== true ?
                <Button
                  className = "buttonGreen"
                  label = 'Tietosuojaseloste'
                  type = 'default'
                  onClick = {() => setPrivacyStatement(true)}
                />
                :
                <Button
                  className = "buttonGreen"
                  label = 'Piilota tietosuojaseloste'
                  type = 'default'
                  onClick = {() => setPrivacyStatement(false)}
                />
              }

            </div>
            {/* content of privacyStatement */}
            {/* This data should be added to customer DB */}
            {privacyStatement === true && <Tietosuojaseloste/>}
            <p> * Tähdellä merkityt kohdat ovat pakoillisia, punaiset kentät vaativat huomiota.</p>
            <p>Kysy työntekijältä, jos jokin kysymys askarruttaa.</p>
          </div>
          <div className = 'flex-column-container' style={{ marginTop: 0, paddingTop: 0 }}>

            {/* firstName */}
            <div className = "input-group">
              <label>Etunimi</label>
              <input
                className={ firstName !== '' ? 'passed' : 'not-passed'}
                placeholder="Etunimet *"
                value = {firstName}
                onChange={(e) => {setFirstName(e.target.value)}}
              />
            </div>


            {/* lastName */}
            <div className = "input-group">
              <label>Sukunimi</label>
              <input
                className={ lastName !== '' ? 'passed' : 'not-passed'}
                placeholder="Sukunimi *"
                value = {lastName}
                onChange={(e) => {setLastName(e.target.value)}}
              />
            </div>

            {/* phone, only numbers, (), space, and + characters are allowed*/}
            <div className = "input-group">
              <label>Puhelin</label>
              <input
                className={phone.length > 3 ? 'passed' : 'not-passed'}
                placeholder="Puhelin *"
                value = {phone}
                onChange={(e) => {
                  let isItOk = false
                  let value = e.target.value
                  if(onlyNumbers( e.target.value.slice(-1) ) === true ) { isItOk = true }
                  if(value.slice(-1) === ' ' ) { isItOk = true }
                  if(value.slice(-1) === '+' ) { isItOk = true }
                  if(value.slice(-1) === '(' ) { isItOk = true }
                  if(value.slice(-1) === ')' ) { isItOk = true }
                  if (isItOk === true) setPhone(value)
                }}
              /></div>

            {/* email */}
            <div className = "input-group">
              <label>Email</label>
              <input
                className = {
                  (email &&
                   email.includes('@') === true &&
                   email.includes('.') === true &&
                   email.includes(' ') === false &&
                   email.length > 6 &&
                   email.split('@')[1].split('.').length === 2) &&
                   email.at(-1) !== '.' &&
                   email.at(-2) !== '.'
                    ? 'passed' : 'not-passed'}
                placeholder="email *"
                value = {email}
                onChange={(e) => {setEmail(e.target.value)}}
              /></div>

            {/* profession */}
            <div className = "input-group">
              <label>Ammatti / opiskelia</label>
              <input
                className="passed"
                placeholder="Ammatti / opiskelia"
                value = {profession}
                onChange={(e) => {setProfession(e.target.value)}}
              />
            </div>

            {/* address */}
            <div className = "input-group">
              <label>Osoite *</label>
              <input
                className={( address !== '' && address.length > 9 ) ? 'passaed' : 'not-passed'}
                placeholder="Osoite *"
                value = {address}
                onChange={(e) => {setAddress(e.target.value)}}
              />
            </div>

            {/* birthDate */}
            <div className="input-group>">
              <label>Syntymäaika</label>
              <div className="customer-form-birthday.container mp-mt-10">
                <select className = {'customer-form-date-of-year ' + (!birthDate.date ? 'not-passed' : 'passed') } onChange={(e) => {setBirthDate({ ...birthDate, date: e.target.value })}}>
                  <option>pv</option>
                  <DateOption amount="31" offset="1"/>
                </select>
                <select className = {'customer-form-month-of-year ' + (!birthDate.month ? 'not-passed' : 'passed') } onChange={(e) => {setBirthDate({ ...birthDate, month: e.target.value })}}>
                  <option>kk</option>
                  <DateOption amount="12" offset="1"/>
                </select>
                <select className = {'customer-form-year-of-year ' + (!birthDate.year ? 'not-passed' : 'passed') } onChange={(e) => {setBirthDate({ ...birthDate, year: e.target.value })}}>
                  <option>Vuosi</option>
                  <DateOption amount="121" offset={new Date().getFullYear() - 120}/>
                </select>
              </div>
            </div>

            {/* SOTU */}
            <div className = "input-group">
              <label>Sosiaalitunnuksen loppuosa</label>
              <input
                className="passed"
                placeholder="123A"
                value = {sosId}
                onChange={(e) => {sosId.length <= 3 ? setSosId(e.target.value) : setSosId('')}}
              />
            </div>

            {/* hobby */}
            <div className = "input-group">
              <label>Harrastukset</label>
              <input
                className="passed"
                placeholder="Harrastukset"
                value = {hobby}
                onChange={(e) => {setHobby(e.target.value)}}
              />
            </div>

            {/* disease */}
            <div className = "input-group">
              <label>Sairaudet ja leikkaukset</label>
              <TextArea className = "passed" trickers ={[disease]} placeholder='Sairaudet ja leikkaukset' value={disease} onChange={(e) => {setDisease(e.target.value)}}/>
            </div>

            {/* Medicines */}
            <div className = "input-group">
              <label>Lääkkeet</label>
              <input
                className="passed"
                placeholder="Lääkkeet"
                value = {medicines}
                onChange={(e) => {setMedicines(e.target.value)}}
              />
            </div>

            {/* allergy */}
            <div className = "input-group">
              <label>Allergiat</label>
              <input
                className="passed"
                placeholder="Allergiat"
                value = {allergy}
                onChange={(e) => {setAllergy(e.target.value)}}
              />
            </div>

            {/*  contraindications */}
            <div className = "input-group">
              <label>Muut kontraindikaatiot</label>
              <TextArea trickers ={[contraindication]} className = "passed" placeholder='Muut kontraindikaatiot' value={contraindication} onChange={(e) => {setContraindication(e.target.value)}}/>
            </div>

            {/*  contraindications */}
            <div className = "input-group">
              <label>Lisätiedot</label>
              <TextArea trickers ={[info]}  className = "passed" placeholder='Lisätiedot' value={info} onChange={(e) => {setInfo(e.target.value)}}/>
            </div>

            {/* Toggle buttons on bottom */}
            {/* marketingPermission toggler */}
            <div className="radio-buttons-row">
              <ToggleButton onClick = {() => marketingPermission === true ? setMarketingPermission(false) : setMarketingPermission(true)} label = { marketingPermission === true ? 'Sopii' : 'Ei'} checked = {marketingPermission}/>
              <div>Fysio Up saa lähettää minulle ajankohtaisviestejä

                <HelpPop
                  text="Tiedotukset koskevat esim. hinnaston muutoksia, tulevia tapahtumia tai muita tiedotteita. Noin kolme kertaa vuodessa"
                  position = "left"
                />
              </div>
            </div>

            {/* pricacy statement toggler */}
            <div className="radio-buttons-row">
              <ToggleButton onClick = {() => statementButton === true ? setStatementButton(false) : setStatementButton(true)} label = { statementButton === true ? 'Kyllä' : 'En'}/>
              <div>Olen tutustunut tietoturvaselosteeseen *
                <HelpPop
                  text="Klikkaamalla varmistat, että olet tietoinen Fysio Upin tietosuojasopimuksesta ja oikeuksistasi. Asetus myös luo sopimuksen sinun ja hoitajasi välille, joten valinta on pakollinen"
                  position = "left"
                />
              </div>
            </div>

            {/* Staff rights toggler */}
            <div className="radio-buttons-row">
              <ToggleButton onClick = { () => rightsForAllStaff === false ? setRightsForAllStaff(true) : setRightsForAllStaff(false) } label = { rightsForAllStaff === true ? 'OK' : 'Ei'}  checked = {rightsForAllStaff}/>
              <div>
            Ammatinharjoittajalla on oikeus tarkastella aikaisempia kirjauskia, vaikka työntekijä on vaihtunut

                <HelpPop
                  text="Kun varaat uuden ajan, ja sinulla on eri hoitaja (esim. hieroja), on hänellä oikeus nähdä hoitohistoriasi. Tämä on suositeltavaa, parhaan hoidon varmistamiseksi"
                  position = "left"
                />
              </div>
            </div>

            {/* confirmation, and cancel Buttons */}
            <div className="flex-row-container mp-mt-40 mp-mb-20" >
              <Button
                className = "buttonGreen"
                label = 'Tallenna'
                type = 'buttonGreen'
                icon = 'update'
                onClick={handleAddNewCustomer}
              />

              <div onClick={handleCancel}>
                <Button
                  type = "buttonNoEnter"
                  label='Peruuta'
                  icon='undo'
                />
              </div>
            </div>
          </div>
        </div>
      </div>}

      { (thanks === 'boo' || thanks === 'yes') &&
      <>
        <Body_ThankYou
          setBodyContainer = {setBodyContainer}
          setThanks = {setThanks}
          thanks = {thanks}
          newCustomer =
            {
              { info: info,
                sosId: sosId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                address: address,
                disease: disease,
                medicines: medicines,
                contraindication: contraindication,
                profession: profession,
                allergy: allergy,
                birthDate: birthDate.date + '.' + birthDate.month + '.' + birthDate.year,
                hobby: hobby,
                company:user.company,
                marketingPermission: marketingPermission === true ? 'Kyllä' : 'Ei',
                rightsForAllStaff: rightsForAllStaff === true ? 'Kyllä' : 'Ei' }
            }/>
      </>}
    </>
  )
}
export default Body_NewCustomer