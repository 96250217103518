const initialModal = {
  open: false,
  title: '',
  message: '',
  onClickConfirm: null
}

const simpleModalReducer = (state = initialModal, action) => {
  switch (action.type) {
  case 'OPEN_MODAL': {
    const newModal = {
      title: action.data.title,
      message: action.data.message,
      open: true,
      onClickConfirm: action.data.action
    }
    return newModal
  }
  case 'DELETE_MY_ACCOUNT': {
    const newModal = {
      title: action.data.title,
      message: action.data.message,
      open: true,
      onClickConfirm: 'delete_my_account'
    }
    return newModal
  }

  case 'CLOSE_MODAL': {
    return initialModal
  }

  default:
    return state
  }
}

export const openModal = (modalConfig) => {
  return{
    type: 'OPEN_MODAL',
    data: modalConfig
  }
}

export const openModalDeleteMyAccount = (modalConfig) => {
  return{
    type: 'DELETE_MY_ACCOUNT',
    data: modalConfig
  }
}

export const closeModal = () => {
  return {
    type: 'CLOSE_MODAL'
  }
}

export default simpleModalReducer