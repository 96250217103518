import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserForm from '../../Private/UserAdmin/UserForm/UserForm'
import PublicHeader from '../PublicHeader/PublicHeader'
import { initializeInvitedUser } from '../../../reducers/invitedUserReducer'
import { signUp } from '../../../services/signUp'
import { logout } from '../../../reducers/userReducer'
import { Link } from 'react-router-dom'

const SignUp = () => {

  const dispatch = useDispatch()
  const user = useSelector(state => state.invitedUser)
  const loggedInUser = useSelector(state => state.user && state.user)
  const [signUpCompleted, setSignUpCompleted] = useState(false)
  const [noInvitationFound, setNoInvitationFound] = useState(false)

  useEffect(() => {
    loggedInUser && dispatch(logout())
  }, [loggedInUser])

  useEffect(() => {
    const id = new URL(document.location.href).searchParams.get('u')
    dispatch(initializeInvitedUser(id))
    !user.id && setNoInvitationFound(true)
  }, [dispatch])

  const createUser = async (newUser) => {
    await signUp(user.id, newUser)
    setSignUpCompleted(true)
  }

  const userCreatedSuccess = <div>
    <h2>Käyttäjä luotu!</h2>
    <p>
      Olet nyt rekisteröitynyt käyttäjäksi. Klikkaa <Link to="/">tästä ja kirjaudu sisään</Link>.
    </p>
  </div>

  const userForm = <>
    <h1>Rekisteröidy HK-Apin käyttäjäksi sähköpostiosoitteella {user && user.email}</h1>
    <p>
      Täytä lomakkeen kaikki kentät suorittaaksesi rekisteröityminen loppuun.
      Et voi muuttaa sähköpostiosoitettasi tässä lomakkeessa. Halutessasi voit vaihtaa sen
      myöhemmin kun olet rekisteröitynyt käyttäjäksi.
    </p>
    <UserForm createUser={createUser} user={user} type='signUp'/>
  </>

  // If no invited user was found show error msg,
  // else show sign up form
  // and after (successfull) submit show success msg
  const content =
    <div className="container">
      { noInvitationFound ? <h3 style={{ textAlign: 'center' }}>{user}</h3> : signUpCompleted ? userCreatedSuccess : userForm }
    </div>

  return(
    <>
      <PublicHeader/>
      { content }
    </>
  )
}

export default SignUp