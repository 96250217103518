import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setToDos, newToDo, changeToDoState } from '../reducers/todosReducer'
import './ToDo.css'

const ToDo = () => {

  const dispatch = useDispatch()
  const todos = useSelector(state => state.toDos)
  const [showToDo, setShowToDo] = useState(false)
  const [note, setNote] = useState('')

  useEffect(() => {
    dispatch(setToDos())
  }, [dispatch])

  const onClickTodo = (event) => {
    event.preventDefault()
    dispatch(changeToDoState(event.target.id))
  }

  const onClickToggleToDo = () => {
    setShowToDo(!showToDo)
  }

  const handleAdd = (event) => {
    event.preventDefault()
    dispatch(newToDo({ note: note }))
    setNote('')
  }


  const handleInput = (event) => {
    event.preventDefault()
    setNote(event.target.value)
  }

  let todoList
  if(todos){
    todoList = todos.map(td => {
      return td.state !== 2 && <ToDoListItem key={td.id} note={td.note} state={td.state} id={td.id} onClickTodo={onClickTodo}/>
    })
  }

  return(
    <>
      { showToDo &&
        <div id="todo">
          <h3>Secret ToDo list</h3>
          <NewToDoInput handleAdd={handleAdd} handleInput={handleInput} note={note}/>
          <div id="todo-content">
            {todoList}
          </div>
        </div>
      }
      <div id="todo-toggler-button" onClick={onClickToggleToDo}>
        <span>ToDo</span>
      </div>
    </>
  )
}

const ToDoListItem = ({ note, state, id, onClickTodo }) => {

  const doneStyle = {
    borderLeft: '8px solid rgb(9, 255, 0)',
  }

  const workingOnStyle = {
    borderLeft: '8px solid yellow'
  }

  const notDoneStyle = {
    borderLeft: '8px solid red'
  }

  const styles = [notDoneStyle, workingOnStyle, doneStyle]

  return(
    <div style={styles[state]} className="todo-list-item" onClick={onClickTodo} id={id}>
      {note}
    </div>
  )
}

const NewToDoInput = ({ handleAdd, handleInput, note }) => {

  return(
    <div className="new-todo">
      <form onSubmit={handleAdd}>
        <input type="text" onChange={handleInput} value={note}/>
        <button >+</button>
      </form>
    </div>
  )
}

export default ToDo