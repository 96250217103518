import React from 'react'
import { useSelector } from 'react-redux'
import Title from '../../UI/Title/Title'
import langs from '../../../langs'
// import { useEffect } from 'react'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Home = () => {
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation
  //  const history = useHistory()
  // set landing page
  // useEffect(() => {
  //   history.push('/cards')
  // },[])

  // const dispatch = useDispatch()
  // const token = useSelector(state => state.user && state.user.token)

  return(
    <div>
      <Title text= {trans.homeTitle[lN]} icon="A"/>
    </div>
  )
}

export default Home