import React, { useEffect } from 'react'
import './PostList.css'
import Post from '../Post/Post'
import { useSelector } from 'react-redux'

const PostList = ({ posts, focusInput, setShowPostManager }) => {

  // map posts, order reversed in css
  const postsToDisplay = posts &&
  <div className = "flex-column-reverse">
    {posts.map(p => <Post setShowPostManager = {setShowPostManager} post={p} key={p.id} focusInput={focusInput}/>)}
  </div>

  // scroll to top, when posts are udpdated
  const threadId = useSelector(state => state.threads.currentThread.id)
  useEffect(() => {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' })
  },[posts,threadId])

  return(
    <>
      <div className = "postList-cont container">
        {postsToDisplay && postsToDisplay}
      </div>
      <div id="bottom"/>
    </>
  )
}

export default PostList