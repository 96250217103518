import axios from 'axios'
import { configurate } from './configurate'

const baseUrl = '/private/api/company'

// get all companies
export const getAllCompanies = async (token, companyId) => {
  try{
    const config = configurate(token)
    const companies = await axios.get(baseUrl + '/' + companyId, config)
    return companies.data
  } catch(err){
    console.log('error at getAllCompanies() : ', err)
  }
}

// create new company
export const createNewCompany = async (token, newAccount ) => {
  try{
    const config = configurate(token)
    const newcCompany = await axios.post(baseUrl, newAccount, config)
    const successMessage = {
      message: `New Company ${newcCompany.username} has been created`
    }
    return successMessage
  } catch(err){
    console.log('error at createNenCompany() : ', err)
  }
}

// update company
export const updateCompany = async (token, company, id) => {
  try{
    const config = configurate(token)
    const updatedCompany = await axios.put(baseUrl + '/' + id, company, config)
    console.log('updated company: ', updatedCompany.data)
    return updatedCompany.data
  } catch(err){
    console.log('error at updateCompany : ', err)
  }
}
