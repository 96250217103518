import {
  getTags,          postTag,        putTag,           deleteTag,
  postTagContent,   putTagContent,  deleteTagContent,
  postTagInTag,     putTagInTag,    deleteTaginTag
} from '../services/srvTags'


const tagsReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_TAGS':
    return action.data

  default: return state
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG REDUCERS -----------------------------|
// -------------------------------------------------------------------|

export const setTags = (token, companyId) => {
  return async dispatch => {
    const tags = await getTags(token, companyId)
    dispatch({
      type: 'SET_TAGS',
      data: tags
    })
  }
}

export const newTag = (token, body, tags) => {
  return async dispatch => {
    const tag = await postTag(token, body)
    // push new tag to tags array
    const updatedTags = [].concat(tags,tag)

    dispatch({
      type: 'SET_TAGS',
      data: updatedTags
    })
  }
}

export const updateTag = (token, body, id) => {
  return async dispatch => {
    const tags = await putTag(token, body, id)
    dispatch({
      type: 'SET_TAGS',
      data: tags
    })
  }
}

export const removeTag = (token, body, tags) => {
  return async dispatch => {
    const updated = await deleteTag(token, body, tags)
    dispatch({
      type: 'SET_TAGS',
      data: updated
    })
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG.CONTENT ------------------------------|
// -------------------------------------------------------------------|

export const newTagContent = (token, body, tags, tagIdId) => {
  return async dispatch => {
    const tagWithNewContent = await postTagContent(token, body, tagIdId)
    const newTags = tags.map(tag => {
      if(tag.id === tagIdId) {
        return tagWithNewContent
      } else return tag
    })
    dispatch({
      type: 'SET_TAGS',
      data: newTags
    })
  }
}

export const updateTagContent = (token, body, tags, tagId, contentId) => {
  return async dispatch => {
    const tag = await putTagContent(token, body, tagId, contentId)
    let updatedTags = tags
    if(tag) updatedTags = tags.map(t => {
      if(t.id !== tagId) return t
      else return tag
    })
    dispatch({
      type: 'SET_TAGS',
      data: updatedTags
    })
  }
}


export const removeTagContent = (token, tags, tagId, contentId) => {
  return async dispatch => {
    await deleteTagContent(token, tagId, contentId)
    const updatedTags = tags.map( t => {
      if(t.id !== tagId) {return t} else {
        const updatedContent =  t.content.filter(c => c.id !== contentId)
        t.content = updatedContent
        return t
      }
    })
    dispatch({
      type: 'SET_TAGS',
      data: updatedTags
    })
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG IN TAG -------------------------------|
// -------------------------------------------------------------------|

export const newTagInTag = (token, body, tags, tagId) => {
  return async dispatch => {
    const tagWithNewTags = await postTagInTag(token, body, tagId)
    const newTags = tags.map(tag => {
      if(tag.id === tagId) {
        return tagWithNewTags
      } else return tag
    })
    dispatch({
      type: 'SET_TAGS',
      data: newTags
    })
  }
}


export const updateTagInTag = (token, body, tags, tagId, tagIntagId) => {
  return async dispatch => {
    const tag = await putTagInTag(token, body, tagId, tagIntagId)
    let updatedTags = tags
    if(tag) updatedTags = tags.map(t => {
      if(t.id !== tagId) return t
      else return tag
    })
    dispatch({
      type: 'SET_TAGS',
      data: updatedTags
    })
  }
}

export const removeTaginTag = (token, tags, tagId, contentId) => {
  return async dispatch => {
    await deleteTaginTag(token, tagId, contentId)
    const updatedTags = tags.map( t => {
      if(t.id !== tagId) {return t} else {
        const updatedContent =  t.content.filter(c => c.id !== contentId)
        t.content = updatedContent
        return t
      }
    })
    dispatch({
      type: 'SET_TAGS',
      data: updatedTags
    })
  }
}
export default tagsReducer