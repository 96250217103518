import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSitemap, faSun, faTag, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from '../../../../reducers/themeReducer'
import IconButton from '../../IconButton/IconButton'
// import langs from '../../../../langs'
// import { useSelector } from 'react-redux'

const UserMenu = ({ toggleSettingsMenu }) => {
  // const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  // const trans = langs.lang // translation
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)


  const links =
    <>
      <div className = 'nav-close-button'>
        <IconButton
          char = 'x'
          className='icon-button-secondary'
          size = '20px'
          onClick={toggleSettingsMenu}
        />
      </div>
      <h4><FontAwesomeIcon icon={faUsersCog}/>
        <span style={{ marginLeft: '10px' }}>Lahja- ja sarjakortit</span>
      </h4>
      <Link to="/cards/settings">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Asetukset
        </div>
      </Link>
      <Link to="/cards/raports">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
         Raportit
        </div>
      </Link>
      <h4><FontAwesomeIcon icon={faTag}/>
        <span style={{ marginLeft: '10px' }}>Tagit</span>
      </h4>
      <Link to="/tags">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Tagit
        </div>
      </Link>
      <Link to="/tagcategories">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Kategoriat
        </div>
      </Link>
      <h4><FontAwesomeIcon icon={faSitemap}/>
        <span style={{ marginLeft: '10px' }}>Tuotehallinta</span>
      </h4>
      <Link to="/products">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Muokkaa tuotteita
        </div>
      </Link>

      <h4><FontAwesomeIcon icon={faSitemap}/>
        <span style={{ marginLeft: '10px' }}>Toimipisteet</span>
      </h4>
      <Link to="/locations">
        <div
          className="hover-pointer menu-link"
          onClick={toggleSettingsMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Muokkaa toimipisteitä
        </div>
      </Link>

      <div className="hover-pointer">
        {theme === '' ?
          <h4 onClick={ () => dispatch(setTheme('-dark') )}>
            <FontAwesomeIcon icon={faMoon}/> Vaihda teemaa
          </h4>:
          <h4 onClick={ () => dispatch(setTheme('') )}>
            <FontAwesomeIcon icon={faSun}/> Vaihda teemaa
          </h4>
        }
      </div>
    </>

  return(
    <div>
      <div id="user-menu" className={'menu' + theme}>

        {links}

      </div>
    </div>
  )
}

export default UserMenu