/* eslint-disable no-unexpected-multiline */
const filters = (customers, searchWord) => {

  let showCards
  if( customers && Array.isArray(customers) === true ) {
  // ------------ FILTERS --------------------------------------
    showCards = customers.filter
    (
      x => (x.name.toLowerCase().includes(searchWord.toLowerCase()))  // name
        || (x.sort.toLowerCase().includes(searchWord.toLowerCase()))  // by sort
    )
  }
  return showCards
}

export default filters