import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faUsersCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import langs from '../../../../langs'
import { useSelector } from 'react-redux'
import IconButton from '../../IconButton/IconButton'

const UserMenu = ({ logout, username, userRole, toggleUserMenu }) => {
  const companyId = useSelector(state => state.user.company)
  const companies = useSelector(state => state.companies)
  const theme = useSelector(state => state.theme)
  let companyName
  if( companies[0] !== undefined){
    if('companyName' in companies[0]) companyName = companies.filter(c => c.id === companyId)[0].companyName
  }
  const lN = useSelector(state => state.user.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  // Admin links
  const adminLinks =
    <>     <div className = 'nav-close-button'>
      <IconButton
        char = 'x'
        className='icon-button-secondary'
        size = '20px'
        onClick={toggleUserMenu}
      />
    </div>
    <h4><FontAwesomeIcon icon={faUsersCog}/>
      <span style={{ marginLeft: '10px' }}>{trans.UserManagement[lN]}</span>
    </h4>
    <Link to="/admin/users">
      <div
        className="hover-pointer menu-link"
        onClick={toggleUserMenu}
        id="user-admin-button"
        data-testid="user-admin-button"
      >
        {trans.users[lN]}
      </div>
    </Link>
    <Link to="/admin/newuser">
      <div
        className="hover-pointer menu-link"
        onClick={toggleUserMenu}
        id="new-user-button"
        data-testid="new-user-button"
      >
        {trans.newUser[lN]}
      </div>
    </Link>
    <Link to="/admin/invite">
      <div
        className="hover-pointer menu-link"
        onClick={toggleUserMenu}
        id="invite-users-button"
        data-testid="invite-users-button"
      >
        {trans.userInvitations[lN]}
      </div>
    </Link>
    <Link to="/admin/trash">
      <div
        className="hover-pointer menu-link"
        onClick={toggleUserMenu}
        id="trashcan-button"
        data-testid="trashcan-button"
      >
        {trans.removedUsers[lN]}
      </div>
    </Link>
    </>

  // User Accounts Manager links
  const superAdminLinks =
    <>
      <h4><FontAwesomeIcon icon={faUsersCog}/>
        <span style={{ marginLeft: '10px' }}>Tilien hallinta</span>
      </h4>
      <Link to="/accounts/accounts">
        <div
          className="hover-pointer menu-link"
          onClick={toggleUserMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Yritystilit
        </div>
      </Link>
      <Link to="/accounts/newAccount">
        <div
          className="hover-pointer menu-link"
          onClick={toggleUserMenu}
          id="user-admin-button"
          data-testid="user-admin-button"
        >
          Lisää uusi yritystili
        </div>
      </Link>
    </>

  return(
    <div>
      <div id="user-menu" className={'menu' + theme}>
        <h4><FontAwesomeIcon icon={faUserCircle}/><span style={{ marginLeft: '12px' }}>{username} / {companyName}</span></h4>
        <Link to="/profile">
          <div
            className="hover-pointer menu-link"
            onClick={toggleUserMenu}
            id="user-profile-button"
            data-testid="user-profile-button"
          >
            {trans.ownInformation[lN]}
          </div>
        </Link>
        { (userRole === 'admin' || userRole === 'superadmin') && adminLinks}
        { (userRole === 'superadmin') && superAdminLinks}
        <Link to="/">
          <div
            onClick={logout}
            className="hover-pointer menu-link"
            id="sign-out-button"
            data-testid="sign-out-button"
          > <div>
              <FontAwesomeIcon icon={faSignOutAlt}/>
              {' ' + trans.logOut[lN]}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default UserMenu