// Converting string with line breaks (\n) to html line break
// Antti Haikonen 5.1.2022

import React from 'react'

const Text = ({ data }) => {
  // split string to array divided by line break -> map it to html element
  const txt = data.split('\n').map( item => {
    return (
      // that damn keyvalue must be
      <span key={ Math.floor(Math.random() * 100000000000000)}>
        {item}
        <br/>
      </span>
    )
  })
  return txt
}

export default Text