import React from 'react'
import './Item_TitleData.css'

const Item_TitleAndData = ({ title, data, data2, data3, data4, data5 }) => {
  let titleStyle = 'flex-container-info'
  if(title.toString().length < 3) {titleStyle = 'item-column-short'}
  return(
    <div className="card-list">
      <div className={titleStyle}>{title}</div>
      <div className="flex-item-right">{data}</div>
      { data2 && <div className="flex-item-right">{data2}</div>}
      { data3 && <div className="flex-item-right">{data3}</div>}
      { data4 && <div className="flex-item-right">{data4}</div>}
      { data5 && <div className="flex-item-right">{data5}</div>}
    </div>
  )
}

export default Item_TitleAndData
