import React, { useEffect, useState } from 'react'
import ExpirySettings from './ExpirySettings/ExpirySettings'
import { useDispatch, useSelector } from 'react-redux'
import Title from '../../../../components/UI/Title/Title'
import PayingMethodSettings from './PayingMethodSettings/PayingMethodSettings'
import Loader from '../../../../components/UI/Loader/Loader'
import { getCards } from  '../../reducers/cardsReducer'

// !! hotbuttons and products are moved to core function
// hotbuttons are now created using product value

const SettingsHolder = () => {
  const dispatch = useDispatch()
  const user = useState(useSelector(state => state.user))[0]
  useEffect(() => {
    dispatch(getCards( user.token, user.company))
  }, [user, dispatch])

  const cards = useState(useSelector(state => state.users && state.cards))
  return(
    (cards[0] !== 'noAccountYet') ?
      <div>
        {/* {showModal && modal} */}
        <Title text="Kortit - Asetukset" icon="m"/>
        <ExpirySettings/>
        <PayingMethodSettings/>
      </div>
      :
      <Loader
        title= "Toiminto ei onnistunut"
        text= "Jos olet luonut uuden tilin, luo ensin etusivulla tietokanta korteille."
      />
  )
}

export default SettingsHolder