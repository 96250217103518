import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../UI/Loader/Loader'
import AppHeader from './components/AppHeader'
import Tag from './components/Tag'

const Tags = () => {

  // selectors
  const tags = useSelector(state => state.users && state.tags)

  // states
  const [selectedID,     setSelectedID]            = useState(null)
  const [bodyContainer,  setBodyContainer]         = useState(null)
  const [filteredTags,   setFilterdTags]           = useState(tags)
  const [searchWord, setSearchWord] = useState('')

  // restore filters if new tag is created or changed
  useEffect(() => {
    setFilterdTags(tags)
  },[tags])

  if(tags) {return(
    <div className="container">
      <AppHeader
        selectedID={selectedID}
        filteredTags = {filteredTags}
        setFilterdTags = {setFilterdTags}
        bodyContainer = {bodyContainer}
        setBodyContainer = {setBodyContainer}
        searchWord = {searchWord}
        setSearchWord = {setSearchWord}
      />
      <div className = "flex-column-reverse mp-mt-20">
        {filteredTags && filteredTags.slice(0,50).map(tag => {
          return(
            <div key={tag.id}>
              <Tag
                key={tag.key}
                tag={tag}
                selectedID = {selectedID}
                setSelectedID = {setSelectedID}
                bodyContainer = {bodyContainer}
                setBodyContainer = {setBodyContainer}
                searchWord = {searchWord}
                setSearchWord = {setSearchWord}
              />
            </div>
          )
        })}
      </div>

    </div>

  )} else return <Loader/>
}

export default Tags