import React from 'react'
import ButtonTxtIcon from '../../../../../../components/UI/ButtonTxtIcon/ButtonTxtIcon'
// import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import Title from '../../../../../../components/UI/Title/Title'

const Body_ThankYou = ({ newCustomer, thanks, setBodyContainer, setThanks }) => {

  const Line = ({ title, data }) => {
    return(
      <div className = "card-list even-odd">
        <div className="flex-container-info">{title}</div>
        <div className="flex-item-right">{data}</div>
      </div>
    )
  }

  return(
    thanks === 'yes' ?
      <div className = "new-customer-container">
        <div className = 'new-customer'>
          <div className='flex-column-container'>
            {/* Link out is hidden in title */}
            <div onClick = { () => {setBodyContainer && setBodyContainer('')}}>
              <Title text = "Kiitos!" icon="u"/>
            </div>
            <p>
          Tiedot on nyt talletettu järjestelmään. Voit vielä tarkistaa tiedot alta. Kun olet valmis,
          anna tabletti / tietokone takaisin työntekijälle.
            </p>
            <Line title = "Etunimi" data = {newCustomer.firstName} />
            <Line title = "Sukunimi" data = {newCustomer.lastName} />
            <Line title = "Puh:nro" data = {newCustomer.phone} />
            <Line title = "Email" data = {newCustomer.email} />
            <Line title = "Osoite" data = {newCustomer.address} />
            <Line title = "Sairaudet" data = {newCustomer.disease} />
            <Line title = "Allergiat" data = {newCustomer.allergy} />
            <Line title = "Lääkitykset" data = {newCustomer.medicines} />
            <Line title = "Muut kontraindikaatiot" data = {newCustomer.contraindication} />
            <Line title = "Ammatti" data = {newCustomer.profession} />
            <Line title = "Syntymäaika" data = {newCustomer.birthDate} />
            <Line title = "SOTU loppuosa" data = {newCustomer.sosId} />
            <Line title = "SOTU loppuosa" data = {newCustomer.sosId} />
            <Line title = "Harrastukset" data = {newCustomer.hobby} />
            <Line title = "Lisätiedot" data = {newCustomer.info} />
            <Line title = "Marrkinointilupa" data = {newCustomer.marketingPermission} />
            <Line title = "Työntekinjöiden oikus nädä tietosi" data = {newCustomer.rightsForAllStaff} />


          </div>
        </div>
      </div>:
      <div className = "new-customer-container">
        <div className = 'new-customer'>
          <div className='flex-column-container'>
            {/* Link out is hidden in title */}
            <div onClick = { () => {setBodyContainer &&  setBodyContainer('')}}>
              <Title text = "Painoit peruuta nappia!" icon="S"/>
            </div>
            <div onClick = {() => setThanks('no')}>
              <p >Jos se oli vahinko, voit palata tästä takaisin</p>
              <ButtonTxtIcon
                label = "Takaisin"
                className = "buttonGreen"
                icon = "E"
              />
            </div>
          </div>
        </div>
      </div>

  )
}
export default Body_ThankYou

//         info: info,
//         sosId: sosId,
//         firstName: firstName,
//         lastName: lastName,
//         email: email,
//         phone: phone,
//         address: address,
//         disease: disease,
//         contraindication: contraindication,
//         profession: profession,
//         allergy: allergy,
//         birthDate: birthDate.date + '.' + birthDate.month + '.' + birthDate.year,
//         hobby: hobby,
//         company:user.company,
//         marketingPermission: marketingPermission,
//         rightsForAllStaff: rightsForAllStaff
