import { setNotification } from '../../../UI/Notification/notificationReducer'
import {
  getProducts, postProduct, putProduct, deleteProduct
} from '../services/srvProducts'


const productsReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_PRODUCTS':
    return action.data

  default: return state
  }
}

// get products

export const get_products = (token, companyId) => {
  return async dispatch => {
    let products = await getProducts(token, companyId)
    products = products.sort(function(a, b) {
      var keyA = a.productName,
        keyB = b.productName
      // Compare the 2 dates
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
    dispatch({
      type: 'SET_PRODUCTS',
      data: products
    })
  }
}

// post product
export const post_product = (token, body, products) => {
  return async dispatch => {
    const newProduct = await postProduct(token, body)
    // merge new product to existing ones
    const updatedProducts = [].concat(products, newProduct)
    dispatch({
      type: 'SET_PRODUCTS',
      data: updatedProducts
    })
  }
}

// put product
export const put_product = (token, body, products, productId) => {
  return async dispatch => {
    // updated product from db
    const updatedProduct = await putProduct(token, body, productId)

    // mappding updated product to exsisting products array
    const updatedProducts = products.map( product => {
      if(product.id === productId) return updatedProduct
      else return product
    })
    dispatch({
      type: 'SET_PRODUCTS',
      data: updatedProducts
    })
  }
}

// delete product
export const delete_product = (token, products, productId) => {
  return async dispatch => {
    // we dont need respond from server
    const query = await deleteProduct(token, productId)
    const updatedProducts = products.filter( product => product.id !== productId)
    if(query.status === 200){
      setTimeout( () => { dispatch(setNotification({ notification: 'Tuote on poistettu!' })) } ,500 )
      dispatch({
        type: 'SET_PRODUCTS',
        data: updatedProducts
      })
    } else setTimeout( () => { dispatch(setNotification({ notification: 'Hempura, jotain meni pieleen! Kokeile uudestaan myöhemmin!' })) } ,500 )
  }
}

export default productsReducer