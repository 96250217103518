const setUser = (key, item, ttl) => {
  item.expiry = ttl
  window.localStorage.setItem(key, JSON.stringify(item))
}

const getUser = () => {
  const userFromStorage = window.localStorage.getItem('hku')
  if(userFromStorage){
    const now = new Date()
    const itemExpiry = new Date(JSON.parse(userFromStorage).expiry)
    const loggedIn = now > itemExpiry ? false : true
    return loggedIn ? userFromStorage : null
  }
  return null
}

export default { setUser, getUser }