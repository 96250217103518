import React, { useState }  from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../../components/UI/Button/Button'
import { addNewAppointment } from '../../../reducers/rdc-oneCustomer'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'
import HelpPop from '../../../../../components/UI/HelpPop/HelpPop'
import TextArea from '../../../../../components/UI/TextArea/TextArea'
import IconButton from '../../../../../components/UI/IconButton/IconButton'


const Body_NewAppointment = ({ setBodyContainer }) => {
  const dispatch = useDispatch()

  // selectors
  const user = useSelector(state => state.user)
  const users = useSelector(state => state.users.allUsers)
  const customer =  useSelector(state => state.users && state.oneCustomer)
  const help =      useSelector(state => state.help.show)
  const products =      useSelector(state => state.products)
  const locations = useSelector(state => state.locations)

  // states
  const [treatment, setTreatment] = useState('')
  const [notes, setNotes] = useState('')
  const [instructions, setInstructions] = useState('')
  const [product, setProduct]  = useState('Valitse tuote')
  const [check, setCheck] = useState('')
  const [worker, setWorker] = useState(user.firstname + ' ' + user.lastname)
  const [location, setLocation] = useState('')

  //consts
  const token = user.token
  const id = customer.id

  const handleAddNewAppointment = (event) => {
    event.preventDefault()
    if(product !== 'Valitse tuote'){
      const body =
      {
        staff_ID: user.firstname + ' ' + user.lastname,
        treatment: treatment,
        instructions: instructions,
        product: product,
        location: location,
        notes: notes,
        check: check
      }
      const newAppointment = addNewAppointment( token, body, id )
      dispatch(newAppointment)  //add new appointment
      dispatch(setNotification({
        notification: 'Uusi kirjaus on tehty',
        type: 'default' }, 3500))
      setBodyContainer('1')

    }
  }
  const submitStyle = () => {
    if(product !== 'Valitse tuote' && treatment !== '') {
      return ['buttonGreen','Tallenna'] } else { return  ['buttonNoEnter','Täytä ensin pakolliset tiedot'] }
  }
  return(
    <>
      <h4 className="body-header">Tee uusi kirjaus</h4>
      {help === true &&
      <div className="flex-row-container mp-center">
        <IconButton className="icon-button-secondary" char="S"/>
        <p>Täytä hoidon tiedot alle. <br/> Työntekijän nimi tallentuu kirjautuneen käyttäjän mukaan.</p>
      </div>}
      <form onSubmit={handleAddNewAppointment} className="flex-column-container" style={{ marginTop: 0, paddingTop: 0 }}>

        {/* Worker */}
        <div className = "input-group">
          <label>Työntekijä
            <HelpPop
              text="Jos työntekijää ei löydy listasta, sen voi muuttaa myöhemmin"
              position = 'right'
            />
          </label>
          <select onChange={(e) => {setWorker(e.target.value)}}>
            <option value={worker}>{worker}</option>
            {users.map(user => {
              return(
                <option key = {user.id} value = {user.firstName + ' ' + user.lastname}>
                  {user.firstname + ' ' + user.lastname}
                </option>
              )
            })}
          </select>
        </div>

        {/* location */}
        <div className = "input-group">
          <label>Toimipiste
            <HelpPop
              text="Valitse toimipiste, jolla asiastapaaminen oli"
              position = 'right'
            />
          </label>
          <select onChange={(e) => {setLocation(e.target.value)}}>
            <option value={location.name}>{location.name}</option>
            {locations.map(loc => {
              return(
                <option key = {loc.id} value = {loc.id}>
                  {loc.name}
                </option>
              )
            })}
            <option value="Kotikäynti">Asiakkaan luona</option>
            <option value="Muu">Muu - Lisää tieto hoidon kuvaukseen</option>
          </select>
        </div>

        {/* treathment */}
        <div className = "input-group">
          <label>Hoidon kuvaus </label>
          <TextArea
            onChange={(e) => {setTreatment(e.target.value)}}
            className = "passed"
            value = {treatment}
            placeholder='Hoidon kuvaus '
            trickers ={[treatment]}
          />
        </div>

        {/* Check */}
        <div className = "input-group">
          <label>Tutkimus/havainnot</label>
          <TextArea
            onChange={(e) => {setCheck(e.target.value)}}
            className = "passed"
            value = {check}
            placeholder='Tutkimus/havainnot'
            trickers ={[check]}
          />
        </div>

        {/* innsturctions */}
        <div className = "input-group">
          <label>Annettu ohjeistus</label>
          <TextArea
            onChange={(e) => {setInstructions(e.target.value)}}
            className = "passed"
            value = {instructions}
            placeholder='Annettu ohjeistus'
            trickers ={[instructions]}
          />
        </div>


        {/* notes */}
        <div className = "input-group">
          <label>Muistiinpanot / Ensi kerralla</label>
          <TextArea
            onChange={(e) => {setNotes(e.target.value)}}
            className = "passed"
            value = {notes}
            placeholder='Muistiinpanot / Ensi kerralla'
            trickers ={[notes]}
          />
        </div>

        {/* product */}
        <div className = "input-group">
          <label>Valitse tuote *</label>
          { (products.filter(p => p.productName === product ) !== [] || product === 'Valitse tuote') &&
          <select onChange={(e) => {setProduct(e.target.value)}}>
            <option value="Valitse tuote">Valitse tuote</option>
            {products.filter(prod => prod.locationId === location).map(product => {
              return(
                <option key = {product.id} value = {product.productName}>
                  {product.productName}
                </option>
              )
            })}
            <option value = "">Muu</option>
          </select>}

          { !products.filter(p => p.productName === product )[0] && product !== 'Valitse tuote' &&
        <input className = "mp-mt-20" value = {product}  placeholder = "Kirjoita tuote" onChange = {(e) => {setProduct(e.target.value)}} />
          }
        </div>
        <div className = "input-gropu">
          <span>* Tähdellä merkityt ovat pakollisia kenttiä</span><br/>
          <span>• Tageja voi lisätä kun kirjaus on tallennettu</span>
        </div>

        {/* Buttons */}
        <div className="flex-row-container">
          <Button
            className = {submitStyle()[0]}
            label = {submitStyle()[1]}
            type = 'buttonGreen'
            icon = 'update'
          />

          <div onClick={ () => { setBodyContainer('') }}>
            <Button
              type = "buttonNoEnter"
              label='Peruuta'
              icon='undo'
            />
          </div>
        </div>
      </form>
    </>
  )
}
export default Body_NewAppointment