/* eslint-disable no-unexpected-multiline */
const filters = (giftCardData, expiredFilter, emptyFilter, showCardNro, showDeactivated, now) => {

  let showCards
  if( giftCardData && Array.isArray(giftCardData) === true ) {
  // ------------ FILTERS --------------------------------------
    showCards = giftCardData.filter
    (
      x => (x.num && x.num.toString().includes(showCardNro)) // by Card Nro
        || (x.customerName && x.customerName.toLowerCase().includes(showCardNro.toLowerCase()))
        || (x.receipt && x.receipt.toLowerCase().includes(showCardNro.toLowerCase()))  // by receipt
        || (x.seller && x.seller.toLowerCase().includes(showCardNro.toLowerCase())) // by seller
    )

    // show active / deactivated toggle
    if(showDeactivated === false) { showCards = showCards.filter (x => x.active === true) }
    else                          { showCards = showCards.filter (x => x.active === false) }

    // show used toggle
    if(emptyFilter === false)     { showCards = showCards.filter (x => x.amount > x.totalUsage) }
    else                          { showCards = showCards.filter (x => x.amount <= x.totalUsage) }

    // filter by expired
    if(expiredFilter === true)    { showCards = showCards.filter ( x =>
      Number(                       x.expiryDate.slice(0,4).toString() +
                                  x.expiryDate.slice(5,7).toString() +
                                  x.expiryDate.slice(8,10).toString()) <= Number(now))
    } else {
      showCards =                   showCards.filter ( x =>
        Number(                     x.expiryDate.slice(0,4).toString() +
                                  x.expiryDate.slice(5,7).toString() +
                                  x.expiryDate.slice(8,10).toString()) >= Number(now))
    }
    // if(showCardNro === '') showCards = []

  // if(showCardNro === 'all' && showDeactivated === false){
  //    showCards = giftCardData.filter (x => x.active === true)}
  }

  return showCards
}

export default filters