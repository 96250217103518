import React from 'react'
import filters from './filters'
import UiCard from '../../../../components/UI/UiCard/UiCard'
import { useSelector } from 'react-redux'
import BodyNewTag from './BodyComponents/BodyNewTag/BodyNewTag'
import Title from '../../../UI/Title/Title'
import IconButton from '../../../UI/IconButton/IconButton'

const AppHeader = ({ searchWord, setSearchWord, selectedID, setFilterdTags, setBodyContainer, bodyContainer }) => {


  // -------- handler to apply filters from search form ----------------
  const handleFilter = (event) => {
    setSearchWord(event.target.value.toString())
    setFilterdTags(filters(tags, event.target.value.toString()))
  }
  const tags = useSelector(state => state.tags)

  // ----------------------- RETURN PAGE ------------------------------
  return(
    <div>
      {
        selectedID === null && bodyContainer !== '0'
          &&
          <><div className="add-item-search-top-bar">
            {/* -------------------- SEARCH INPUT  ------------------ */}

            <IconButton
              char="j"
              className="icon-button-main"
              onClick={() => { setBodyContainer('0') } } />
            <input className = 'input-cardholder-find theme' value={searchWord} onChange={handleFilter} placeholder='etsi tagia' />

            {searchWord !== '' &&
            <IconButton
              char="x"
              className="icon-button-gray"
              size="30px"
              onClick={() => {
                setSearchWord('')
                setFilterdTags(tags, '')
              } } />}

            {/* ------------- BUTTON - add new Tag  ----------------- */}


          </div><Title text="Tagit" icon="I" /></>
      }

      {/* -------------FORM to add new Tag -------------------*/}
      {
        bodyContainer === '0' &&
          <div>
            <div/>
            <UiCard
              headerTitle = "Lisää uusi tagi"
              headerInfo = ""
              footerInfo = "joku ohje?"
            >
              <BodyNewTag setFilterdTags = {setFilterdTags} setBodyContainer = {setBodyContainer} tags = {tags} setSearchWord={setSearchWord}/>
            </UiCard>
          </div>
      }
    </div>

  )
}
export default AppHeader