import React from 'react'
import './PublicHeader.css'
import { Link } from 'react-router-dom'

const PublicHeader = () => {
  return(
    <div id="public-header">
      <Link to="/">
        <div className="logo-login"/>
      </Link>
    </div>
  )
}

export default PublicHeader