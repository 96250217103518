// scroll to wanted y position, if behaviour is not se, it is smooth

const srcollTo = (yCoord, smooth) => {
  let y = 0
  if(yCoord){y = 0}
  let behaviour = 'smooth'
  if(smooth) {behaviour = smooth}

  window.scrollTo({ top: y,  behavior: behaviour })
}

export default srcollTo