//-------------------------------------------------------------------------|
//  BodyComponents is takin care of all components visible in UIcard body  |
//-------------------------------------------------------------------------|

import React from 'react'
import BodyEdit from './BodyEdit/BodyEdit'
import BodyBasicData from './BodyBasicData/BodyBasicData'
import BodyInTags from './BodyInTags/BodyInTags'
import { newTagInTag } from '../../reducers/tagsReducer'
import { useDispatch } from 'react-redux'

const BodyComponents = ({ tag, bodyContainer, setSelectedID, selectedID, setBodyContainer }) => {
  const dispatch = useDispatch()

  //---------------------------------------------------------|
  // dispatch function is sent to bodyNewTag, because then   |
  // bodyInTag is possible to reuse in other components.     |
  //                                                         |
  //  • body = body of new tag                               |
  //  • tags = tags from selector                            |
  //  • id = item.id (in this component tag.id)              |
  //                                                         |
  const saveTags = (token, body, tags, itemId) => {        //|
    dispatch(newTagInTag(token, body, tags, itemId))       //|
  }                                                        //|
  //---------------------------------------------------------|

  return(
    <>
      { tag &&
      <div className="customer-body-container">
        {bodyContainer === '1' &&
        <BodyEdit tagId = {tag.id} selectedID={selectedID} setBodyContainer={setBodyContainer} key="1" />}
        {bodyContainer === '2' &&
        <BodyBasicData tag = {tag} setSelectedID = {setSelectedID} selectedID={selectedID} setBodyContainer={setBodyContainer} key="2"/>}
        {bodyContainer === '3' &&
        <BodyInTags
          itemId = {tag.id}
          items = {tag.tags}
          selectedID={selectedID}
          key="3"
          saveTags = {saveTags}
          title = "Tagin sisäiset tagit"
          sortBy = {tag.mainCategory}
          sortByCategory = {tag.category}
        />}
      </div>
      }
    </>
  )
}
export default BodyComponents