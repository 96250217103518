// /* eslint-disable indent */
import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/cards'

// ----------------------GET CARDS --------------------------->

export const cardsGet = async (token, companyId) => {
  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl + '/' + companyId, config)
    return response

  } catch(err){
    console.log('Umhh... error at cardsServices/cardsGet: ', err)
  }
}

// ---------------------- UPDATE CARD --------------------------->

export const cardUpdate = async (token, body, cardId ) => {
  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/updatecard/'  + cardId, body, config)
    return response

  } catch(err){
    console.log('dong... error at cardsServices/cardUpdate: ', err)
  }
}

// ---------------------- ADD NEW CARD --------------------------->
// when addaning new card, server is also checking if there are cards to archive.
// Cards are being archived, if they are 2 year older than expirydate.
export const cardAdd = async (token, companyId, body) => {
  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl + '/newcard/'  + companyId, body, config)
    return response
  } catch(err){
    console.log('Voi nuhnuh... error at cardsServices/cardAdd: ', err)
  }
}

// ---------------------- DELETE CARD --------------------------->

export const cardDdelete = async (token, cardId) => {
  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/deletecard/'  + cardId, config)
    return response
  } catch(err){
    console.log('puff!... error at cardsServices/cardDdelete: ', err)
  }
}



// ---------------------- ADD NEW ACCOUNT ------------------------->

export const cardNewAccount = async (token, companyId, body) => {
  try{
    const config = configurate(token)

    // default values for company
    const accountBody = {
      company: companyId,
      nextCardNumber: body.nextCardNumber,
      expiryTime: body.expiryTime
    }

    const response = await axios.post(baseUrl, accountBody, config)
    return response
  } catch(err){
    console.log('argh... error at cardsServices/cardNew: ', err)
  }
}

// ---------------------- UPDATE ACCOUNT ------------------------->

export const cardUpdateAccount = async (token, accountId, body) => {
  try{
    const config = configurate(token)
    const response = await axios.put(baseUrl + '/' + accountId, body, config)
    return response

  } catch(err){
    console.log('yrmh... error at cardsServices/cardUpdateAccount: ', err)
  }
}

// ---------------------- DELETE (OR RETURN) CARD USAGE --------------->
// router.delete('/:cardId/:usageId', async (req, res) => {

export const carUsageDelete = async (token, cardId, usageId) => {
  try{
    const config = configurate(token)
    const response = await axios.delete(baseUrl + '/usage/' + cardId + '/' +  usageId, config)
    return response

  } catch(err){
    console.log('plah... error at cardsServices/carUsageDelete: ', err)
  }
}

// -----------------------Add new product ------------------------------->
// router.post('/product/:id', async (req,res) => {
export const cardProductAdd = async (token, accountId, body) => {
  try{
    const config = configurate(token)
    const response = await axios.post(baseUrl + '/product/' + accountId, body, config)
    return response

  } catch(err){
    console.log('plah... error at cardsServices/carProductAdd: ', err)
  }
}

// -----------------------delete product ------------------------------->
// router.post('/product/:id', async (req,res) => {
export const cardProductDelete = async (token, accountId, productId) => {
  try{
    const config = configurate(token)
    const response = await axios.delete(baseUrl + '/product/' + accountId + '/' + productId , config)
    return response

  } catch(err){
    console.log('plah... error at cardsServices/cardProductDelete: ', err)
  }
}

// -----------------------Add new paying method ------------------------->
// ------- router.post('/payingMethod/:id', async (req,res) => { -------->
export const cardPayingMethodAdd = async (token, accountId, body) => {
  try{
    const config = configurate(token)
    const response = await axios.post(baseUrl + '/payingMethod/' + accountId, body, config)
    return response

  } catch(err){
    console.log('plah... error at cardsServices/payingMethod: ', err)
  }
}

//router.delete('/payingMethod/:accountId/:methodId', async (req,res) => {
export const cardPayingMethodDelete = async (token, accountId, methodId) => {
  try{
    const config = configurate(token)
    const response = await axios.delete(baseUrl + '/payingMethod/' + accountId + '/' + methodId , config)
    return response

  } catch(err){
    console.log('plah... error at cardsServices/cardProductDelete: ', err)
  }
}