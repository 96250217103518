import React from 'react'
import UiCard from '../../../../components/UI/UiCard/UiCard'
import BodyButtons from './BodyButtons'
import BodyComponents from './BodyComponents/BodyComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faPaw } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { emptyCustomerState } from '../../reducers/rdc-oneCustomer'
import { useSelector } from 'react-redux'
import IconButton from '../../../../components/UI/IconButton/IconButton'

const Customer = ({ setFilterdCustomers, setSearchWord, searchWord, customer, customers, setCustomers, selectedID, setSelectedID, bodyContainer, setBodyContainer }) => {
  const oneCustomer = useSelector(state => state.oneCustomer)
  const dispatch = useDispatch()
  const FooterInfo = () => {
    const contraIndications = oneCustomer && (oneCustomer.diseases || oneCustomer.contraindication) && <> {oneCustomer.disease}, {oneCustomer.contraindication} </>
    const info = customer.info ? <>{customer.info} <br/> </> : ''

    return(
      <div style={{ justifyContent: 'flex-start' }}>
        {contraIndications && <p style={{ marginTop: '3px', marginBottom: '3px' }}><b>Mahd. vasta-aiheet:</b> {contraIndications}</p> } {info}
      </div>

    )
  }
  return(
    <>
      {/* Back button */}
      { selectedID === customer.id &&
      <div className = "UIcard-exit-button">
        <IconButton
          onClick={() => {
            setSelectedID(null)
            setBodyContainer(null)
            dispatch(emptyCustomerState())
          }}
          className="icon-button-secondary"
          char="x"
          size="20px"
        />
      </div>
      }
      {/* if no customer is chosen or if customers id is same show content */}
      { selectedID === null | selectedID === customer.id ?
        <UiCard
          headerTitle = {<div className="customer-card-title">{ customer.firstName} {customer.lastName}</div>}
          footerInfo = {<FooterInfo/>}
          footerInfoRight = {
            <>
              <FontAwesomeIcon icon={faHeart}/>{' '}{customer.birthDate}
              <FontAwesomeIcon icon={faPaw}/>{' '}{customer.appointmentsAmount}
            </>}
          onClick = {() => setBodyContainer('1')}
        >
          <BodyButtons
            customer = {customer}
            setBodyContainer = {setBodyContainer}
            bodyContainer = {bodyContainer}
            selectedID = {selectedID}
            setSelectedID = {setSelectedID}
          />

          <div className="customer-body-container">
            <BodyComponents
              bodyContainer = {bodyContainer}
              setSelectedID = {setSelectedID}
              selectedID  = {selectedID}
              setBodyContainer  = {setBodyContainer}
              setCustomers = {setCustomers}
              customers = {customers}
              searchWord = {searchWord}
              setSearchWord = {setSearchWord}
              setFilterdCustomers = {setFilterdCustomers}
            />
          </div>

        </UiCard>
        :
        null
      }
    </>
  )
}
export default Customer