/* eslint-disable no-unexpected-multiline */
import React, { useEffect, useState } from 'react'
import './GiftCard.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateCard } from '../reducers/cardsReducer'
import Wrapper from '../../../components/UI/Wrapper/Wrapper'
import Button from '../../../components/UI/Button/Button'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'
import HelpText from '../../../components/UI/HelpText/HelpText'
let overDueStyle

const onlyNumbers = (value) => { return /^\d*\.?\d*$/.test(value) }   // to check is it number
const GiftCardUseHolder = ({ card, giftNavigate, setIdSelected, visits, setVisits
}) =>
{

  // -------------------------------- states from store ------------------------------->

  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)
  const user = useSelector(state => state.user)
  const users = useSelector(state => state.users && state.users.allUsers)
    .filter(user => user.role !== 'customer') // asiakas is not a worker
  const help = useSelector(state => state.help)

  const hotButtons = useSelector(state => state.products)

  // -------------------------------- S T A T E S ------------------------------------->

  const [usageAmount,   setUsageAmount]    = useState('')
  const [worker,        setWorker]         = useState('')
  const [style,         setStyle]          = useState('underheader-amount-remaining')

  // order buttons from smallest to biggest
  let buttonsOrdered
  if (hotButtons !== undefined && hotButtons !== null) {
  // order them
    buttonsOrdered = hotButtons.map(x => x.productValue).sort(function(a, b){return a - b})
    // filter douples
    buttonsOrdered =  [...new Set(buttonsOrdered)]
  }

  // ----------------------------------- UPDATE HANDLE --------------------------------->

  const handleUpdateCard = (event) => {
    event.preventDefault()
    if(showSubmit() === true){
      const amountToAdd = Math.round(( Number(usageAmount))*100)/100
      const usageToadd = {
        amount: amountToAdd,
        worker: worker,
        usageDate: new Date()
      }

      // update usage array of card
      let currenUsage
      if(card.usage.length > 0) {currenUsage = card.usage.concat(usageToadd)
      } else currenUsage = [usageToadd]

      // Card object to update
      const cardObject =
    {
      totalUsage: Math.round((Number(card.totalUsage) + Number(usageAmount))*100)/100,
      usage: currenUsage
    }

      dispatch(updateCard(user.token, cardObject, card.id, cards))  // update cards to DB

      // pop notification about update
      dispatch(setNotification({
        notification:  ((Number(usageAmount) + Number(card.totalUsage)) === Number(card.amount) ) ? `Kortti numero ${card.num} on käytetty loppuun!` : `Kortin nro. ${card.num} käyttö  onnistui`,
        type: 'default' }))
      setUsageAmount(0)
      giftNavigate(0)
      setIdSelected('')

      // setButtonUse("defaultButton")
      if(card.productAmount > 0) setVisits(visits+1)
    }
  } // <------------------- END OF UPDATE HANDLE ------------------------------------<


  // --------------------- show submit submit if all passed ------------------------->
  const showSubmit = () => {
    if(
      usageAmount === ''
      || (Number(usageAmount) + Number(card.totalUsage)) > Number(card.amount)
      || usageAmount === '0'
      || worker === ''
      || usageAmount < 1)

    {return false } else {return true}
  }
  // --------------------- SET WORKER --------------------------------------------->

  const handlerSetWorker = (event) => {
    // to update usageAmount state, if product is choosen.
    if(Number(card.productAmount) > 0) {setUsageAmount(Number(card.amount)/Number(card.productAmount))}
    setWorker(event.target.value)
  }
  // --------------------- USAGE AMOUNT HANDLER ------------------------------------>

  const setUsageAmountHandler = (event) => {
    // replace "," -> "." for numbers
    let str = (event.target.value).replace(',','.')
    // must be a number | no dot on start
    if(onlyNumbers(str) === true && str !== '.' ) {
      setUsageAmount(str)

    }

  }
  // --------------------- FONT SIZE POP-EFFECT WHEN AMOUNT IS CHANGED ------------------>
  //                      ( so user will notice that value is chancing )

  useEffect(() => {
    setStyle('underheader-amount-remaining-scaled')
    setTimeout(() => {setStyle('underheader-amount-remaining')},100)
  },[usageAmount])

  // --------------------- CHANGE TITLE STYLE IF AMOUNT IS TOO BIG ---------------------->

  if((card.amount - card.totalUsage - usageAmount)  < 0 ) {overDueStyle = { color: 'white', backgroundColor: 'var(--not-passed-color)' }}
  else {overDueStyle = null}

  // --------------------- MODULE RETURN ------------------------------------------------>
  // ------------------------------------------------------------------------------------>

  return (

    <>
      {/* Title */}
      <h4 className="body-header" style={overDueStyle}>
             Veloitus
        { card.productAmount > 0
          // if product exists show amount of products, else amount (€)
          ? <span className={style}><span className ="buttonTxt-icon">S</span>Kortille jää:  { card.productAmount - card.usage.length -1} x {card.product} </span>
          : <span className={style}><span className ="buttonTxt-icon">S</span>Kortille jää:  { Math.round( (card.amount - card.totalUsage - usageAmount )*100)/100 } €</span>
        }
      </h4>
      <HelpText text="Kortin käyttö tapahtuu täällä. Jos kortissa on käyntikertoja, valitset vain työntekijän ja paina veloita-painkietta. Jos kortti on euromaaäräinen, valitse jokin summanappuloista, tai syötä summma numeronta kenttään. "/>
      <div className="giftcard-body-container">
        { card.productAmount > 0 ?


        // 1. ---------- if product exists, show thise section -------------------------------->


          <div>
            { help === true && <span className="grey">Kortin voi muuttaa summalle kortin muokkausvalinnassa.</span>}
            <form onSubmit={handleUpdateCard}>
              <div className = "buttons-row">

                {/* set Worker */}
                <select className={worker ? 'passed' : 'not-passed'} onChange={handlerSetWorker}>
                  <option key="000" value=""> Valitse työntekijä »</option>
                  {users && users.map(x => <option key={x.id} value={x.firstname + ' ' + x.lastname}>{x.firstname + ' ' + x.lastname}</option>)}
                </select>
                <br/>

                {/* Submit */}
                {showSubmit() === true
                  ? <Button className="buttonGreen" type = "buttonGreen" label='Käytä korttia' icon = 'update'/>
                  : <Button
                    type = "buttonCancel"
                    icon = 'info'
                    label = 'Valitse työntekijä' />
                }
              </div>
            </form>

          </div>


          :

        // 2. --   --------  if no product, show this section  -------------------------------->

          <div>
            {(usageAmount === '' && help === true) && <p> Klikkaa summanappia alla, tai syötä summa kenttään </p>}

            {/* Charge buttons ---------------------- */}

            <form onSubmit={handleUpdateCard}>
              <div className = 'gap-20'/>
              <Wrapper>
                {buttonsOrdered
                 && buttonsOrdered.map
                 ((x,i) =>

                   <button
                     value = {x}
                     type="button"
                     onClick={setUsageAmountHandler}
                     key = {x+i}
                     className="hotButton">
                     {x}
                   </button>
                 )}
              </Wrapper>
              <div className = 'gap-20'/>

              <div className = 'input-group'>

                {/* amount ----------------------------- */}
                <label>Syötä summa, tai klikkaa yllä pikapainiketta</label>
                <input  className ={(usageAmount !== '' && (card.amount - usageAmount - card.totalUsage) >= 0 && overDueStyle === null) ? 'passed' : 'not-passed'} placeholder='Syötä summa / pikavalinta yllä' value = {usageAmount} onChange={setUsageAmountHandler}/>


                {/* Worker ------------------------------- */}
                <label>Valitse työntekijä</label>
                <select className ={worker ? 'passed' : 'not-passed'} onChange={handlerSetWorker}>
                  <option key="000" value=""> Valitse työntekijä </option>
                  {users && users.map(x => <option key={x.id} value={x.firstname + ' ' + x.lastname}>{x.firstname + ' ' + x.lastname}</option>)}
                </select>


                {/* Submit button ------------------------- */}

                {showSubmit() === true
                  ? <Button className = "buttonGreen" type = "buttonGreen" label='Käytä korttia' icon = 'update'/>
                  : <Button icon = 'info' type="buttonNoEnter" label = {Number(usageAmount) + Number(card.totalUsage) > Number(card.amount) ? <>Maksettavaksi jää: { - (card.amount - card.totalUsage - usageAmount) } € </> : 'Lisää summa ja työntekijä '} />
                }
              </div>
            </form>
          </div>
        }
      </div>
    </>
  )
}

export default GiftCardUseHolder