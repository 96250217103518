const initialState = {
  show: false,
  message: '',
  type: 'default'
}

const notificationReducer = (state = initialState, action) => {
  switch(action.type){
  case 'NOTIFICATE':{
    const newState = {
      show: true,
      message: action.data.notification,
      type: action.data.type,
      onClickConfirm: action.data.onClickConfirm,
      onClickBack: action.data.onClickBack
    }
    return newState
  }

  case 'HIDE':{
    return initialState
  }

  default: return state
  }
}

let timeOut
let delay = 3500

export const setNotification = (notification, setDelay) => {
  if(setDelay) delay = setDelay
  return dispatch => {
    dispatch({
      type: 'NOTIFICATE',
      data: notification
    })

    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      dispatch({
        type: 'HIDE'
      })
    }, delay)
  }
}

export const hideNotification = () => {
  return{
    type: 'HIDE'
  }
}

export default notificationReducer