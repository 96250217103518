// -----------------------------------------------------------------------|
//                       HEADER OF COMONENT                               |
//                       depndend of BodyInTags                           |
// -----------------------------------------------------------------------|
import React from 'react'
import IconButton from '../../../../../../UI/IconButton/IconButton'

const BodyInTags_Header = ({ showForm, setShowForm, linkText }) => {

  let linkInfoTxt = 'Muokkaa tageja'
  if(linkText){ linkInfoTxt = linkText }
  'Lisää tai poista tägejä'

  return(
    <>
      {/*------------------------------
      |  Main heading of comoponent   |
      |-----------------------------*/}


      {/*---------------------------------------------------|
      |  text which is activated when help state is true    |
      |---------------------------------------------------*/}

      {/*-----------------------------------------------|
      |  activate edit form to add or remove tags       |
      |-----------------------------------------------*/}
      {showForm === false &&
      <div className = "tag-edit-button-cont" onClick={() => { setShowForm(true)}}>
        <IconButton className="icon-button-secondary mp-up-5" size= "20px" char='J' text={linkInfoTxt}/>

      </div>
      }

    </>
  )
}

export default BodyInTags_Header

