// This form is shown, when state user.role = customer.
// New customer is being created by customer.
// This route hasn´t other functions for security reasons
// 1. Save new customer -> confirm form. -> new form
// 2. logout

import React from  'react'
import Body_NewCustomer from '../components/AppBody/BodyComponents/Body_NewCustomer/Body_NewCustomer'
import Logout from './components/Logout'

const NewCustomer = () => {
  window.scrollTo({ top: 30 })

  return (
    <>
      <Body_NewCustomer/>
      <Logout/>
    </>

  )
}

export default NewCustomer