// -----------------------------------------------------------------------|
//                 LIST OF TAGS TO ADD OR REMOVE                          |
//                       depndend of BodyInTags                           |
// -----------------------------------------------------------------------|

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ButtonTxtIcon from '../../../../../../UI/ButtonTxtIcon/ButtonTxtIcon'
import filters from '../../../filters'
import MapTags from './MapTags'

const EditTagList = ({ sortBy, sortByCategory, value, handleSelectTags, handlePreview, setShowForm, handlerSaveTags, editTitle }) => {
  // 1. filter unpublished tags away
  // 2. sort filters by name
  const tags = useSelector(state => state.tags)
    .filter(tag => tag.published === true)
    .sort((a, b) => a.name.localeCompare(b.name))

  const help = useSelector(state => state.help.show)
  const [searchWord, setSearchWord] = useState('')
  let editTitleTxt

  // show filtered tags by searc word
  let filteredTags = filters(tags, searchWord)

  // separate tags which are choosen or not
  let notChoosenTags = filteredTags.filter( tag => value.includes(tag.id) === false)
  let choosenTags = filteredTags.filter( tag => value.includes(tag.id))

  // sort not choosen tags by category
  let tagsByMainCategory = notChoosenTags.filter( tag => tag.mainCategory === sortBy)
  let restTags = notChoosenTags.filter( tag => tag.mainCategory !== sortBy)

  let tagsByCategory = restTags.filter( tag => tag.category === sortByCategory)
  restTags = restTags.filter( tag => tag.category !== sortByCategory)

  if(!editTitle) {editTitleTxt = 'Muokkaa tagivalintoja'} else {editTitleTxt = editTitle}


  return(

    <div className = "tag-cards-container">
      { editTitle !== '-' &&
      <h5 className = "tag-header">
        {editTitleTxt}
      </h5>}
      { help &&
      <p className="mp-center mp-ml-20 mp-mr-20">
        Jos klikkaat jo valittua tagia uudestaan, valinta poistetaan. <br/>
        Kun olet tyytyväinen valintaan, talleta.
      </p>}
      <input style={{ marginTop: '10px' }} placeholder='etsi tagia' onChange={(e) => {setSearchWord(e.target.value)}}/>

      {/*-------------------|
      |     mapping tags    |
      |-------------------*/}

      <MapTags tagsToMap = {choosenTags} handleSelectTags = {handleSelectTags} handlePreview = {handlePreview} value = {value}/>
      <MapTags tagsToMap = {tagsByMainCategory} handleSelectTags = {handleSelectTags} handlePreview = {handlePreview} value = {value}/>
      <MapTags tagsToMap = {tagsByCategory} handleSelectTags = {handleSelectTags} handlePreview = {handlePreview} value = {value}/>
      <MapTags tagsToMap = {restTags} handleSelectTags = {handleSelectTags} handlePreview = {handlePreview} value = {value}/>

      {/*--------------------------------|
      |     Buttons to SAVE or CANCEL    |
      |--------------------------------*/}

      <div className="buttons-row">

        <ButtonTxtIcon
          onClick={handlerSaveTags}
          className="buttonGreen"
          label="Tallenna"
          icon="V"
        />
        <ButtonTxtIcon
          onClick={() => { setShowForm(false)}}
          className="buttonCancel"
          label="Peruuta"
          icon="q"
        />
      </div>
    </div>
  )
}

export default EditTagList