import React, { useState } from 'react'
import './ThreadsUI.css'
import PostManager from '../PostManager/PostManager'
// import ThreadSearch from './ThreadSearch/ThreadSearch'
import { useDispatch } from 'react-redux'
// import ThreadList from '../ThreadList/ThreadList'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { setThreadsFilter } from '../../MessageReducers/threadsReducer'
import { useSelector } from 'react-redux'
import IconButton from '../../../../components/UI/IconButton/IconButton'

const ThreadsUI = () => {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const [value, setValue] = useState('')

  const onChangeInput = (e) => {
    e.preventDefault()
    dispatch(setThreadsFilter(e.target.value))
    setValue(e.target.value)
  }

  const [currentView, setCurrentView] = useState(0)

  const clickNewThread = () => {
    setCurrentView(1)
  }

  const resetUi = () => {
    setCurrentView(0)
  }

  const defaultView = () =>

    <div className="add-item-search-top-bar">

      <IconButton
        char="j"
        className="icon-button-main"
        onClick={clickNewThread}
      />

      {/* search */}

      <input
        className={'input-cardholder-find theme' + theme }
        placeholder='etsi keskustelua'
        onChange={onChangeInput}
        value = {value}
      />

      { value !== '' &&
        <IconButton
          char="x"
          className="icon-button-gray"
          size="30px"
          onClick={() => {
            dispatch(setThreadsFilter(''))
            setValue('')
          }}
        />}

    </div>



  const views = [
    defaultView(),
    <PostManager
      key="post-manager-component"
      resetUi={resetUi}
      newThread={true}
    />
  ]

  return(
    views[currentView]
  )
}

export default ThreadsUI