import axios from 'axios'
// import { configurate } from './configurate'

const baseUrl = '/api/s3'

// get all companies
export const s3ImageUpload = async (file) => {
  try{

    // const config = configurate(token)
    const image = await axios.get(baseUrl + '/' + file.name + '/' + file.type)
    if(image.status === 200){
      console.log('image.data: ', image)
      const xhr = new XMLHttpRequest()
      console.log('signedRequest: ', image.data.signedRequest)
      xhr.open('PUT', image.data.signedRequest )
      xhr.send(file)
      // uploadFile(file, response.signedRequest, response.url);
    }
    return image.data
  } catch(err){
    console.log('error at s3ImageUpload() : ', err)
  }
}