import React from 'react'
import dayjs from 'dayjs'
import LinkInPost from './LinkInPost/LinkInPost'
import { addQuotes } from '../../../MessageReducers/newPostReducer'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import './Post.css'
import Quotes from './Quote/Quotes'
import authorColors from '../../../../../components/UI/AuthorCircle/authorColors'

const Post = ({ post, focusInput, setShowPostManager }) => {

  const dispatch = useDispatch()

  const images = (post.images && post.images.length > 0) && post.images.map(img =>
    <div className="image-in-post" key={Math.random() * 9999999 }>
      <img
        alt="shared"
        src={img}
      />
    </div>
  )

  const links = (post.links && post.links.length > 0) && post.links.map(url =>
    <LinkInPost
      url={url}
      key={Math.random() * 9999999}
    />
  )

  const onClickReply = (quotedPost) => {
    if(quotedPost){
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setShowPostManager(true)
      dispatch(addQuotes({
        post: quotedPost,
        state: true
      }))
      focusInput()
    }
  }

  const createMarkup = (text) => {
    return { __html: text.replace(/\n/g, '<br />') }
  }

  const postBody = <div dangerouslySetInnerHTML={createMarkup(post.body)} />
  const authCol = post.author.slice(0,3)
  const postAuthor = <div><span className = "post-author-dot" style={{ color: 'rgb(' + authorColors([authCol,'yes']) + ')' }}>• </span>
    <span className="post-author">{post.firstname} {post.lastname}</span></div>
  const dateAndTime = <span style={{ color: 'var(--color-secondary)' }}>{dayjs(post.date).format('HH:mm • DD.MM.YYYY')}</span>

  return(
    <div className='post'>
      <div className='post-header'>
        {postAuthor}
        {dateAndTime}
      </div>
      {(post.quotes && post.quotes.length > 0) && <Quotes quotes={post.quotes}/>}
      {links && links}
      {postBody}
      {images && images}
      <div className="post-buttons-container">
        <div className="reply-link" onClick={() => onClickReply(post)} >
          <FontAwesomeIcon icon={faReply}/>
          <span style={{ marginLeft: '0.5em' }}>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Post