import axios from 'axios'
import { configurate } from '../../../../services/configurate'

// tag and tagCategory are combined  <- 2 baseUrls
// maybe should be separeted in future

const baseUrl = '/private/api/tag'
const baseUrlCategories = '/private/api/tagcategory'

// get all tags filtered by company
export const getTags = async (token, companyId) => {
  try{
    const config = configurate(token)
    const items = await axios.get(baseUrl + '/' + companyId, config)
    return items.data
  } catch(err){
    console.log('error at getTags() : ', err)
  }
}

// create new Tag
export const postTag = async (token, body ) => {
  try{
    const config = configurate(token)
    const newTags = await axios.post(baseUrl, body, config)
    return newTags.data
  } catch(err){
    console.log('error at postTag() : ', err)
  }
}

// update tag
export const putTag = async (token, body, id) => {
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrl + '/' + id, body, config)
    return item.data
  } catch(err){
    console.log('error at putTag() : ', err)
  }
}
// delete tag
export const deleteTag = async (token, id, tags) => {
  try{
    const config = configurate(token)
    await axios.delete(baseUrl + '/' + id, config)
    const updated = tags.filter(tag => tag.id !== id)
    return updated
  } catch(err){
    console.log('error at delete tag : ', err)
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG CONTENT ------------------------------|
// -------------------------------------------------------------------|

// create new Tag content
export const postTagContent = async (token, body, tagId ) => {
  try{
    const config = configurate(token)
    const newTags = await axios.post(baseUrl + '/' + tagId, body, config)
    return newTags.data
  } catch(err){
    console.log('error at postTag() : ', err)
  }
}

// update tag content

export const putTagContent = async (token, body, tagId, contentId) => {
  console.log('token:', token)
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrl + '/' + tagId + '/' + contentId, body, config)
    return item.data
  } catch(err){
    console.log('error at putTag() : ', err)
  }
}

// delete tag content
export const deleteTagContent = async (token, tagId, contentId) => {
  try{
    const config = configurate(token)
    const tag = await axios.delete(baseUrl + '/' + tagId + '/' + contentId , config)

    return tag.data
  } catch(err){
    console.log('error at delete tag content : ', err)
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG IN TAG -------------------------------|
// -------------------------------------------------------------------|

// -------- post tag in tag

export const postTagInTag = async (token, body, tagId ) => {
  try{
    const config = configurate(token)
    const newTags = await axios.post(baseUrl + '/tagInTag/' + tagId, body, config)
    return newTags.data
  } catch(err){
    console.log('error at postTag() : ', err)
  }
}

// update tag in tag ( /tagInTag/:tagId/:tagInTagId )

export const putTagInTag = async (token, body, tagId, tagInTagId) => {
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrl + '/tagInTag/' + tagId + '/' + tagInTagId, body, config)
    return item.data
  } catch(err){
    console.log('error at putTag() : ', err)
  }
}

// delete tag content ( /tagInTag/:tagId/:tagInTagId )
export const deleteTaginTag = async (token, tagId, tagInTagId) => {
  try{
    const config = configurate(token)
    const tag = await axios.delete(baseUrl + '/tagInTag/' + tagId + '/' + tagInTagId , config)

    return tag.data
  } catch(err){
    console.log('error at delete tag content : ', err)
  }
}

// -------------------------------------------------------------------|
// ------------------------ TAG CATERGORIES --------------------------|
// -------------------------------------------------------------------|

// get tagGategories
export const getTagCategories = async (token, companyId) => {
  try{
    const config = configurate(token)
    const items = await axios.get(baseUrlCategories + '/' + companyId, config)
    return items.data
  } catch(err){
    console.log('error at getTagCategories() : ', err)
  }
}

// post tagCategory
export const postTagCategory = async (token, body) => {
  try{
    const config = configurate(token)
    console.log('bodySRV: ', body)
    const newItem = await axios.post(baseUrlCategories, body, config)
    return newItem.data
  } catch(err){
    console.log('error at postTagCategory() : ', err)
  }
}

// PUT tagCategory
export const putTagCategory = async (token, body, tagCategoryId ) => {
  try{
    const config = configurate(token)
    const item = await axios.put(baseUrlCategories + '/' + tagCategoryId, body, config)
    return item.data
  } catch(err){
    console.log('error at putTagCategory() : ', err)
  }
}

// Delete tagCategory

export const deleteTagCategory = async (token, catgoryId ) => {
  try{
    const config = configurate(token)
    const item = await axios.delete(baseUrlCategories + '/' + catgoryId, config)
    return item
  } catch(err){
    console.log('error at putTagCategory() : ', err)
  }
}