import React from 'react'
import { createNewUser } from '../../../../services/users'
import UserForm from '../UserForm/UserForm'
import Title from '../../../UI/Title/Title'
import { useSelector } from 'react-redux'
import langs from '../../../../langs'


const NewUser = () => {
  const currentUser = useSelector(state => state.user)
  const lN = Number(currentUser.language) // lN = languageNumber <- 0 EN, 1 FI ...
  const trans = langs.lang // translation

  return(
    <>
      <Title text={trans.createUser[lN]} icon="j"/>
      <div className="container">
        <UserForm createUser={createNewUser} type='newUser' />
      </div>
    </>
  )
}

export default NewUser