import React from 'react'
import Item_TitleAndData from '../../../components/UI/Item_TitleAndData/Item_TitleAndData'
import dateToDay from '../../../functions/dateToDay'
import { useSelector } from 'react-redux'

const GiftCardinfoHolder = ({ card }) => {
  const locations = useSelector(state => state.locations)
  return(
    <div>
      <h4 className="body-header">Kortin tiedot</h4>
      <div>
        <div className="customers-body-container">
          <div className = "even-odd"> <Item_TitleAndData title="Toimipaikka"      data={card.location && locations.filter(loc => loc.id === card.location)[0].name}/></div>
          <div className = "even-odd"><Item_TitleAndData title="E-mail"            data={card.email}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Maksutapa"         data={card.payingMethod.methodName}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Kuitin nro"        data={card.receipt}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Arvo alussa"       data={card.amount}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Myyntihinta"       data={card.salesValue}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Käytetty yhteensä" data={card.totalUsage}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Tuote"             data= {card.productAmount !== null ? card.productAmount +  ' x ' + card.product : 'Kortti ostettu summalle'}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Tuotetyyppi"       data={card.cardType}/></div>
          <div className = "even-odd"><Item_TitleAndData title="Voimassa"          data={dateToDay(card.createdDate) + ' - '  + dateToDay(card.expiryDate)}/></div>
          <div className = "even-odd"> <Item_TitleAndData title="Lisätiedot"       data={card.info}/></div>
        </div>
      </div>
    </div>
  )
}

export default GiftCardinfoHolder

