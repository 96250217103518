const lang = {

  // A
  active:                   ['Active',                                             'Aktiivinen'],
  activeUser:               ['Active user',                                        'Aktiivinen käyttäjä'] ,
  accountIngress:           [
    'Fields marked with * are required. By filling in the password fields, you can change your password to the service.',
    'Tähdellä merkityt kentät ovat pakollisia. Täyttämällä salasanakentät voit vaihtaa salasanasi palveluun.'],
  accountManagement:        ['Account management',                                 'Tilien hallinta' ],
  addEmail:                 ['Add an e-mail',                                      'Lisää sähköpostiosoite'],
  admin:                    ['Administrator',                                      'Ylläpitäjä' ],
  sureToRemoveUser:         ['Are You sure about removing user?',                  'Oletko varma, että haluat poistaa käyttäjän?'],

  // B
  badEmail:                 [ 'Bad e-mail address.',                               'Sähköpostiosoite on virheellinen.' ],
  back:                     ['Back',                                               'Takaisin'],
  basicUser:                ['User',                                               'Peruskäyttäjä' ],

  // C
  chooseExpiryDay:          [ 'Or choose expiry day',                              'Tai valitse käyttöoikeuden vanhenemispäivä' ],
  clear:                    ['Clear',                                              'Tyhjennä'],
  createUser:               [ 'Create user',                                       'Luo käyttäjä' ],
  confirmUserRemove:        [ 'Conifm user remove',                                'Varmista käyttäjän poisto' ],
  created:                  ['Created in',                                         'Luotu'],

  // D
  doesNotExpire:            ['Does not expire',                                    'Ei vanhene'],
  // E
  edit:                     ['Edit',                                               'Muokkaa'],
  english:                  ['English',                                            'Englanti'],
  email:                    [ 'e-mail',                                            'e-mail' ],
  emailsAdded:              ['Added e-mails',                                      'Lisätyt sähköpostit:'],
  expires:                  ['Expires',                                            'Vanhenee'],
  //F

  fillEmail:                ['PLease fill e-mail',                                 'Syötä sähköpostiosoite.'],
  finnish:                  ['Finnish',                                            'Suomi'],
  firstName:                [ 'First name',                                        'Etunimi' ],
  firstNameNeeded:          [ 'Please fill up first name ',                        'Etunimi on pakollinen tieto' ],

  hideInActive:             ['Hide inactive',                                      'Piilota ei-aktiiviset'],
  homeTitle:                ['Dashboard',                                          'Ohjausnäkymä'],

  // I
  inactive:                 ['Inactive',                                           'Ei aktiivinen'],
  informationUpdated:       ['Information updated',                                'Tiedot päivitetty'],
  invitationsPending:       ['Pending Invitations:',                               'Odottavat kutsut:'],
  invitationsSent:          ['Invitations are sent',                               'Kutsut lähetetty.'],
  isRemoved:                ['has been removed',                                   'on postettu!'],

  language:                 ['Language',                                           'Kieli'],
  lastName:                 ['Last name',                                          'Sukunimi' ],
  LastNameNeeded:           ['Please fill up last name',                           'Sukunimi on pakollinen tieto'],
  loggedIn:                 ['Logged in',                                          'Kirjautuneena sisään'],
  logOut:                   ['Log out',                                            'Kirjaudu ulos'],

  notExpiring:              ['Not expiring',                                       'Käyttöoikeus ei vanhene' ],
  notStudent:               ['Not student',                                        'Ei opiskelija'],
  newUser:                  ['New user',                                           'Uusi käyttäjä'],
  newUserCreated:           ['New user created',                                   'Uusi käyttäjä luotu'],
  operationRemoveUser:      ['This removes user:',                                 'Tämä toiminto poistaa käyttä'],
  orChooseExpDay:           ['Or choose epiry day',                                'Tai valitse käyttöoikeuden vanhenemispäivämäärä'],
  pwd:                      ['Password',                                           'Salasana' ],
  pwdAgain:                 ['Password again',                                     'Salasana uudestaan' ],
  emailNeeded:              ['Please fill up e-mail',                              'Sähköposti on pakollinen tieto' ],
  pwdNoMatch:               ['E-mails are different',                              'Salasanat eivät täsmää' ],
  pwdAtLeastWidth:          ['Password must have at least 12 characters',          'Salasanassa on oltava vähintään 12 merkkiä' ],

  // O
  ownInformation:           ['Own information',                                     'Omat tiedot'],

  // P

  personaInfo:              ['Personal settings',                                   'Omat tiedot'],

  // R
  removeUser:              ['Remove user',                                         'Poista käyttäjä'],
  removedUsers:             ['Removed users',                                      'Poistetut käyttäjät'],
  removeUserAccount:        ['Remove your user account',                            'Poista käyttäjätilisi'],
  removeAccountLegend:      [
    'This will delete  your account permanently, and you can not use this service anymore',
    'Tämä poistaa käyttäjätilisi palvelusta. Jos poistat tilisi et voi enää kirjautua sisään etkä käyttää palvelua.'],
  role:                     ['Role',                                               'Rooli'],

  searchUser:               ['Search for a user',                                  'Etsi käyttäjää'],
  searchResults:            ['search results',                                     'Hakutulokset'],
  sendInvitations:          ['Send invitationa',                                   'Lähetä kutsut'],
  sendInvitationsTitle:     ['Send invitations',                                   'Lähetä käyttäjäkutsuja'],
  showOnlyStudents:         ['Show only students',                                 'Näytä vain opiskelijat'],
  showUserLevels:           ['Show user levels',                                   'Näytä käyttäjätasot:'],
  signUp:                   ['Sign up',                                            'Rekisteröidy käyttäjäksi' ],
  staff:                    ['Staff',                                              'Työntekijä' ],
  subscriber:               ['Subscriber',                                         'Tilaaja'],
  superuser:                ['superuser',                                          'superuser' ],
  student:                  ['Student',                                            'Opiskelija' ],

  // U
  userAlreadyExits:         ['User or email already exits. User not created.',     'Käyttäjänimi tai sähköposti on jo käytössä. Käyttäjää ei luotu.' ],
  update:                   ['Update','Päivitä tiedot'],
  updateUser:               ['Update user',                                        'Päivitä käyttäjä' ],
  user:                     ['User',                                               'Käyttäjä'],
  users:                    ['Users',                                              'Käyttäjät'],
  UserAccountDeleted:       ['User account deleted',                               'Käyttäjätili on poistettu'],
  userInvitations:          ['User invitations',                                   'Käyttäjäkutsut'],
  UserManagement:           ['User management',                                    'Käyttäjähallinta'],
  userNameNeeded:           ['Please fill up user',                                'Käyttäjänimi on pakollinen tieto.' ],
  userName:                 ['User name',                                          'Käyttäjänimi' ],
  userNameLength:           ['Username has to have at least 5 characters',         'Käyttäjänimi täytyy olla vähintään 5 merkkiä pitkä' ],
  userUpdated:              ['User is updated',                                    'Käyttäjä päivitetty.' ],
  userRemoved:              ['User has been removed',                              'Käyttäjä on poistettu.' ],
  userRemove:               ['Remove user',                                        'Poista käyttäjä']

}
export default { lang }
