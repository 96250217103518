import React from 'react'

const SelectCategory = ({ setCategory, categories }) => {
  return (
    <>
      <select onChange={(e) => {setCategory(e.target.value)}}>
        <option value = 'other'>Valitse kategoria</option>
        {categories.map( cat =>
          <option key={cat.id} value={ [cat.name, cat.id ]} >
            {cat.name}
          </option>
        )}
      </select>
    </>
  )
}

export default SelectCategory