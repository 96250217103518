import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import './RemoveAttachmentButton.css'

const RemoveAttachmentButton = ({ item, remove }) => {
  return(
    <button
      id={item}
      className="remove-attachment-button"
      onClick={() => remove(item)}
    >
      <FontAwesomeIcon icon={faTimesCircle} />
    </button>
  )
}

export default RemoveAttachmentButton