import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/msg'

// GET ALL
export const msgGetAll = async (token, companyId) => {
  try{
    const config = configurate(token)
    const response = await axios.get(baseUrl, config)

    // response is filtered by company
    const filteredResponse = response.data.filter(p => p.company === companyId)
    return await filteredResponse
  } catch(err){
    console.log('error at msgService/msgGetAll: ', err)
  }
}

// GET TOPIC ... Get all posts under a topic?
export const msgGetTopic = async(id, token) => {
  try{
    const config = configurate(token)
    const response = await axios.get( baseUrl + '/' + id, config)
    return response.data
  } catch(err){
    console.log('Error at msgService/msgGetById:', err)
  }
}

// POST NEW TOPIC - Start new thread
export const msgNewTopic = async(token, body) => {
  try {
    const config = configurate(token)
    const responce = await axios.post(baseUrl, body, config)
    return responce.data
  } catch(err){
    console.log('Error at msgService/msgNewPost: ', err)
  }
}

// NEW THREAD - Add new thread with post zero in it
export const newThread = async(token, post) => {
  try{
    const config = configurate(token)
    const response = await axios.post(baseUrl, post, config)
    return response.data
  } catch(err){
    console.log('Error at create new thread: ', err.response.data)
  }
}

// POST NEW POST - Add post to current thread
export const msgNewPost = async(token, post, topicId) => {
  try{
    const config = configurate(token)
    const response = await axios.post(baseUrl + '/post/' + topicId, post, config)
    return response.data
  } catch(err){
    console.log('Error at msgService/msgNewPost: ', err)
  }
}

// POST IMAGE
export const msgNewImage = async(token,post,topicId,upload) => {
  try{
    const config = configurate(token)
    const responce = await axios.post(baseUrl + '/post/upload/' + topicId, post, upload, config)
    return responce.data
  } catch(err){
    console.log('Error at msgService/msgNewImage: ', err)
  }
}

// POST QUOTE - Create a new post with a quote from another post
export const msgPostQuote = async(token,body,topicId,postId) => {
  console.log('quote-body: ', body)
  try{
    const config = configurate(token)
    const response = await axios.post(baseUrl + '/quote/' + topicId + '/' + postId, body, config)
    return response.data
  } catch(err){
    console.log('Error at services/msgPostQuote: ', err)
  }
}

// UPDATE TOPIC
export const msgUpdateTopic = async(token, body, id) => {
  try {
    const config = configurate(token)
    const response = await axios.put(baseUrl + '/' + id, body, config)
    return response.data
  }catch(err){
    console.log('Error at services/msgUpdateTopic: ', err)
  }
}