// -----------------------------------------------------------------------|
//                    POP UP OF CHOOSED TAG CONTENT                       |
//                       depndend of BodyInTags                           |
// -----------------------------------------------------------------------|

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../../../../../UI/IconButton/IconButton'
import Text from '../../../../../../UI/Text/Text'
import { newTagInTag } from '../../../../reducers/tagsReducer'
import BodyInTags from '../BodyInTags'

const Preview = ({ showPreview, handlePreview }) => {
  const dispatch = useDispatch()
  // const theme = useSelector(state => state.theme)

  // selectors
  const tags = useSelector (state => state.tags)

  //---------------------------------------------------------|
  // dispatch function is sent to bodyNewTag, because then   |
  // bodyInTag is possible to reuse in other components.     |
  //                                                         |
  //  • body = body of new tag                               |
  //  • tags = tags from selector                            |
  //  • id = item.id (in this component tag.id)              |
  //                                                         |
  const saveTags = (token, body, tags, itemId) => {        //|
    dispatch(newTagInTag(token, body, tags, itemId))       //|
  }                                                        //|
  //---------------------------------------------------------|

  // fitler current tag from tags array, returned value is array so item is [0]
  const tagToPreview = tags.filter(t => t.id === showPreview)[0]

  return(
    <div className = "tag-preview">
      <div className='tag-preview-container'>
        <span className="tag-preview-info-title">Tagin esikateslu</span>
        <div className = "tag-preview-exit-button">

          {/* --------------------------|
          |    exit button in corner    |
          ---------------------------- */}

          <IconButton
            className="icon-button-secondary"
            char="x"
            size = "20px"
            onClick={() => handlePreview('')}
          />
        </div>

        <h4 className="tag-upper-header-preview">{tagToPreview.name}</h4>

        {tagToPreview.content.map(cont => {

          return(
            <div className = "tag-preview-item" key={cont.id}>


              {/* ------------------------------------------------------------------------------|
              |    filtering proper html tags for content by sort key                           |
              --------------------------------------------------------------------------------*/}
              { cont.sort === 'text' && <div className = "tag-txt-preview"><Text data={cont.data}/></div>}
              { cont.sort === 'header' && <h5 className="tag-header-preview">{cont.data}</h5>}

              {/* ------------------------------------------------------------------------------|
              |    Link content opens new window                                                |
              --------------------------------------------------------------------------------*/}
              { cont.sort === 'link' &&
                <div className = "tag-txt-preview">
                  <div onClick = {() => {window.open(cont.data.split(',')[1], 'Tag-popup', 'width=0,height=0') }}>
                    {<><span className="symbol">g </span>{cont.data.split(',')[0]}</>}
                  </div>
                </div> }


              {/* ------------------------------------------------------------------------------|
              |    video is not shown, if content is empty                                      |
              |    ifram is originally made for YouTube, but its workin also at least in Vimeo  |
              ---------------------------------------------------------------------------------*/}
              { cont.sort === 'video' && cont.data !== ''
                && <iframe src={cont.data} title="Video" allowFullScreen className="tag-video-preview">{cont.data}</iframe>}
            </div>
          )
        })}
        {/* This needs to get tags of tag, not the item of component which is useing Preview -component! */}

        <BodyInTags
          items = {tagToPreview.tags}
          selectedID={tagToPreview.id}
          saveTags = {saveTags}
          itemId = {tagToPreview.id}
          sortBy = {tagToPreview.mainCategory}
          sortByCategory = {tagToPreview.category}
        />
      </div>

    </div>
  )
}

export default Preview