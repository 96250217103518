import React from 'react'
import './UiCardHeader.css'


const UiCardHeader = (props,  { onClick }) => {

  return (

    <div className={'ui-card-header'}>

      <h3 onClick={ onClick }>{props.headerTitle}<br/>
        <span className="ui-card-header-amount">{props.headerAmount}</span>
      </h3>
      <span className="header-info">{props.hederInfo && props.hederInfo}</span>
    </div>
  )
}

export default UiCardHeader