import  {
  customersGet
} from '../services/srv-customers'

const customersReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_CUSTOMERS':
    return action.data
  default: return state
  }
}

// get customers
export const getCustomers = (token, company) => {
  return async dispatch => {
    let customers = await customersGet(token, company)
    if(customers.data === null){customers = { data: 'noAccountYet' }}
    dispatch({
      type: 'SET_CUSTOMERS',
      data: customers.data
    })
  }
}

export default customersReducer


