import React from 'react'
import IconButton from '../../../UI/IconButton/IconButton'

const BodyButtons = ({ setBodyContainer, item, setSelectedID }) => {

  const scrollTop = () => {
    window.scrollTo({ top: 0,  behavior: 'smooth' })
  }

  return(
    <IconButton
      char='L'
      className="icon-button-secondary"
      text = 'Muokkaa'
      onClick={ () => { setBodyContainer('1'); scrollTop(); setSelectedID(item.id)}}
    />
  )
}

export default BodyButtons