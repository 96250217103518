import React from 'react'
import './UiCard.css'
import UiCardHeader from './UiCardHeader/UiCardHeader'
import UiCardFooter from './UiCardFooter/UiCardFooter'
import UiCardBody from './UiCardBody/UiCardBody'
import { useSelector } from 'react-redux'

const UiCard = ( props, { onClick }) => {
  const theme = useSelector(state => state.theme)

  return(
    <div className={'ui-card' + theme}  style={props.style}>
      {props.headerTitle !== undefined
        &&
        <UiCardHeader
          headerTitle = {props.headerTitle}
          hederInfo = {props.headerInfo}
          headerAmount = {props.headerAmount}
          cardType = {props.cardType}
          seller = {props.seller}
          idSelected = {props.idSelected}
          onClick = {onClick}
        />
      }

      {props.children !== []
        &&
        <UiCardBody
          bodyStyle = {props.bodyStyle}
          infoUD = {props.infoUD}
        >
          {props.children}
        </UiCardBody>
      }

      {(props.footerTitle !== undefined
        || props.footerInfo !== undefined
        || props.helpTxt !== undefined
        || props.footerInfoRight !== undefined
      )
        && <UiCardFooter
          footerTitle={props.footerTitle}
          footerInfo={props.footerInfo}
          helpTxt={props.helpTxt}
          footerInfoRight = {props.footerInfoRight}
        />}
    </div>
  )
}

export default UiCard