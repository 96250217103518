import React from 'react'
import IconButton from '../../../UI/IconButton/IconButton'
import UiCard from '../../../UI/UiCard/UiCard'
import BodyButtons from './BodyComponents/BodyButtons'
import BodyComponents from './BodyComponents/BodyComponents'


const Tag = ({ tag, tags, setTags, setBodyContainer, bodyContainer, selectedID, setSelectedID }) => {

  const mainCategory = () => {
    if (tag.mainCategory === 'instructions') return 'Ohjeistukset'
    if (tag.mainCategory ===  'check') return 'Tutkimukset'
    if (tag.mainCategory ===  'notes') return 'Asiakastapaaminen'
    if (tag.mainCategory ===  'dataPoints') return 'Seuranta'
    if (tag.mainCategory ===  'other') return 'Muu'
    else return 'error: missing maing category'
  }

  return(
    <>
      {/* Back button */}
      { selectedID === tag.id &&
            <div className='UIcard-exit-button'>
              <IconButton
                onClick={() => {
                  setSelectedID(null)
                  setBodyContainer(null)
                }}
                className="icon-button-secondary"
                size="20px"
                char="x"
              />
            </div>
      }
      { selectedID === tag.id | selectedID === null ?
        <UiCard
          headerTitle = {<>{tag.name}  { tag.published !== true ? <span className = "gray">(luonnos)</span> : ''}</>}
          footerInfoRight = {mainCategory() + ' / ' + tag.category}
          footerInfo = {'Avainsanat: ' + tag.keywords}
        >
          <div className="customer-body-container">
            <BodyButtons
              tag={tag}
              setBodyContainer = {setBodyContainer}
              bodyContainer = {bodyContainer}
              selectedID = {selectedID}
              setSelectedID = {setSelectedID}
            />
            <div className="customer-body-container">
              <BodyComponents
                bodyContainer = {bodyContainer}
                setSelectedID = {setSelectedID}
                selectedID  = {selectedID}
                setBodyContainer  = {setBodyContainer}
                setTags = {setTags}
                tags = {tags}
                tag={tag}
              />
            </div>

          </div>

        </UiCard> : null}
    </>
  )
}

export default Tag