import React, { useState } from 'react'
import './Loader.css'

const Loader = ({ title, text }) => {

  const[shoeLoader, setShowLoader] = useState(true)
  setTimeout( () => setShowLoader(false), 5000)

  const NoResponse = () => {
    return(
      <>
        { !title && <h3>Sisältöä ei pystytä näyttämään</h3> }
        { !text && <p>Jokin meni vikaan. Yritä myöhemmin uudelleen.</p> }
        { title && <h3> {title} </h3> }
        { text && <p> {text} </p> }
      </>
    )
  }

  const Loading = () => {
    return(
      <div className="loader-title">
        <h4>Ladataan...</h4>
        <div className="loader-container">
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )

  }
  return(
    <>
      { shoeLoader === true
        ? <Loading/>
        : <NoResponse/>
      }
    </>
  )
}

export default Loader