import React,{ useState } from 'react'
import './Notification.css'
import { hideNotification } from './notificationReducer'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../Button/Button'

const Notification = () => {

  const dispatch = useDispatch()
  const notification = useSelector(state => state.notification && state.notification)
  const theme = useSelector(state => state.theme && state.theme)
  const message = notification.message
  let type = notification.type

  const Modal = () => {
    return(
      <div className="notification-buttons-row">
        <Button
          label = 'Takaisin'
          type = 'alert'
          className="buttongreem"
          onClick={notification.onClickBack}
          icon = 'back-white'
        />
        <Button
          label = 'Poista'
          type = 'alert'
          onClick={notification.onClickConfirm}
          icon = 'bin'
          className="buttonRed"
        />
      </div>
    )
  }

  if(!type) type = 'default'
  type === 'default' && setTimeout(() => {setStyle('off')},3200)
  const [style, setStyle] = useState('on')

  const handleCancel = () => {
    setStyle('off')
    setTimeout(() => {dispatch(hideNotification())}, 300)
  }

  return(
    <>
      <div className = {'notification' + theme} onClick={handleCancel}>
        <div className={'notification-' + style + ' notification-' + type + ' notification-theme' +  theme}>
          <h5 className="heading-notification">{message}</h5>
          {notification.type === 'modal' && <Modal/>}
        </div>
      </div>
    </>
  )
}

export default Notification