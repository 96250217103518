import React from 'react'
import './UiCardBody.css'

const UiCardBody = (props) => {

  return (
    <div className= 'ui-card-body'>
      {props.children}
      {props.infoUD && props.infoUD}
    </div>
  )
}

export default UiCardBody