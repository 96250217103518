// -----------------------------------------------------------------------|
//                 Content for adding new tag                             |
//                 depndend of bodyComponents                             |
// -----------------------------------------------------------------------|

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './BodyInTags.css'
// import { newTagInTag } from '../../../reducers/tagsReducer'
import CurrentTags from './components/CurrentTags'
import Preview from './components/Preview'
import BodyInTags_Header from './components/BodyInTags_Header'
import EditTagList from './components/EditTagList'

const BodyInTags = ({ itemId, items, saveTags, title, selectedID, linkText, editTitle, sortBy })  => {
  // ------------------------------------------|
  //               selectors                   |
  // ------------------------------------------|
  const tags = useSelector(state => state.tags)
  const user = useSelector(state => state.user)

  // ------------------------------------------|
  //               states                      |
  // ------------------------------------------|

  const [showForm,      setShowForm] =    useState(false)
  const [showPreview,   setShowPreview] = useState('')

  // value for array of active tags ids. Defaut is array of ids from db
  const defautValue = items ? items.map(t => { return t.tagId }) : []
  const [value,         setValue] =       useState(defautValue)


  // ---------------------------------------------------------------|
  //               handles of component                             |
  // ---------------------------------------------------------------|

  //------  toggle selected items (tagId s) in array  --------------|

  const handleSelectTags = (id) => {
    value.includes(id) !== true ?
      setValue([...value, id]) : setValue(value.filter(x => x !== id))
  }
  // ---------  handle to set which tag is previewed ---------------|

  const handlePreview = (id) => { setShowPreview(id)}

  // -------  save selected items, sending array to server  --------|

  const handlerSaveTags = () => {

    // mapping array containing choosen tags id:s

    const body = Array.isArray(tags) === true && tags.length > 0 && tags.map( tag => {
      if(value.includes(tag.id) === true) return {
        name: tag.name,
        tagId: tag.id,
        category: tag.category
      } }

      // clean undefined tags away beacuse map is levaing them in array

    ).filter(tag => tag !== undefined)
    saveTags(user.token, body, tags, itemId)
    setShowForm(false)
  }

  return(
    <div>{title && <h4 className="body-header mp-mb-20">{title}</h4>}
      <div className="tag-contgainer">
        <BodyInTags_Header
          linkText = {linkText}
          title = {title}
          value={value}
          handlePreview = {handlePreview}
          handleSelectTags = {handleSelectTags}
          showForm={showForm}
          handlerSaveTags={handlerSaveTags}
          setShowForm = {setShowForm}/>

        {showForm === true &&

      <EditTagList
        editTitle = {editTitle}
        value = {value}
        handleSelectTags = {handleSelectTags}
        handlePreview = {handlePreview}
        setShowForm = {setShowForm}
        handlerSaveTags = {handlerSaveTags}
        sortBy = {sortBy}
      />
        }

        {showForm === false &&

      <CurrentTags
        items = {items}
        handlePreview = {handlePreview}
      />}

        {showPreview !== '' &&

      <Preview
        showPreview = {showPreview}
        handlePreview = {handlePreview}
        items = {items}
        selectedID = {selectedID}
        saveTags = {saveTags}
      />}

      </div>
    </div>
  )
}

export default BodyInTags