import React from 'react'
const IsDeleted = ({ num }) => {

  return(
    <div>
      <h3>Kortti nro {num} on poistettu tietokannasta</h3>
      <p> Korttia ei voi enää palauttaa</p>
    </div>
  )
}

export default IsDeleted
