import React from 'react'

const Reactivated = ({ num }) => {

  return (
    <div>
      <h3>Kortti nro {num} palautettiin onnistuneesti</h3>
    </div>

  )
}

export default Reactivated