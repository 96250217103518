import React from 'react'
import './LinkListItem.css'
import RemoveAttachmentButton from '../../RemoveAttachmentButton/RemoveAttachmentButton'

const LinkListItem = ({ item, remove }) => {
  return(
    <div className="link-list-item">
      <RemoveAttachmentButton item={item} remove={remove}/>
      <a href={item} target="blank">
        {item}
      </a>
    </div>
  )
}

export default LinkListItem