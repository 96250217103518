import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import IconButton from '../../UI/IconButton/IconButton'
import Loader from '../../UI/Loader/Loader'
import Title from '../../UI/Title/Title'
import UiCard from '../../UI/UiCard/UiCard'
import Item from './components/Item'
import { post_product } from './reducers/productsReducer'

const Products = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const products = useSelector(state => state.products)

  const newProductHandler = () => {
    const body = {
      productName: 'Aseta tuotteen nimi',
      company: user.company,
      productValue: '0',
      duration: '0',
      category: '-'
    }
    dispatch(post_product(user.token, body, products))
  }


  return(
    <div>
      <div className="customer-add-new-customer">
        <IconButton
          char="j"
          className="icon-button-main"
          onClick={newProductHandler}
        />
      </div>
      <Title text="Tuotteet" icon="B"/>
      <div className="container flex-column-reverse">
        {products ? products.map( item => {
          let style = {}
          if(item.productName === 'Aseta tuotteen nimi') {style = { border: 'solid 5px var(--passed-color)', borderRadius: '5px' }}
          return(
            <div key={item.id}>
              <UiCard
                style = {style}
                headerTitle = {item.productName}
                headerInfo = {item.productValue + ' €'}
              >
                <Item item={item}/>
              </UiCard>
            </div>
          )
        }):
          <Loader/>
        }

      </div>
    </div>

  )
}

export default Products