import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../components/Private/Home/Home'
import UserProfile from '../components/Private/UserProfile/UserProfile'
import Footer from '../components/UI/Footer/Footer'

// Account management
import AppRoutes from '../Apps/AppRoutes'
import Products from '../components/Private/Products/Products'
import Tags from '../components/Private/Tags/Tags'
import TagCategories from '../components/Private/TagCategories/TagCategories'
import { useSelector } from 'react-redux'
import NavBar from '../components/UI/NavBar/NavBar'
import Locations from '../components/Private/Locations/Locations'

const StaffRoutes = ({ activeModules }) => {
  const theme = useSelector(state => state.theme)
  return(
    <div className={'routes-container' + theme}>

      <Switch>
        <Route exact path="/home">             <NavBar activeModules = {activeModules}/>          <Home />             <Footer/> </Route>
        <Route exact path="/">                   <NavBar activeModules = {activeModules}/>        <Home />             <Footer/> </Route>
        <Route exact path="/profile">             <NavBar activeModules = {activeModules}/>       <UserProfile />      <Footer/> </Route>
        <Route exact path="/tags">                <NavBar activeModules = {activeModules}/>       <Tags />             <Footer/> </Route>
        <Route exact path="/tagcategories">         <NavBar activeModules = {activeModules}/>     <TagCategories />    <Footer/> </Route>
        <Route exact path="/products">              <NavBar activeModules = {activeModules}/>     <Products />         <Footer/> </Route>
        <Route exact path="/locations">      <NavBar activeModules = {activeModules}/>             <Locations />                           <Footer/> </Route>

        { activeModules && <AppRoutes activeModules={activeModules}/>}
      </Switch>
    </div>
  )
}


export default StaffRoutes