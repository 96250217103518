import React from 'react'
import './HoverPlate.css'
import '../ActionButton/ActionButton'
import ActionButton from '../ActionButton/ActionButton'

const HoverPlate = (props) => {

  return(
    <div>
      <ActionButton onClick={props.deleteHandler} id={props.id}/>

      <div className="hover-plate">
        {props.children}
      </div>

    </div>
  )
}

export default HoverPlate