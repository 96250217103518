import React from 'react'
import Images from './Images/Images'
import Links from './Links/Links'

const Attachments = () => {
  return(
    <div>
      <Images/>
      <Links/>
    </div>
  )
}

export default Attachments