const initialState = {
  cardsOld: false,
  cardsUsed: false,
  cardsTrach: false
}

const tagsCategoriesReducer = (state = initialState, action) => {
  switch(action.type){
  case 'SET_FILTERS':
    return action.data

  default: return state
  }
}

export const setFilters = (data) => {


  return async dispatch => {
    dispatch({
      type: 'SET_FILTERS',
      data: data
    })
  }
}

export default tagsCategoriesReducer