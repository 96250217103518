import React, { useState } from 'react'
import Title from '../../../components/UI/Title/Title'
import { newAccount } from  '../reducers/cardsReducer'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/UI/Loader/Loader'
import { setNotification } from '../../../components/UI/Notification/notificationReducer'

// <-- cards.js
// If card account is not yet defined, here is form to do it...

const NewCardAccount = () => {

  const user =  useSelector(state => state.user)
  const cards = useSelector(state => state.cards)
  const dispatch = useDispatch()

  // -------- new account hanler ------------------------------
  const [newNextCardNumber, setNewNextCardNumber] = useState('')
  const [newExpiryTime, setNewExpiryTime] = useState('')

  const neAccountHandler = () => {
    const accountBody = {
      nextCardNumber: newNextCardNumber,
      expiryTime: newExpiryTime
    }
    dispatch(newAccount(user.token, user.company, accountBody ))  // update cards to DB
    dispatch(setNotification({
      notification: 'Uusi tili on luotu!',
      type: 'default' }))
    setTimeout(() => { window.location.reload() },100)
  }

  return (
    // if cards value is noAccountYet -> open form. Else open Loader, when db is not yet loaded.
    cards === 'noAccountYet' ?
      <div>
        <Title icon ="S" text="Ei vielä tiliä – Luo uusi!"/>
        <h4>Näyttää siltä, että sinulle ei ole vielä luotu tiliä kortteja varten.</h4>
        <p>Täytä lomake, lähetä se, ja uusi tili on valmis! Voit myhöhemmin muokata tietoja asetukset valikosta.</p>
        <div className = "giftcards-container">

          {/* Expirytime */}
          <div className="flex-container">
            <div className="flex-item-left">
              Kortin voimassaoloaika (kk):
            </div>
            <div className="flex-item-right">
              <input
                value={newExpiryTime}
                onChange={(event) => setNewExpiryTime(event.target.value)}
              />
            </div>
          </div>

          {/* sequential number to start */}
          <div className="flex-container">
            <div className="flex-item-left">
              Ensimmäisen kortin numero (esim. 1):
            </div>
            <div className="flex-item-right">
              <input
                value={newNextCardNumber}
                onChange={(event) => setNewNextCardNumber(event.target.value)}
              />
            </div>
          </div>

          <button
            onClick={neAccountHandler}
            className = "buttonGreen"
            type = "submit"
          >
                  Luo uusi tili
          </button>

        </div>
      </div>
      :
      <> <Loader/></>
  )
}

export default NewCardAccount
