import React from 'react'
import Msg from './Messages/Messages/Msg'
import Thread from './Messages/Messages/Thread/Thread'
import { Route } from 'react-router-dom'
import Loader from '../components/UI/Loader/Loader'
import Footer from '../components/UI/Footer/Footer'
import Cards from './Cards/Cards'
import Customers from './Customers/Customers'
import RaportsHolder from './Cards/components/RaportsHolder/RaportsHolder'
import SettingsHolder from './Cards/components/SettingsHolder/SettingsHolder'
import NavBar from '../components/UI/NavBar/NavBar'
import CustomerRaport from './Customers/components/CustomerRaport/CustomerRaport'

const AppRoutes = ({ activeModules }) => {

  if (activeModules){
    return(
      <>

        { activeModules.messages === true  && // MESSAGES
        <>
          <Route exact path="/msg">   <NavBar activeModules = {activeModules}/>  <Msg />                               <div className="container"> </div>  <Footer/>  </Route>
          <Route exact path="/msg/:id"> <NavBar activeModules = {activeModules}/><Thread />                           <div className="container"> </div>  <Footer/>  </Route>
        </>}

        {activeModules.giftCard === true  &&  // CARDS
        <>
          <Route exact path="/cards">              <NavBar activeModules = {activeModules}/>       <div className="container"> <Cards/>                      </div> <Footer/> </Route>
          <Route exact path="/cards/raports">      <NavBar activeModules = {activeModules}/>       <div className="container"> <RaportsHolder/>              </div> <Footer/> </Route>
          <Route exact path="/cards/settings">     <NavBar activeModules = {activeModules}/>       <div className="container"> <SettingsHolder/>             </div> <Footer/> </Route>
        </>}

        {activeModules.customers === true  &&  // Customers
        <>
          <Route exact path="/customers">      <NavBar activeModules = {activeModules}/>               <div className="container"> <Customers/>                      </div> <Footer/> </Route>
          <Route exact path="/customer/:id">                    <CustomerRaport/> </Route>
        </>}

      </>
    )}
  else return(
    <>
      <Loader  title = 'Oops!' text = 'This app is not available, please contact Ahjomedia!' />
    </>
  )
}

export default AppRoutes
