import React from 'react'
import DateOption from './DateOption'

const DateDropDown = ({ setFilterRange, filterRange, yearNow }) => {

  // function to replace item in array
  function replaceAt(array, index, value) {
    const ret = array.slice(0)
    ret[index] = value
    return ret
  }

  return(<>
    <div className="flex-col-nogap" style={{ marginBottom: '15px' }}>
      <div className="flex-row-nogap">
        <div  style={{ width:'230px' }}> Mistä (pv/kk/vuosi): </div>

        <div className = "flex-time-container" style={{ width: '100%', margin: 0 }}>
          <select className = "input-time" onChange={(event) => {setFilterRange(replaceAt(filterRange, 0, event.target.value))}}>

            <DateOption amount="31" offset="1"/>
          </select>
          <select className = "input-time" onChange={(event) => {setFilterRange(replaceAt(filterRange, 1, event.target.value))}}>
            <option value = {filterRange[1]}>{filterRange[1]}</option>
            <DateOption amount="12" offset="1"/>
          </select>
          <select className = "input-time" style={{ marginRight:'5px' }} onChange={(event) => {setFilterRange(replaceAt(filterRange, 2, event.target.value))}}>
            <option value = {filterRange[2]}>{filterRange[2]}</option>
            <DateOption amount="10" offset={yearNow-9}/>
          </select>
        </div>

      </div>

      <div className="flex-row-nogap">
        <div style={{ width:'230px' }}>
    Mihin (pv/kk/vuosi):
        </div>
        <div className = "flex-time-container" style={{ width: '100%', margin: 0 }}>
          <select className = "input-time" onChange={(event) => {setFilterRange(replaceAt(filterRange, 3, event.target.value))}}>
            <option value = {filterRange[3]}>{filterRange[3]}</option>
            <DateOption amount="31" offset="1"/>
          </select>
          <select className = "input-time" onChange={(event) => {setFilterRange(replaceAt(filterRange, 4, event.target.value))}}>
            <option value = {filterRange[4]}>{filterRange[4]}</option>
            <DateOption amount="12" offset="1"/>
          </select>
          <select  className = "input-time" onChange={(event) => {setFilterRange(replaceAt(filterRange, 5, event.target.value))}}>
            <option value = {filterRange[5]}>{filterRange[5]}</option>
            <DateOption amount="10" offset={yearNow-9}/>
          </select>
        </div>
      </div>
    </div>
  </>) }

export default DateDropDown