const timeStamp = (d) => {


  const year = d.getFullYear().toString()
  var month = (d.getMonth()+1).toString()
  if(month < 10){month = '0' + month}
  var day = d.getDate().toString()
  if(day < 10){day = '0' + day}
  const now = parseInt(year + month + day)

  return now

}
export default timeStamp