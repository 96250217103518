// -----------------------------------------------------------------------|
//            SHOW ACTIve TAGS WHEN OPENING COMPONENT                     |
//                       depndend of BodyInTags                           |
// -----------------------------------------------------------------------|

import React from 'react'
import IconButton from '../../../../../../UI/IconButton/IconButton'

const CurrentTags = ({ items, handlePreview }) => {

  // if(items === undefined){items = []}
  return(
    <>
      <div className = "tag-cards-container">

        {/*--------------------|
        |    mapping tags      |
        |--------------------*/}

        {items.map(tag => {
          return(
            <div
              onClick={() => handlePreview(tag.tagId)} // sending id to show current tag preview
              className = 'tag-card'
              key={tag.tagId}>
              <p className = "tag-name">{tag.name}</p>
              <IconButton
                className="icon-button-secondary tag-eye"
                size="13px"
                char='G'
              />
            </div>
          )
        })}

      </div>
    </>
  )
}

export default CurrentTags