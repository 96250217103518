// <-- Used By NewAccounts.js
import React, { useEffect, useState } from 'react'
import Button from '../../UI/Button/Button'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { createNewCompany, updateCompany } from '../../../services/company'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanies } from '../../../reducers/companyReducer'
import UiCard from '../../UI/UiCard/UiCard'
import HelpPop from '../../UI/HelpPop/HelpPop'
import { setNotification } from '../../UI/Notification/notificationReducer'

const AccountForm = ({ formType }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  let companyId
  let companyData
  if(formType === 'editAccount') {
    companyId = useRouteMatch('/accounts/editaccount/:id').params.id
    companyData = useSelector(state => state.companies).filter( company => company.id === companyId)[0]
  }

  // states
  const [company, setCompany] = useState('')
  const [active, setActive] = useState(false)
  const [name, setName] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postNro, setPostNro] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [vat, setVat] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [emailHeader, setEmailHeader] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [emailSignature, setEmailSignature] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [useCustomEmail, setUseCustomEmail] = useState(false)
  const [useCustomHost, setUseCustomHost] = useState(false)

  const [emailPwd, setEmailPwd] = useState('')
  const [emailPort, setEmailPort] = useState('')
  const [emailHost, setEmailHost] = useState('')




  const currentUser = useSelector(state => state.user)
  const [activeModules, setActiveModules] = useState({
    giftCard: false,
    video: false,
    messages: false,
    customers: false
  })

  // defining states of company if editMode
  useEffect(() => {
    if(companyData) {
      setCompany(companyData.companyName)
      setActive(companyData.active)
      setName(companyData.contactname)
      setStreetAddress(companyData.street)
      setPostNro(companyData.postNro)
      setCity(companyData.city)
      setCountry(companyData.country)
      setVat(companyData.vat)
      setTel(companyData.tel)
      setEmail(companyData.email)
      setActiveModules(companyData.activeModules ? companyData.activeModules : activeModules)
    }
  },[companyData])

  const clearFields = () => {
    setCompany('')
    setName('')
    setStreetAddress('')
    setPostNro('user')
    setEmail('')
    setCity('')
    setCountry('')
    setVat('')
    setTel('')
    setEmail('')
    setActiveModules({
      giftCard: false,
      video: false,
      messages: false,
      customers: false
    })
  }
  const submitFormEditAccount = async (e) => {

    e.preventDefault()

    const body = {
      companyName: company,
      contactname: name,
      street: streetAddress,
      postNro: postNro,
      city: city,
      country: country,
      vat: vat,
      tel: tel,
      email: email,
      activeModules: activeModules,
      active: active
    }
    let modCompany // tmp var for User
    modCompany = await updateCompany(currentUser.token, body, companyId)

    if(modCompany){
      dispatch(setNotification({ notification: 'Tilin muokkaus onnistui' }))
      dispatch(setCompanies(currentUser.token, currentUser.company))

      // history.push('/')
    }
    else{
      dispatch(setNotification({
        notification: 'Nimi on varattu, käytä jotain toista nimeä',
        type: 'alert'
      })
      )
    }
  }

  const submitFormNewAccount = async (e) => {
    e.preventDefault()

    // if(validateForm()){ // must DONE LATER!!!!!!!!

    const newAccount = {
      companyName: company,
      contactname: name,
      street: streetAddress,
      postNro: postNro,
      city: city,
      country: country,
      vat: vat,
      tel: tel,
      email: email,
      activeModules: activeModules,
      active: active
    }

    let newCompany // tmp var for User
    newCompany = await createNewCompany(currentUser.token, newAccount)

    if(newCompany){
      dispatch(setNotification({ notification: 'Uusi tili on luotu, luo nyt Yritykselle käyttäjä' }))
      dispatch(setCompanies(currentUser.token, currentUser.company))
      clearFields()
      history.push('/admin/newuser')
    }
    else{
      dispatch(setNotification({
        notification: 'Tili on jo olemassa, käytä jotain toista nimeä',
        type: 'alert'
      })
      )
    }

  }
  const handleInput = (event) => {
    const id = event.target.id
    const val = event.target.value

    switch (id) {

    case 'company':         setCompany(val);            break
    case 'active':          setActive(val);             break
    case 'name':            setName(val);               break
    case 'streetAddress':   setStreetAddress(val);      break
    case 'postNro':         setPostNro(val);            break
    case 'city':            setCity(val);               break
    case 'country':         setCountry(val);            break
    case 'vat':             setVat(val);                break
    case 'tel':             setTel(val);                break
    case 'email':           setEmail(val);              break
    case 'emailHeader':     setEmailHeader(val);        break
    case 'emailBody':       setEmailBody(val);          break
    case 'emailSignature':  setEmailSignature(val);     break
    case 'emailUser':       setEmailUser(val);          break
    case 'emailAddress':    setEmailAddress(val);       break
    case 'emailPwd':        setEmailPwd(val);           break
    case 'emailPort':       setEmailPort(val);          break
    case 'emailHost':       setEmailHost(val);          break



    case 'useCustomEmail':  useCustomEmail === false ? setUseCustomEmail(true): setUseCustomEmail(false);       break
    case 'useCustomHost':   useCustomHost === false ? setUseCustomHost(true): setUseCustomHost(false);       break



    case 'giftCard':
      {
        activeModules.giftCard === false ? setActiveModules({ ...activeModules,  giftCard: true }) : setActiveModules({  ...activeModules, giftCard: false })
      }
      break
    case 'video':
      {
        activeModules.video === false ? setActiveModules({ ...activeModules, video: true }) : setActiveModules({  ...activeModules, video: false })
      }
      break
    case 'messages':
      {
        activeModules.messages === false ? setActiveModules({ ...activeModules, messages: true }) : setActiveModules({  ...activeModules, messages: false })
      }
      break
    case 'customers':
      {
        activeModules.customers === false ? setActiveModules({ ...activeModules, customers: true }) : setActiveModules({  ...activeModules, customers: false })
      }
      break

    }

  }

  return(
    <UiCard
      headerTitle = {`Muokataan tiliä ${company}`}
    >
      <form className='giftcard-body-container' >
        <p>Tähdellä* merkityt kohdat ovat pakollisia</p>
        {/* Company */}
        <div className="input-group">
          <label htmlFor="company">Yrityksen nimi*</label>
          <input
            id="company"
            name="company"
            data-testid="company"
            type="text"
            value={company}
            onChange={handleInput}
          />
        </div>

        {/* Name */}
        <div className="input-group">
          <label htmlFor="name">Yhteyshenkilön nimi*</label>
          <input
            id="name"
            name="name"
            data-testid="name"
            type="text"
            value={name}
            onChange={handleInput}
          />
        </div>

        {/* Street address */}
        <div className="input-group">
          <label htmlFor="streetAddress">Katuosoite</label>
          <input
            id="streetAddress"
            name="streetAddress"
            data-testid="streetAddress"
            type="text"
            value={streetAddress}
            onChange={handleInput}
          />
        </div>

        {/* postNro */}
        <div className="input-group">
          <label htmlFor="postNro">Postinumero</label>
          <input
            id="postNro"
            name="postNro"
            data-testid="postNro"
            type="text"
            value={postNro}
            onChange={handleInput}
          />
        </div>

        {/* City */}
        <div className="input-group">
          <label htmlFor="city">Paikkakunta</label>
          <input
            id="city"
            name="city"
            data-testid="city"
            type="text"
            value={city}
            onChange={handleInput}
          />
        </div>

        {/* Country */}
        <div className="input-group">
          <label htmlFor="country">Maa</label>
          <input
            id="country"
            name="country"
            data-testid="country"
            type="text"
            value={country}
            onChange={handleInput}
          />
        </div>

        {/* vat */}
        <div className="input-group">
          <label htmlFor="vat">VAT tai y-tunnus</label>
          <input
            id="vat"
            name="vat"
            data-testid="vat"
            type="text"
            value={vat}
            onChange={handleInput}
          />
        </div>

        {/* tel */}
        <div className="input-group">
          <label htmlFor="tel">Puhelin*</label>
          <input
            id="tel"
            name="tel"
            data-testid="tel"
            type="text"
            value={tel}
            onChange={handleInput}
          />
        </div>

        {/* e-mail */}
        <div className="input-group">
          <label htmlFor="email">E-mail*</label>
          <input
            id="email"
            name="email"
            data-testid="email"
            type="text"
            value={email}
            onChange={handleInput}
          />
        </div>

        {/* Active (Boolean) */}
        <div className="input-group">
          <label htmlFor="email">Onko tili aktiivinen</label>
          <select
            id="active"
            name="active"
            data-testid="active"
            type="select"
            value={email}
            onChange={handleInput}
          >
            <option value={active}>{active ? 'Kyllä' : 'Ei'}</option>
            {active
              ? <option value={false}>Ei</option>
              : <option value={true}>Kyllä</option>}

          </select>
        </div>

        {currentUser.role === 'superadmin' &&
        <>
          <hr/>
          {/* active modules */}
          <h3>Aktiiviset ojelmamoduulit</h3>

          {/* GiftCards */}
          <div className="input-group mp-mt-30 mp-mb-30">
            <input checked={ activeModules.giftCard === true ? true : ''} readOnly = {true}
              id="giftCard"           name="giftCard"         data-testid="giftCard"
              type="checkbox"         value="giftCard"        onClick={handleInput}
            />
            <label htmlFor="GiftCard">Lahjakortit</label>
          </div>

          {/* Video */}
          <div className="input-group mp-mt-30 mp-mb-30">
            <input checked={ activeModules.video === true ? true : ''} readOnly = {true}
              id="video"              name="video"            data-testid="video"
              type="checkbox"         value="video"           onClick={handleInput}
            />
            <label htmlFor="VideoService">Videopalvelu</label>
          </div>

          {/* Messages */}
          <div className="input-group mp-mt-30 mp-mb-30">
            <input checked={ activeModules.messages === true ? true : ''} readOnly = {true}
              id="messages"           name="messages"            data-testid="messages"
              type="checkbox"         value="messages"           onClick={handleInput}
            />
            <label htmlFor="VideoService">Viestit</label>
          </div>

          {/* Customers */}
          <div className="input-group mp-mt-30 mp-mb-30">
            <input checked={ activeModules.customers === true ? true : ''} readOnly = {true}
              id="customers"           name="customers"            data-testid="customers"
              type="checkbox"         value="customers"           onClick={handleInput}
            />
            <label htmlFor="VideoService">Asiakaskertomukset</label>
          </div>
        </>
        }

        {/* Custom email invitations */}
        <h3>Tilin sähköpostiasetukset</h3>
        <p className="alert">Nämä asetukset eivät vielä ole tominnallisia, TODO(14.2.2022)</p>
        <div className="input-group mp-mt-30 mp-mb-30">
          <input
            onClick={handleInput}
            type="checkbox"
            id="useCustomEmail"
            name="useCustomEmail"
            data-testid="useCustomEmail"
          />

          <label htmlFor="GiftCard">Käytä yksilöityä sähkpostiviestiä <HelpPop text={
            'Jos haluat käyttää kutsuvieteissä yksilöityä sähköpostiviestiä, käytä näitä valintoja. Muuten lähteyksessä käytetään oletusarvoja.'
          }/></label>
        </div>
        {useCustomEmail === true &&
          <>
            {/* invite header title */}
            <div className="input-group">
              <label htmlFor="emailHeader">E-mail kutsun otsikko:</label>
              <input
                id="emailHeader"
                name="emailHeader"
                data-testid="emailHeader"
                type="text"
                value={emailHeader}
                onChange={handleInput}
              />
            </div>

            {/* invite header body */}
            <div className="input-group">
              <label htmlFor="emailHeader">E-mail kutsun sisältö ennen kutsulinkkiä:</label>
              <textarea
                id='emailBody'
                name="emailBody"
                data-testid="emailBody"
                className = "passed"
                value={emailBody}
                onChange={handleInput}
              ></textarea>
            </div>

            {/* invite signature*/}
            <div className="input-group">
              <label htmlFor="emailSignature">E-mail kutsun allekirjoitus:</label>
              <input
                id="emailSignature"
                name="emailSignature"
                data-testid="emailSignature"
                type="text"
                value={emailSignature}
                onChange={handleInput}
              />
            </div>
          </>
        }
        {/* Custom email invitations */}

        <div className="input-group mp-mt-30 mp-mb-30">
          <input
            onClick={handleInput}
            type="checkbox"
            id="useCustomHost"
            name="useCustomHost"
            data-testid="useCustomHost"
          />
          <label htmlFor="GiftCard">Käytä viestien lähetykseen omaa sähköpostiosoitetta <HelpPop text={
            'Jos haluat käyttää kutsuvieteissä omaa sähköpostiosoitetta, käytä alla olevia arvoja. Käytä tähän vain tätä tarkoitusta varten luotua sähköpostiosoitetta.'
          }/></label>
        </div>

        { useCustomHost === true &&
          <>
            {/* user for email*/}
            <div className="input-group">
              <label htmlFor="emailUser">E-mail käyttäjätunnus:</label>
              <input
                id="emailUser"
                name="emailUser"
                data-testid="emailUser"
                type="text"
                value={emailUser}
                onChange={handleInput}
              />
            </div>

            <div className="input-group">
              <label htmlFor="emailAddress">Sähköpostiosoite</label>
              <input
                id="emailAddress"
                name="emailAddress"
                data-testid="emailAddress"
                type="text"
                value={emailAddress}
                onChange={handleInput}
              />
            </div>


            <div className="input-group">
              <label htmlFor="emailPwd">Salasana</label>
              <input
                id="emailPwd"
                name="emailPwd"
                data-testid="emailPwd"
                type="text"
                value={emailPwd}
                onChange={handleInput}
              />
            </div>

            <div className="input-group">
              <label htmlFor="emailPort">Lähtevän postin portti</label>
              <input
                id="emailPort"
                name="emailPort"
                data-testid="emailPort"
                type="text"
                value={emailPort}
                onChange={handleInput}
              />
            </div>

            <div className="input-group">
              <label htmlFor="emailHost">Sähköpostipalvelin</label>
              <input
                id="emailHost"
                name="emailHost"
                data-testid="emailHost"
                type="text"
                value={emailHost}
                onChange={handleInput}
              />
            </div>
          </>
        }


        {/* Buttons */}
        {formType === 'newAccount' &&
        <div id="user-form-buttons">
          <Button
            id="submit-user-form-button"
            type = "buttonGreen"
            onClick={submitFormNewAccount}
            label = "Luo uusi tili"
            icon = "update"
          />
        </div>
        }
        {formType === 'editAccount' &&
        <div id="user-form-buttons">
          <Button
            id="submit-user-form-button"
            type = "buttonGreen"
            onClick={submitFormEditAccount}
            label = "Päivitä"
            icon = "update"
          />
        </div>
        }
      </form>
    </UiCard>
  )
}

export default AccountForm